import BaseModelAPI from '../BaseModelAPI';

export default class ArchivedDocument extends BaseModelAPI {
	static entity = 'archiveddocument';
	static entityName = 'archiveddocument';

	static filterMapping = {
		'documentCode' : 'outgoing_documents.documentCode',
		'documentNo' : 'outgoing_documents.documentNo',
		'createdAt' : 'outgoing_documents.createdAt',
		'sifatSurat' : 'outgoing_documents.sifatSuratId',
		'sifatSuratId' : 'outgoing_documents.sifatSuratId',
		'documentType' : 'outgoing_documents.documentTypeId',
		'documentTypeId' : 'outgoing_documents.documentTypeId',
		'description' : 'outgoing_documents.description',
		'archivedStatus' : 'archivedDocumentStatus.name',
		'archivedStatusId' : 'archivedDocumentStatus.name',
		'documentLocation' : 'outgoing_documents.documentLocationId',
		'documentLocationId' : 'outgoing_documents.documentLocationId',
		'remarks' : 'outgoing_documents.remarks',
	};

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.noBeritaAcara = '';
			this.archivedDateFrom = '';
			this.archivedDateTo = '';
			this.documentLocationId = '';
			this.documentLocation = {};
			this.picId = '';
			this.pIC = {};
			this.approvedById = '';
			this.approvedBy = {};
			this.removalMethodId = '';
			this.removalMethod = {};
			this.remarks = '';
			this.investigatorDetail = []
			this.incomingDocumentId = '';
			this.incomingDocument = {};
			this.outgoingDocumentId = '';
			this.outgoingDocument = {};
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}