<template>
	<b-card>
		<b-row>
			<b-col cols="12">
				<h6>A. Informasi Umum Mitra</h6>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6">
				<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="Nama Mitra Kerjasama" label-for="filter-incoming-document">
					{{ model != null ? ( model.destinationUser != null ? model.destinationUser.fullName : "-") : "-" }}
				</b-form-group>
			</b-col>
			<b-col cols="6">
			</b-col>
			<b-col cols="6">
				<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="Alamat" label-for="filter-incoming-document">
					{{ model != null ? ( model.destinationUser != null ? model.destinationUser.senderAddress : "-") : "-" }}
				</b-form-group>
			</b-col>
			<b-col cols="6">
				<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="Email" label-for="filter-incoming-document">
					{{ model != null ? ( model.destinationUser != null ? model.destinationUser.email : "-") : "-" }}
				</b-form-group>
			</b-col>
			<b-col cols="6">
				<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="Contact Person" label-for="filter-incoming-document">
					{{ model != null ? ( model.destinationUser != null ? model.destinationUser.contactPerson : "-") : "-" }}
				</b-form-group>
			</b-col>
			<b-col cols="6">
				<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="No. Telepon" label-for="filter-incoming-document">
					{{ model != null ? ( model.destinationUser != null ? model.destinationUser.phoneNumber : "-") : "-" }}
				</b-form-group>
			</b-col>
		</b-row>
		<b-row class="mt-2">
			<b-col cols="12">
				<h6>B. Daftar Kontrak Yang Pernah/Sedang Berlangsung </h6>
				<b-table bordered v-if="contractDocuments != null && contractDocuments.length > 0" id="my-table" stacked="md" head-variant="light"  :no-local-sorting="true" :items="contractDocuments" :fields="fields" :busy="isDataLoading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small responsive>
					<template v-slot:head(actions)>
					</template>
					<template #top-row v-if="isShowFilter">
					</template>
					<template v-slot:cell(no)="row">
						{{ row.index + 1 }}
					</template>
					<template v-slot:cell(incomingDocument)="row">
						{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentCode : "-" }}
					</template>
					<template v-slot:cell(outgoingDocument)="row">
						{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentCode : "-" }}
					</template>
					<template v-slot:cell(contractDate)="row">
						{{ row.item.contractDate | moment("DD-MMM-YYYY") }}
					</template>
					<template v-slot:cell(contractStatus)="row">
						{{ row.item.contractStatus != null ? row.item.contractStatus.name : "-" }}
					</template>
					<template v-slot:cell(documentCode)="row">
						<span v-if="row.item.outgoingDocument != null">
							{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentCode : "-" }}
						</span>
					</template>
					<template v-slot:cell(senderName)="row">
						<span v-if="row.item.outgoingDocument != null">
							<b-badge variant="secondary" v-for="item in row.item.outgoingDocument.outgoingDocumentDestinationUser.slice(0,3)" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
								{{ item.destinationUser ? item.destinationUser.fullName : "-" }}
							</b-badge>
						</span>
					</template>
					<template v-slot:cell(contactPerson)="row">
						<span v-if="row.item.outgoingDocument != null">
							{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.contactPerson : "-" }}
						</span>
					</template>
					<template v-slot:cell(documentDate)="row">
						<span v-if="row.item.outgoingDocument != null">
							{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentDate : "-" | moment("DD-MMM-YYYY")}}
						</span>
					</template>
					<template v-slot:cell(documentType)="row">
						<span v-if="row.item.outgoingDocument != null">
							{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentType != null ? row.item.outgoingDocument.documentType.name : "-" : "-" }}
						</span>
					</template>
					<template v-slot:cell(documentLocation)="row">
						<span v-if="row.item.outgoingDocument != null">
							{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentLocation != null ? row.item.outgoingDocument.documentLocation.name : "-" : "-" }}
						</span>
					</template>
					<template v-slot:cell(documentNo)="row">
						<span v-if="row.item.outgoingDocument != null">
							{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentNo : "-" }}
						</span>
					</template>
					<template v-slot:cell(documentAttachment)="row">
						<span v-if="row.item.outgoingDocument != null">
							{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentNo : "-" }}
						</span>
					</template>
				</b-table>
			</b-col>
		</b-row>
		<b-row class="mt-2">
			<b-col cols="6">
			</b-col>
			<b-col cols="6">
				<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="date & time" label-for="filter-incoming-document">
					{{ printedDate | moment('DD-MMM-YYYY HH:mm') }}
				</b-form-group>
			</b-col>
			<b-col cols="6">
			</b-col>
			<b-col cols="6">
				<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="prepared by" label-for="filter-incoming-document">
					{{ user }}
				</b-form-group>
			</b-col>
		</b-row>
		<b-row class="mt-3" id="btn-action">
			<b-button class="ml-2" variant="success" size="sm" @click="printPage"><b-icon-printer /> Print</b-button>
			<b-button class="ml-2" variant="danger" size="sm" @click="closePage"><b-icon-x /> Close</b-button>
		</b-row>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-card>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
	:deep .col-form-label {
		padding-top: 0rem !important;
		padding-bottom: 0rem !important;
	}

	h6 {
		font-weight: bold;
	}
</style>
<script>
import ContractDocument from '@/models/ContractDocument/ContractDocument';
import ContractDocumentList from '@/models/ContractDocument/ContractDocumentList';
import ContractDocumentFilter from '@/filters/ContractDocument/ContractDocumentFilter';
import RoleDetail from '@/models/Core/RoleDetail';

import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import AppInputTextarea from '@/components/AppInputTextarea';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import ContractStatusFilter from '@/filters/ContractStatus/ContractStatusFilter';
import ContractStatus from '@/models/ContractStatus/ContractStatus';
import PopupDownload from '@/components/PopupDownload';
import PopupPDF from '@/components/PopupPDF';
import PopupDataEditNotification from '@/components/PopupDataEditNotification';
import Upload from './Upload';
import { mapGetters } from 'vuex'

export default {
	components : {
		AppInputDatepicker,AppInputDatepickerRange,AppInputComboboxAutocomplete,AppInputTextarea
	},
	data() {
		return {
			model: null,
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			filter : {},
			currentContractDocumentPage : 1,
			contractDocumentPageSize: 10,
			sortByContractDocument: '',
			sortDescContractDocument: '',
			incomingDocuments : [],
			sortingBy: Array(),
			filter: null,
			sortBy: '',
			sortDesc: '',
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fields: [
				{"actionsCheck": { "class" : "th-actions-check", "label" : ""}},
				{"no" : { "class" : "th-number"}}, 
				{"documentCode": {}, "class" : "th-incoming-document", "label" : "Kode Risalah", key: 'documentCode', sortable: false}, 
				{"description": {}, "class" : "th-description", "label" : "Perihal/Ringkasan Surat", key: 'description', sortable: false}, 
				{"remainingDay": {}, "class" : "th-remaining-day", "label" : "Masa Berlaku Kontrak", key: 'remainingContractDay', sortable: false}, 
				{"documentDate": {}, "class" : "th-document-date", "label" : "Tanggal Surat", key: 'documentDate', sortable: false}, 
				{"documentNo": {}, "class" : "th-document-no", "label" : "Nomor Surat", key: 'documentNo', sortable: false},
				{"contractStatus": {}, "class" : "th-contract-status", "label" : "Status Kontrak", key: 'contractStatus', sortable: false}, 
				{"documentLocation": {}, "class" : "th-document-location", "label" : "Lokasi Arsip Surat", key: 'documentLocation', sortable: false}, 
				{"remarks": {}, "class" : "th-remarks", "label" : "Catatan", key: 'remarks', sortable: false}, 
			],
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			contractDocuments: [],
			isShowFilter: false,
			printedDate : null

		}
	},
	methods : {
		printPage() { window.print() },
		closePage() { window.close() },
		getContractDocuments() {
			this.isDataLoading = true;
			this.sortingBy = Array();
			if (this.sortBy != null && this.sortBy !=  "") {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["contractDate"] = true;
			}
			
			ContractDocument.getContractOutgoingList(this, this.filter, this.sortingBy, 0, 0, 'incomingDocument,outgoingDocument,contractStatus,outgoingDocumentDestinationUser')
			.then(result => {
				if (result != null) {
					this.contractDocuments = result.data
					if (this.contractDocuments != null && this.contractDocuments.length > 0) {
						if (this.contractDocuments[0].outgoingDocumentDestinationUser) {
							let model = this.contractDocuments[0].outgoingDocumentDestinationUser.filter(item => item.destinationUserId == this.filter.senderName);
							console.log(model);
							if (model != null && model.length > 0)
								this.model = model[0];
						}
					}
					this.contractDocumentsPaging = result.pagination
				}
				this.isDataLoading = false;
			})
			.catch(error => {
				this.isDataLoading = false;
			});
		},
	},
	beforeMount() {
		this.filter = new ContractDocumentFilter();
	},
	watch : {
	},
	mounted(){
		let id = this.$route.params.id;
		this.filter.senderName = id;
		this.getContractDocuments();
		this.printedDate = new Date();
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		userRole() {
			return RoleDetail.query().where("functionInfoId", "contract_document").first();
		},
		...mapGetters({
			user: 'user',
		})
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
