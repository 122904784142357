<template>
	<div>
		<b-button class="filter" variant="light" block href="#" v-b-toggle.accordion-filter>
			<span><b-icon-funnel-fill></b-icon-funnel-fill> Filter</span>
			<span class="menu-arrow">
				<b-icon-chevron-right class="float-right chevron-right"></b-icon-chevron-right><b-icon-chevron-down class="float-right chevron-down"></b-icon-chevron-down>
			</span>
		</b-button>
		<b-collapse id="accordion-filter" accordion="accordion-filter" role="tabpanel" class="menu-accordion">
			<b-card>
				<b-row>
					<b-col cols="12" lg="6">
					<app-input-combobox-autocomplete v-if="isSuperUser" :options="documentLocations" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" id="filter-" size="sm" :model.sync="filter.documentLocationId" @input="getDocumentLocations" @focus="getDocumentLocations" />
					<app-input-combobox :options="documentCategory" optionsLabel="text" optionsKey="value"  label-cols-sm="4" label-cols-lg="3" label="Kategori Surat" id="filter-" size="sm" :model.sync="filter.documentCategory" :selected="filter.documentCategory"  :isSelectMethodExists="true" @onSelectMethod="changeDocumentCategory" />
					<b-button variant="danger" size="sm" class="float-right" @click="resetFilter"><span style="font-size: 0.75rem" class="mr-1"><b-icon-x></b-icon-x></span> Clear Filter</b-button>
					<b-button variant="primary" size="sm" class="float-right mr-1" @click="getReceiptDocuments"><span style="font-size: 0.75rem" class="mr-1"><b-icon-search></b-icon-search></span> Cari</b-button>
					</b-col>
				</b-row>
			</b-card>
		</b-collapse>
		<b-row class="mt-3">
			<b-col>
				<b-button variant="warning" v-if="(userRole != null ? userRole.allowUpload : false)" class="float-right ml-2" @click="showPopupUploadData" size="sm"><b-icon-upload class="mr-1"></b-icon-upload> Upload</b-button>
				<b-overlay
					v-if="(userRole != null ? userRole.allowDownload : false)"
					:show="isDownloadDataBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="float-right "
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowDownload : false)" class="float-right ml-2" @click="downloadData" size="sm"><b-icon-download class="mr-1"></b-icon-download> Download</b-button>
				</b-overlay>
				<b-button variant="primary" v-if="(userRole != null ? userRole.allowDownload : false)" class="float-right" href="/files/ReceiptDocument/ReceiptDocument.xlsx" size="sm"><b-icon-wallet-fill class="mr-1"></b-icon-wallet-fill> Download Template</b-button>
			</b-col>
		 </b-row>
		<b-card class="mt-3">
		<b-row class="mb-3">
			<b-col>
				<b-button variant="success" v-if="isSuperUser" to="/receiptdocument/create" size="sm"><b-icon-pencil class="mr-1"></b-icon-pencil> Create</b-button>
				<b-overlay
					v-if="(userRole != null ? userRole.allowPrint : false)"
					:show="isMultiplePrintBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowPrint : false)" class="ml-2" @click="printData" size="sm"><b-icon-printer-fill class="mr-1"></b-icon-printer-fill> Multiple Print</b-button>
				</b-overlay>
			</b-col>
			<b-col>
				<span class="float-right">
					<b-form-select v-model="pageSizeOptions" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
				<b-overlay
							:show="isExportDataBusy"
							rounded
							opacity="0.6"
							spinner-small
							spinner-variant="primary"
							class="float-right mr-2"
							>
					<b-button variant="warning" @click="exportData" size="sm"><b-icon icon="file-spreadsheet" class="mr-1"></b-icon> Export Data</b-button>
				</b-overlay>
			</b-col>
		</b-row>
		<div class="table-corner">
			<b-table id="my-table" stacked="md" head-variant="light"  :no-local-sorting="true" :items="receiptDocuments" :fields="fields" :busy="isDataLoading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small responsive>
			<template v-slot:head(actions)>
				<b-button-group class="float-right"  size="sm">
					<b-button @click="isShowFilter = !isShowFilter" size="sm"><b-icon-funnel-fill /></b-button>
					<b-button><b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" id="input-select-all" class="float-right" v-model="selectAll"></b-form-checkbox></b-button>
				</b-button-group>
			</template>
			<template #top-row v-if="isShowFilter">
				<b-th></b-th>
				<b-th stacked-heading="Kode Risalah">
					<b-form-input id="input-document-code-table" @input="getReceiptDocumentsWithoutLoading" v-model="filter.documentCode" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Tanggal">
					<b-form-datepicker boundary="viewport" placeholder="" id="input-document-date-from-table" reset-button @input="getReceiptDocumentsWithoutLoading" v-model="filter.createdAtFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
					<b-form-datepicker boundary="viewport" placeholder="" id="input-document-date-to-table" reset-button @input="getReceiptDocumentsWithoutLoading" v-model="filter.createdAtTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
				</b-th>
				<b-th stacked-heading="Nomor Surat">
					<b-form-input id="input-document-no-table" @input="getReceiptDocumentsWithoutLoading" v-model="filter.documentNo" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Nama Pengirim">
					<b-form-input id="input-sender-name-table" @input="getReceiptDocumentsWithoutLoading" v-model="filter.senderName" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Ditujukan Kepada">
					<v-select append-to-body label="fullName" :options="userProfiles" :value="filter.destinationUserId" :reduce="item => item.id" v-model="filter.destinationUserId" :filterable="true" @input="getReceiptDocumentsWithoutLoading" @search="getUserProfiles"></v-select>
				</b-th>
				<b-th stacked-heading="No. Tanda Terima Surat">
					<b-form-input id="input-receipt-document-no-table" @input="getReceiptDocumentsWithoutLoading" v-model="filter.receiptDocumentNo" size="sm"></b-form-input>
				</b-th>
				<b-th></b-th>
				<b-th stacked-heading="Select All">
					<b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" id="input-select-all" class="float-right d-block d-md-none" v-model="selectAll"></b-form-checkbox>
				</b-th>
			</template>
			<template v-slot:cell(no)="row">
				{{ ((row.index + 1) + ( pageSizeOptions * (currentPage - 1))) }}
			</template>
			<template v-slot:cell(documentLocation)="row">
				{{ row.item.documentLocation != null ? row.item.documentLocation.name : "" }}
			</template>
			<template v-slot:cell(destinationUser)="row">
				<span v-if="row.item.incomingDocument != null && row.item.incomingDocument.isSendToAll">
					Semua Karyawan
				</span>
				<span v-else-if="row.item.outgoingDocument != null && row.item.outgoingDocument.isSendToAll">
					Semua Karyawan
				</span>
				<span v-else>
					<span v-if="row.item.destinationUser != null">
							{{ row.item.destinationUser ? row.item.destinationUser.fullName : "-" }}
					</span>
				</span>
			</template>
			<template v-slot:cell(documentDate)="row">
				{{ row.item.documentDate | moment("DD-MMM-YYYY") }}
			</template>
			<template v-slot:cell(createdAt)="row">
				{{ row.item.createdAt | moment("DD-MMM-YYYY") }}
			</template>
			<template v-slot:cell(documentType)="row">
				{{ row.item.documentType != null ? row.item.documentType.name : "" }}
			</template>
			<template v-slot:cell(deliveryUser)="row">
				{{ row.item.deliveryUser != null ? row.item.deliveryUser.fullName : "" }}
			</template>
			<template v-slot:cell(status)="row">
				<span>
					<app-input-item-combobox :options="documentStatuses" optionsLabel="text" optionsKey="value" label-cols-sm="4" label-cols-lg="3" id="input-" size="sm" :itemData="row.item" :selected="row.item.isReceived ? 'Diterima' : 'Belum Diterima'" :isSelectMethodExists="true" @onSelectMethod="changeDocumentStatus" />
				</span>
				<span v-if="false">
					<span v-if="row.item.incomingDestinationUserId != null && row.item.incomingDestinationUserId != '' && row.item.incomingDestinationUserId.id != undefined">
						<app-input-item-combobox :options="documentStatuses" optionsLabel="text" optionsKey="value" label-cols-sm="4" label-cols-lg="3" id="input-" size="sm" :itemData="row.item.incomingDestinationUserId" :selected="row.item.incomingDestinationIsReceived ? 'Diterima' : 'Belum Diterima'" :isSelectMethodExists="true" @onSelectMethod="changeIncomingDocumentStatus" />
					</span>
					<span v-if="row.item.outgoingDestinationUserId != null && row.item.outgoingDestinationUserId != '' && row.item.outgoingDestinationUserId.id != undefined">
						<app-input-item-combobox :options="documentStatuses" optionsLabel="text" optionsKey="value" label-cols-sm="4" label-cols-lg="3" id="input-" size="sm" :itemData="row.item.outgoingDestinationUserId" :selected="row.item.outgoingDestinationIsReceived ? 'Diterima' : 'Belum Diterima'" :isSelectMethodExists="true" @onSelectMethod="changeOutgoingDocumentStatus" />
					</span>
				</span>
			</template>
			<template v-slot:cell(receiptUser)="row">
				{{ row.item.receiptUser != null ? row.item.receiptUser.fullName : "" }}
			</template>
			<template v-slot:cell(actions)="row" >
				<div class="div-actions float-right">
				<b-button size="sm" v-if="isSuperUser" :to="{path: '/receiptdocument/edit/' + row.item.id }" variant ="success"  class="mr-2 btn-xs">
					<b-icon-pencil></b-icon-pencil>
				</b-button>
				<b-overlay
					v-if="isSuperUser"
					:show="busy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<click-confirm>
					<b-button size="sm" variant="danger" @click="deleteData(row.item.id)" class="mr-2 btn-xs">
						<b-icon-trash></b-icon-trash>
					</b-button>
					</click-confirm>
				</b-overlay>
				<b-button size="sm" v-if="(userRole != null ? userRole.allowRead : true)" variant="primary" class="mr-2 mt-md-2 mt-lg-0 btn-xs" :to="{path: '/receiptdocument/detail/' + row.item.id }" >
					<b-icon-eye-fill></b-icon-eye-fill>
				</b-button>
				<b-button size="sm" v-if="(userRole != null ? userRole.allowPrint : false)" variant="light"  @click="printSingleData(row.item.id)" class="mr-2 mt-md-2 mt-lg-0 btn-xs">
					<b-icon-printer-fill></b-icon-printer-fill>
				</b-button>
				<b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" class="d-inline" :id="'input-is-print-' + (row.index + 1)" @change="toggleIsPrint(Number(row.item.id))" :checked="checkAllData.includes(Number(row.item.id))" ></b-form-checkbox>
				</div>
			</template>
			</b-table>
		</div>
		<b-row>
			<b-col cols="12" md="6" lg="6">
				<b-button variant="success" v-if="isSuperUser" to="/receiptdocument/create" size="sm"><b-icon-pencil class="mr-1"></b-icon-pencil> Create</b-button>
				<b-overlay
					v-if="(userRole != null ? userRole.allowPrint : false)"
					:show="isMultiplePrintBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowPrint : false)" class="ml-2" @click="printData" size="sm"><b-icon-printer-fill class="mr-1"></b-icon-printer-fill> Multiple Print</b-button>
				</b-overlay>
			</b-col>
			<b-col cols="12" md="6" lg="6">
			<b-pagination
				class="float-md-right btn-paging"
				v-model="currentPage"
				:total-rows="(receiptDocumentsPaging != null ? receiptDocumentsPaging.total : 0)"
				:per-page="(receiptDocumentsPaging != null ? receiptDocumentsPaging.perPage : 0)"
				aria-controls="my-table"
			></b-pagination>
			</b-col>
		</b-row>
		</b-card>
	</div>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
</style>
<script>
import ReceiptDocument from '@/models/ReceiptDocument/ReceiptDocument';
import ReceiptDocumentList from '@/models/ReceiptDocument/ReceiptDocumentList';
import ReceiptDocumentFilter from '@/filters/ReceiptDocument/ReceiptDocumentFilter';
import RoleDetail from '@/models/Core/RoleDetail';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppInputItemCombobox from '@/components/AppInputItemCombobox';
import AppInputCombobox from '@/components/AppInputCombobox';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import AppInputTextarea from '@/components/AppInputTextarea';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import DocumentTypeFilter from '@/filters/DocumentType/DocumentTypeFilter';
import DocumentType from '@/models/DocumentType/DocumentType';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import PopupDownload from '@/components/PopupDownload';
import PopupPDF from '@/components/PopupPDF';
import PopupDataEditNotification from '@/components/PopupDataEditNotification';
import Upload from './Upload';
import { mapGetters } from 'vuex';

export default {
	components : {AppInputCombobox, AppInputItemCombobox, ReceiptDocument, ReceiptDocumentList, ReceiptDocumentFilter, AppInputTextbox,AppInputDatepicker,AppInputDatepickerRange,AppInputTextarea,AppInputComboboxAutocomplete,AppInputTextarea,PopupDownload,PopupPDF,Upload,PopupDataEditNotification,},
	data() {
		return {
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fields: [
				{"no" : { "class" : "th-number"}}, 
				{"documentCode": {}, "class" : "th-document-code", "label" : "Kode Risalah", key: 'documentCode', sortable: true}, 
				{"createdAt": {}, "class" : "th-document-date", "label" : "Tanggal", key: 'createdAt', sortable: true}, 
				{"documentNo": {}, "class" : "th-document-no", "label" : "Nomor Surat", key: 'documentNo', sortable: true}, 
				{"senderName": {}, "class" : "th-sender-name", "label" : "Pengirim/Pemohon Surat", key: 'senderName', sortable: true}, 
				{"destinationUser": {}, "class" : "th-destination-user", "label" : "Ditujukan Kepada", key: 'destinationUser', sortable: true}, 
				//{"deliveryUser": {}, "class" : "th-delivery-user", "label" : "Penyerah Surat", key: 'deliveryUser', sortable: true}, 
				//{"receiptUser": {}, "class" : "th-receipt-user", "label" : "Penerima Surat", key: 'receiptUser', sortable: true}, 
				{"receiptDocumentNo": {}, "class" : "th-receipt-document-no", "label" : "No. Tanda Terima Surat", key: 'receiptDocumentNo', sortable: true}, 
				//{"remarks": {}, "class" : "th-remarks", "label" : "Catatan", key: 'remarks', sortable: true}, 
				{"status": {}, "class" : "th-status", "label" : "Status", key: 'status', sortable: false}, 
				{"actions": { "class" : "th-actions", "label" : ""}}
			],
			busy:false,
			isDataLoading: false,
			isDownloadDataBusy: false,
			isMultiplePrintBusy: false,
			showPopupUpload: false,
			showPopupDownload: false,
			isShowPopupPDF: false,
			pdfUrl: "",
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			filter: null,
			processId: "",
			popupDownloadLabel: "",
			hasLoadedData: false,
			showFilter: false,
			isShowFilter: false,
			isCreateBusy: false,
			btnEditData: [],
			currentDataId: null,
			receiptDocuments: [],
			receiptDocumentsPaging: {},
			documentLocations : [],
			userProfiles : [],
			documentTypes : [],
			incomingDocuments : [],
			documentStatuses:[
				{ value : true, text : 'Diterima' },
				{ value : false, text : 'Belum Diterima' },
			],
			documentCategory:[
				{ value : null, text : 'All' },
				{ value : "SMA", text : 'Surat Masuk' },
				{ value : "SKI", text : 'Surat Keluar Internal' },
				{ value : "SKE", text : 'Surat Keluar External' },
			],
			isExportDataBusy: false,
		}
	},
	methods : {
		getReceiptDocuments : async function() {
			this.isDataLoading = true;
			this.sortingBy = Array();
			if (this.sortBy != null && this.sortBy != "") {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["createdAt"] = true;
			}
			ReceiptDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'documentLocation,deliveryUser,documentType,destinationUser,receiptUser,incomingDocument,outgoingDocumentDestinationUser,incomingDocumentDestinationUser,',)
			.then(result => {
				if (result != null) {
					this.receiptDocuments = result.data
					this.receiptDocumentsPaging = result.pagination
				}
				this.isDataLoading = false;
			})
			.catch(error => {
				this.isDataLoading = false;
			});
		},
		getReceiptDocumentsWithoutLoading : async function() {
			this.sortingBy = Array();
			if (this.sortBy != null && this.sortBy != "") {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["createdAt"] = true;
			}
			ReceiptDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'documentLocation,deliveryUser,documentType,destinationUser,receiptUser,incomingDocument,outgoingDocumentDestinationUser,incomingDocumentDestinationUser')
			.then(result => {
				if (result != null) {
					this.receiptDocuments = result.data
					this.receiptDocumentsPaging = result.pagination
				}
			})
			.catch(error => {});
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentLocation.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentTypes : async function(input) {
			let filter = new DocumentTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentTypes = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		resetFilter() {
			this.filter = new ReceiptDocumentFilter();
		},
		showPopupUploadData() {
			this.showPopupUpload = true;
		},
		uploadData(modalEvent) {
			this.showPopupUpload = false;
		},
		resetModal() {
			this.showPopupUpload = false;
			this.showPopupDownload = false;
			this.isShowPopupPDF = false;
		},
		resetModalUpload() {
			this.showPopupUpload = false;
			this.getReceiptDocuments();
		},
		toggleIsPrint(id) {
			if (this.checkAllData.includes(id)) {
				this.$store.dispatch("removeCheckData", id);
			} else {
				this.$store.dispatch("addCheckData", id);
			}
		},
		showPopupPrint(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di cetak";
			this.processId = id;
		},
		showPopupDownloadData(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di unduh";
			this.processId = id;
		},
		showPopupPDF(url) {
			this.isShowPopupPDF = true;
			this.pdfUrl = url;
		},
		printData() {
			let data = this.$store.state.dataIds;
			if (data.length > 0) {
				this.isMultiplePrintBusy = true;
				ReceiptDocument.multiPagePrint(this).then(result => {
					if (result) {
						this.showPopupPrint(result.id);
					}
					this.isMultiplePrintBusy = false;
				}).catch(error => { this.isMultiplePrintBusy = false; });
			}
		},
		printSingleData(id) {
			ReceiptDocument.singleDataPagePrint(this, id).then(result => {
				if (result) {
					this.showPopupPDF(result.download);
				}
			});
		},
		downloadData() {
			if (this.sortBy != null) {
					this.sortingBy[this.sortBy] = this.sortDesc;
			}
			this.isDownloadDataBusy = true;
			ReceiptDocument.downloadData(this, this.filter, this.sortingBy).then(result => {
				if (result) {
					this.showPopupDownloadData(result.id);
				}
				this.isDownloadDataBusy = false;
			}).catch(error => { this.isDownloadDataBusy = false; })
		},
		deleteData(id) {
			ReceiptDocument.deleteData(this, id).then(result => {
				if (result)
				{
					this.getReceiptDocuments();
				}
			})
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768) {
				this.isShowFilter = true;
			} else {
				this.isShowFilter = this.showFilter;
			}
		},
		changeDocumentStatus(selectedValue, data){
			//if (this.isSuperUser) {
				if (selectedValue) {
					data.isReceived = selectedValue.value;
				} else {
					data.isReceived = false
				}

				let id = data.id;

				if (id != '' && id != null && id != undefined) {
					ReceiptDocument.updateData(this, data, id).then(result => {
						this.getReceiptDocumentsWithoutLoading();
						this.isBusy = false;
					}).catch(error => { this.isBusy = false; });
				}
			//}
		},
		changeIncomingDocumentStatus(selectedValue, data){
			if (selectedValue) {
				data.isReceived = selectedValue.value;
			} else {
				data.isReceived = false
			}

			let id = data.id;

			if (id != '' && id != null && id != undefined) {
				this.$http.post(process.env.VUE_APP_API_URL + 'incomingdocument/receiveddestination/' + id, data).then(result => {
					this.getReceiptDocumentsWithoutLoading();
				});
			}
		},
		changeOutgoingDocumentStatus(selectedValue, data){
			if (selectedValue) {
				data.isReceived = selectedValue.value;
			} else {
				data.isReceived = false
			}

			let id = data.id;

			if (id != '' && id != null && id != undefined) {
				this.$http.post(process.env.VUE_APP_API_URL + 'outgoingdocument/receiveddestination/' + id, data).then(result => {
					this.getReceiptDocumentsWithoutLoading();
				});
			}
		},
		changeDocumentCategory(selectedValue, data){
			if (selectedValue) {
				this.filter.documentCategory = selectedValue.value;
			} else {
				this.filter.documentCategory = null;
			}

			//this.getReceiptDocuments();
		},
		exportData() {
			this.sortingBy = Array();
			if (this.sortBy != null && this.sortBy != "") {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["createdAt"] = true;
			}
			this.isExportDataBusy = true;
			ReceiptDocument.exportData(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'incomingDocument,userReceiver,incomingDocumentAttachmentDetail')
			.then(result => {
				if (result != null) {
					saveAs(result.data, "tanda_terima_surat.xlsx");
				}
				this.isExportDataBusy = false;
			})
			.catch(error => {
				this.isExportDataBusy = false;
			});
		}
	},
	beforeMount() {
		this.$store.dispatch("removeCheckAllData");
		this.filter = new ReceiptDocumentFilter();
		this.getDocumentLocations()
		//this.getUserProfiles()
		this.getDocumentTypes()
		//this.getIncomingDocuments()
	},
	mounted(){
		let breadcrumb =[
			//"Master Data", 
			"Tanda Terima Surat"
		];
		this.getReceiptDocuments();
		this.$store.dispatch('setBreadCrumb', breadcrumb);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
		});
	},
	watch: {
		sortBy: {
			handler: function() {
				this.getReceiptDocuments();
			}
		},
		sortDesc: {
			handler: function() {
				this.getReceiptDocuments();
			}
		},
		currentPage: {
			handler: function() {
				this.getReceiptDocuments();
			}
		},
		pageSizeOptions: {
			handler: function() {
				this.getReceiptDocuments();
			}
		},
		selectAll(newValue) {
			if (newValue) {
				ReceiptDocument.checkAllData(this, this.filter, this.sortingBy);
			} else {
				this.$store.dispatch("removeCheckAllData");
			}
		},
		'$route'(to, from) {
			if (to != null) {
				if (to.name == "receiptdocumentindex") {
					this.getReceiptDocumentsWithoutLoading();
				}
			}
		},
	},
	computed: {
		checkAllData() {
			return this.$store.state.dataIds;
		},
		userRole() {
			return RoleDetail.query().where("functionInfoId", "receipt_document").first();
		},
		...mapGetters({
			isSuperUser: 'isSuperUser',
			user: 'user',
			userId: 'userId',
		}),
	},
	beforeDestroy() {
		this.$store.dispatch("removeCheckAllData");
	},
}
</script>
