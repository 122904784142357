<template>
	<div>
		<b-button class="filter" variant="light" block href="#" v-b-toggle.accordion-filter>
			<span><b-icon-funnel-fill></b-icon-funnel-fill> Filter</span>
			<span class="menu-arrow">
				<b-icon-chevron-right class="float-right chevron-right"></b-icon-chevron-right><b-icon-chevron-down class="float-right chevron-down"></b-icon-chevron-down>
			</span>
		</b-button>
		<b-collapse id="accordion-filter" accordion="accordion-filter" role="tabpanel" class="menu-accordion">
			<b-card>
				<b-row>
					<b-col cols="12" lg="6">
						<app-input-combobox-autocomplete :options="outgoingDocumentFilters" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" id="filter-" size="sm" :model.sync="filter.id" @input="getFilteredOutgoingDocuments" @focus="getFilteredOutgoingDocuments" />
						<app-input-textbox id="fieldset-name" label-cols-sm="4" label-cols-lg="3" label="Nomor Surat" label-for="filter-name" size="sm" :model.sync="filter.documentNo" />
						<app-input-combobox-autocomplete :options="documentLocations" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" id="filter-" size="sm" :model.sync="filter.documentLocationId" @input="getDocumentLocations" @focus="getDocumentLocations" />
						<b-button variant="danger" size="sm" class="float-right" @click="resetFilter"><span style="font-size: 0.75rem" class="mr-1"><b-icon-x></b-icon-x></span> Clear Filter</b-button>
						<b-button variant="primary" size="sm" class="float-right mr-1" @click="getOutgoingDocuments"><span style="font-size: 0.75rem" class="mr-1"><b-icon-search></b-icon-search></span> Cari</b-button>
					</b-col>
				</b-row>
			</b-card>
		</b-collapse>
		<b-row class="mt-3">
			<b-col>
				<b-button variant="warning" v-if="(userRole != null ? userRole.allowUpload : false)" class="float-right ml-2" @click="showPopupUploadData" size="sm"><b-icon-upload class="mr-1"></b-icon-upload> Upload</b-button>
				<b-button variant="primary" v-if="(userRole != null ? userRole.allowDownload : false)" class="float-right" href="/files/OutgoingDocument/OutgoingDocument.xlsx" size="sm"><b-icon-wallet-fill class="mr-1"></b-icon-wallet-fill> Download Template</b-button>
			</b-col>
		 </b-row>
		<b-card class="mt-3">
		<b-row class="mb-3">
			<b-col>
				<b-button variant="success" v-if="(userRole != null ? userRole.allowCreate : false)" @click="createData" size="sm"><b-icon-pencil class="mr-1"></b-icon-pencil> Tambah</b-button>
				<b-overlay
					v-if="(userRole != null ? userRole.allowUpdate : false)"
					:show="isSendDataBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline ml-2"
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowUpdate : false)" :disabled="checkAllData.length <= 0 ? true : false" @click="sendData" size="sm"><b-icon-play class="mr-1"></b-icon-play> Kirim</b-button>
				</b-overlay>
				<b-overlay
					v-if="(userRole != null ? userRole.allowPrint : false)"
					:show="isMultiplePrintBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowPrint : false)" class="ml-2" @click="printData" size="sm"><b-icon-printer-fill class="mr-1"></b-icon-printer-fill> Multiple Print</b-button>
				</b-overlay>
			</b-col>
			<b-col>
				<span class="float-right">
					<b-form-select v-model="pageSizeOptions" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
				<b-overlay
							:show="isExportDataBusy"
							rounded
							opacity="0.6"
							spinner-small
							spinner-variant="primary"
							class="float-right mr-2"
							>
					<b-button variant="warning" @click="exportData" size="sm"><b-icon icon="file-spreadsheet" class="mr-1"></b-icon> Export Data</b-button>
				</b-overlay>
			</b-col>
		</b-row>
		<div class="table-corner">
			<b-table id="my-table" stacked="md" head-variant="light"  :no-local-sorting="true" :items="outgoingDocuments" :fields="fields" :busy="isDataLoading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small responsive>
			<template v-slot:head(actionsCheck)>
				<b-button-group size="sm" v-if="false">
					<b-button><b-form-checkbox v-if="(userRole != null ? userRole.allowUpdate : false)" id="input-select-all" class="float-right" v-model="selectAll"></b-form-checkbox></b-button>
				</b-button-group>
			</template>
			<template v-slot:head(actions)>
				<b-button-group class="float-right" size="sm">
					<b-button @click="isShowFilter = !isShowFilter" size="sm"><b-icon-funnel-fill /></b-button>
				</b-button-group>
			</template>
			<template #top-row v-if="isShowFilter">
				<b-th stacked-heading="Select All">
					<b-form-checkbox v-if="(userRole != null ? userRole.allowUpdate : false)" id="input-select-all" class="float-right d-block d-md-none" v-model="selectAll"></b-form-checkbox>
				</b-th>
				<b-th></b-th>
				<b-th stacked-heading="Kode Risalah">
					<b-form-input id="input-document-code-table" @input="getOutgoingDocumentsWithoutLoading" v-model="filter.documentCode" size="sm"></b-form-input>
				</b-th>
				<b-th>
					<b-form-datepicker boundary="viewport" placeholder="" id="input-document-date-from-table" reset-button @input="getOutgoingDocumentsWithoutLoading" v-model="filter.createdAtFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
					<b-form-datepicker boundary="viewport" placeholder="" id="input-document-date-to-table" reset-button @input="getOutgoingDocumentsWithoutLoading" v-model="filter.createdAtTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
				</b-th>
				<b-th stacked-heading="Pemohon Surat">
					<v-select append-to-body label="fullName" :options="userProfiles" :value="filter.createdByUserProfile" :reduce="item => item.id" v-model="filter.createdByUserProfile" :filterable="true" @input="getOutgoingDocumentsWithoutLoading" @search="getUserProfiles" @open="getUserProfiles"></v-select>
				</b-th>
				<b-th></b-th>
				<b-th stacked-heading="Jenis Surat">
					<v-select append-to-body label="name" :options="documentTypes" :value="filter.documentType" :reduce="item => item.id" v-model="filter.documentType" :filterable="true" @input="getOutgoingDocumentsWithoutLoading" @search="getSenderStatuss"></v-select>
				</b-th>
				<b-th>
					<b-form-input id="input-document-code-table" @input="getOutgoingDocumentsWithoutLoading" v-model="filter.description" size="sm"></b-form-input>
				</b-th>
				<b-th></b-th>
				<b-th stacked-heading="Nomor Surat">
					<b-form-input id="input-document-no-table" @input="getOutgoingDocumentsWithoutLoading" v-model="filter.documentNo" size="sm"></b-form-input>
				</b-th>
				<b-th>
					<v-select  :components="{OpenIndicator, Deselect}" append-to-body label="name" :options="outgoingDocumentStatuss" :value="filter.outgoingDocumentStatusId" :reduce="item => item.id" v-model="filter.outgoingDocumentStatusId" :filterable="true" @input="getOutgoingDocumentsWithoutLoading" @search="getOutgoingDocumentStatuss" @open="getOutgoingDocumentStatuss"></v-select>
				</b-th>
				<b-th stacked-heading="">
				</b-th>
			</template>
			<template v-slot:cell(no)="row">
				{{ ((row.index + 1) + ( pageSizeOptions * (currentPage - 1))) }}
			</template>
			<template v-slot:cell(documentLocation)="row">
				{{ row.item.documentLocation != null ? row.item.documentLocation.name : "" }}
			</template>
			<template v-slot:cell(createdByUserProfile)="row">
				{{ row.item.createdByUserProfile != null ? row.item.createdByUserProfile.fullName : "" }}
			</template>
			<template v-slot:cell(applicant)="row">
				<span v-if="row.item.isSendToAll">
					Semua Karyawan
				</span>
				<span v-else>
					<b-badge variant="secondary" v-for="item in row.item.outgoingDocumentDestinationUser.slice(0,3)" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
						{{ item.destinationUser ? item.destinationUser.fullName : "-" }}
					</b-badge>
					<span v-if="row.item.outgoingDocumentDestinationUser.length > 3">
						<br /><b-button size="sm" variant="secondary" class="btn-xs" @click="showPopupDestinationUser(row.item)">Show All</b-button>
					</span>
				</span>
			</template>
			<template v-slot:cell(destinationStatus)="row">
				{{ row.item.destinationStatus != null ? row.item.destinationStatus.name : "" }}
			</template>
			<template v-slot:cell(documentDate)="row">
				{{ row.item.createdAt | moment("DD-MMM-YYYY") }}
			</template>
			<template v-slot:cell(createdAt)="row">
				{{ row.item.createdAt | moment("DD-MMM-YYYY") }}
			</template>
			<template v-slot:cell(documentType)="row">
				<span :class="row.item.documentType != null ? '' : (isSuperUser ? 'data-not-complete' : '')">
					{{ row.item.documentType != null ? row.item.documentType.name : (isSuperUser ? "**lengkapi data**" : "-") }}
				</span>
			</template>
			<template v-slot:cell(draftAttachment)="row">
				{{ row.item.draftAttachment != null ? row.item.draftAttachment.originalFilename : "" }}
			</template>
			<template v-slot:cell(lampiranAttachment)="row">
				<span v-if="false">
					{{ row.item.lampiranAttachment != null ? row.item.lampiranAttachment.originalFilename : "" }}
					<b-button  v-if="row.item.lampiranAttachment" size="sm" @click="downloadData(item.lampiranAttachment)" variant ="primary"  class="mr-2 btn-xs">
						<b-icon-download></b-icon-download>
					</b-button>
				</span>
				<b-btn-group v-if="row.item.isLampiran" @click="showPopupDocument(row.item)">
					<b-button size="sm" variant="secondary" class="btn-xs">
						{{ row.item.documentAttachmentCount }} Attachment(s)
					</b-button>
					<b-button size="sm" variant="secondary" class="btn-xs">
						<b-icon-paperclip></b-icon-paperclip>
					</b-button>
				</b-btn-group>
			</template>
			<template v-slot:cell(documentSigner)="row">
				<b-badge variant="secondary" v-for="item in row.item.outgoingDocumentSignerUser" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
					{{ item.userProfile ? item.userProfile.fullName : "-" }}
				</b-badge>
			</template>
			<template v-slot:cell(documentChecker)="row">
				<b-badge variant="secondary" v-for="item in row.item.outgoingDocumentCheckerUser" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
					{{ item.userProfile ? item.userProfile.fullName : "-" }}
				</b-badge>
			</template>
			<template v-slot:cell(outgoingDocumentStatus)="row">
				{{ row.item.outgoingDocumentStatus != null ? row.item.outgoingDocumentStatus.name : "" }}
			</template>
			<template v-slot:cell(actionsCheck)="row" >
				<span v-if="row.item.outgoingDocumentStatusId == 'SAVED' || (isSuperUser && row.item.outgoingDocumentStatusId == 'SUBMISSION' && row.item.isDataComplete)">
					<b-form-checkbox v-if="(userRole != null ? userRole.allowUpdate : true)" class="d-inline" :id="'input-is-print-' + (row.index + 1)" @change="toggleSendData(Number(row.item.id))" :checked="checkAllData.includes(Number(row.item.id))" ></b-form-checkbox>
				</span>
			</template>
			<template v-slot:cell(actions)="row" >
				<div class="div-actions float-right">
				<span v-if="row.item.outgoingDocumentStatusId == 'SENT' ? true : false">
					<b-button v-if="isSuperUser" size="sm" v-b-popover.hover.left="row.item.isReminder ? 'Matikan Reminder' : 'Nyalakan Reminder'" :variant="row.item.isReminder ? 'success' : 'light'"  @click="toggleIsReminder(row.item)" class="mr-2 mt-md-2 mt-lg-0 btn-xs">
						<b-icon-toggle-on v-if="row.item.isReminder"></b-icon-toggle-on>
						<b-icon-toggle-off v-else></b-icon-toggle-off>
					</b-button>
					<b-overlay
						v-if="isSuperUser"
						:show="isRalatBusy"
						rounded
						opacity="0.6"
						spinner-small
						spinner-variant="primary"
						class="d-inline-block"
						>
						<click-confirm>
							<b-button size="sm" v-b-popover.hover.left="'Ralat'" :variant="'warning'"  @click="ralatDocument(row.item)" class="mr-2 mt-md-2 mt-lg-0 btn-xs">
								<b-icon-files></b-icon-files>
							</b-button>
						</click-confirm>
					</b-overlay>
				</span>
				<span v-if="row.item.outgoingDocumentStatusId != 'APPROVED' && row.item.outgoingDocumentStatusId != 'REJECTED' && row.item.outgoingDocumentStatusId != 'outgoingDocumentStatusId'">
					<span v-if="row.item.outgoingDocumentStatusId == 'SENT'">
						<span v-if="isSuperUser">
							<b-button size="sm" v-b-popover.hover.left="'Edit'" v-if="(userRole != null ? userRole.allowUpdate : false)" :to="{path: '/outgoingdocument/edit/' + row.item.id }" variant ="success"  class="mr-2 btn-xs">
								<b-icon-pencil></b-icon-pencil>
							</b-button>
						</span>
					</span>
					<span v-else>
						<b-button size="sm" v-b-popover.hover.left="'Edit'" v-if="(userRole != null ? userRole.allowUpdate : false)" :to="{path: '/outgoingdocument/edit/' + row.item.id }" variant ="success"  class="mr-2 btn-xs">
							<b-icon-pencil></b-icon-pencil>
						</b-button>
					</span>
				</span>
				<span v-if="row.item.outgoingDocumentStatusId != 'APPROVED' && row.item.outgoingDocumentStatusId != 'SENT'">
					<b-overlay
						v-if="(userRole != null ? userRole.allowDelete : false)"
						:show="busy"
						rounded
						opacity="0.6"
						spinner-small
						spinner-variant="primary"
						class="d-inline-block"
						>
						<click-confirm>
						<b-button v-b-popover.hover.left="'Delete'" size="sm" variant="danger" @click="deleteData(row.item.id)" class="mr-2 btn-xs">
							<b-icon-trash></b-icon-trash>
						</b-button>
						</click-confirm>
					</b-overlay>
				</span>
				<b-overlay
					v-if="row.item.outgoingDocumentStatusId == 'WAITING_APPROVAL' || row.item.outgoingDocumentStatusId == 'SUBMISSION' || row.item.outgoingDocumentStatusId == 'SAVED' || row.item.outgoingDocumentStatusId == 'NEED_REVISION'"
					:show="busy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<click-confirm>
					<b-button v-b-popover.hover.left="'Cancel'" size="sm" variant="danger" @click="cancelData(row.item.id)" class="mr-2 btn-xs">
						<b-icon-x></b-icon-x>
					</b-button>
					</click-confirm>
				</b-overlay>
				<b-button v-b-popover.hover.left="'Detail'" size="sm" v-if="(userRole != null ? userRole.allowRead : true)" variant="primary" class="mr-2 mt-md-2 mt-lg-0 btn-xs" :to="{path: '/outgoingdocument/detail/' + row.item.id }" >
					<b-icon-eye-fill></b-icon-eye-fill>
				</b-button>
				<b-button size="sm" v-if="(userRole != null ? userRole.allowPrint : false)" variant="light"  @click="printSingleData(row.item.id)" class="mr-2 mt-md-2 mt-lg-0 btn-xs">
					<b-icon-printer-fill></b-icon-printer-fill>
				</b-button>
				</div>
			</template>
			</b-table>
		</div>
		<b-row>
			<b-col cols="12" md="6" lg="6">
				<b-button variant="success" v-if="(userRole != null ? userRole.allowCreate : false)" @click="createData" size="sm"><b-icon-pencil class="mr-1"></b-icon-pencil> Tambah</b-button>
				<b-overlay
					v-if="(userRole != null ? userRole.allowUpdate : false)"
					:show="isSendDataBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline ml-2"
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowUpdate : false)" :disabled="checkAllData.length <= 0 ? true : false" @click="sendData" size="sm"><b-icon-play class="mr-1"></b-icon-play> Kirim</b-button>
				</b-overlay>
				<b-overlay
					v-if="(userRole != null ? userRole.allowPrint : false)"
					:show="isMultiplePrintBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowPrint : false)" class="ml-2" @click="printData" size="sm"><b-icon-printer-fill class="mr-1"></b-icon-printer-fill> Multiple Print</b-button>
				</b-overlay>
			</b-col>
			<b-col cols="12" md="6" lg="6">
			<b-pagination
				class="float-md-right btn-paging"
				v-model="currentPage"
				:total-rows="(outgoingDocumentsPaging != null ? outgoingDocumentsPaging.total : 0)"
				:per-page="(outgoingDocumentsPaging != null ? outgoingDocumentsPaging.perPage : 0)"
				aria-controls="my-table"
			></b-pagination>
			</b-col>
		</b-row>
		</b-card>
		<PopupDownloadDocument :openPopup="isShowPopupDocument" :allowUpdate="allowUpdate" :parent="parent" @resetMethod="resetModalUpload" />
		<PopupDestinationUsers :openPopup="isShowPopupDestinationUser" :parent="parent" @resetMethod="resetModalUpload" />
	</div>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
</style>
<script>
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import OutgoingDocumentList from '@/models/OutgoingDocument/OutgoingDocumentList';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';
import RoleDetail from '@/models/Core/RoleDetail';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import AppInputTextarea from '@/components/AppInputTextarea';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import Branch from '@/models/Branch/Branch';
import DocumentTypeFilter from '@/filters/DocumentType/DocumentTypeFilter';
import DocumentType from '@/models/DocumentType/DocumentType';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import SenderStatusFilter from '@/filters/SenderStatus/SenderStatusFilter';
import SenderStatus from '@/models/SenderStatus/SenderStatus';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AttachmentFilter from '@/filters/Core/AttachmentFilter';
import Attachment from '@/models/Core/Attachment';
import OutgoingDocumentStatusFilter from '@/filters/OutgoingDocumentStatus/OutgoingDocumentStatusFilter';
import OutgoingDocumentStatus from '@/models/OutgoingDocumentStatus/OutgoingDocumentStatus';
import PopupDownload from '@/components/PopupDownload';
import PopupPDF from '@/components/PopupPDF';
import PopupDataEditNotification from '@/components/PopupDataEditNotification';
import Upload from './Upload';
import PopupDownloadDocument from './PopupDownloadDocument';
import PopupDestinationUsers from './PopupDestinationUsers';
import { mapGetters } from 'vuex';

export default {
	components : {PopupDestinationUsers, PopupDownloadDocument, OutgoingDocument, OutgoingDocumentList, OutgoingDocumentFilter, AppInputTextbox,AppInputTextarea,AppInputComboboxAutocomplete,AppInputTextarea,AppInputDatepicker,AppInputDatepickerRange,PopupDownload,PopupPDF,Upload,PopupDataEditNotification,},
	data() {
		return {
			OpenIndicator: {
                render: createElement => createElement('b-icon-chevron-down',  {class: {'btn-transparent': true}}),
            },
            Deselect: {
                render: createElement => createElement('b-icon-x',  {class: {'btn-transparent': true, 'btn-grey' : true}}),
            },
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fields: [
				{"actionsCheck": { "class" : "th-actions-check", "label" : ""}},
				{"no" : { "class" : "th-number"}}, 
				{"documentCode": {}, "class" : "th-document-code", "label" : "Kode Risalah", key: 'documentCode', sortable: true}, 
				{"createdAt": {}, "class" : "th-document-date", "label" : "Tanggal", key: 'createdAt', sortable: true}, 
				{"createdByUserProfile": {}, "class" : "th-created-by", "label" : "Pemohon Surat", key: 'createdByUserProfile', sortable: true}, 
				//{"documentLocation": {}, "class" : "th-document-location", "label" : "Lokasi Dokumen", key: 'documentLocation', sortable: true}, 
				{"applicant": {}, "class" : "th-applicant", "label" : "Ditujukan Kepada", key: 'applicant', sortable: true}, 
				{"documentType": {}, "class" : "th-document-type", "label" : "Jenis Surat", key: 'documentType', sortable: true}, 
				{"description": {}, "class" : "th-description", "label" : "Perihal Surat", key: 'description', sortable: true}, 
				//{"draftAttachment": {}, "class" : "th-draft-attachment", "label" : "Draft", key: 'draftAttachment', sortable: true}, 
				{"lampiranAttachment": {}, "class" : "th-lampiran-attachment", "label" : "File Dokumen", key: 'lampiranAttachment', sortable: false}, 
				{"documentNo": {}, "class" : "th-document-no", "label" : "Nomor Surat", key: 'documentNo', sortable: true}, 
				//{"documentSigner": {}, "class" : "th-document-signer", "label" : "Penandatangan Surat", key: 'documentSigner', sortable: false}, 
				//{"documentChecker": {}, "class" : "th-document-checker", "label" : "Pemeriksa Surat", key: 'documentChecker', sortable: false}, 
				{"outgoingDocumentStatus": {}, "class" : "th-outgoing-document-status", "label" : "Status", key: 'outgoingDocumentStatus', sortable: true}, 
				{"actions": { "class" : "th-actions", "label" : ""}}
			],
			busy:false,
			isDataLoading: false,
			isDownloadDataBusy: false,
			isMultiplePrintBusy: false,
			showPopupUpload: false,
			showPopupDownload: false,
			isShowPopupPDF: false,
			isSendDataBusy: false,
			pdfUrl: "",
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			filter: null,
			processId: "",
			popupDownloadLabel: "",
			hasLoadedData: false,
			showFilter: false,
			isShowFilter: false,
			isCreateBusy: false,
			btnEditData: [],
			currentDataId: null,
			outgoingDocuments: [],
			outgoingDocumentsPaging: {},
			documentLocations : [],
			documentTypes : [],
			userProfiles : [],
			senderStatuss : [],
			outgoingDocumentStatuss : [],
			parent: {},
			allowUpdate: false,
			isShowPopupDestinationUser: false,
			isShowPopupDocument: false,
			isRalatBusy: false,
			outgoingDocumentFilters: [],
			isExportDataBusy: false,
		}
	},
	methods : {
		toggleIsReminder(model) {
			if ((model.reminderDateTo == null || model.reminderDateTo == undefined) && (model.reminderDateFrom == null || model.reminderDateFrom == undefined)) {
				this.$store.dispatch('addErrorMessageGlobal', "Untuk menyalakan reminder, silahkan set Reminder Date terlebih dahulu.");  
			} else {
				this.$http.get(process.env.VUE_APP_API_URL + 'outgoingdocument/toggleisreminder/' + model.id).then(result => {
					this.getOutgoingDocumentsWithoutLoading();
				});
			}
		},
		ralatDocument(model) {
			this.isRalatBusy = true;
			this.$http.get(process.env.VUE_APP_API_URL + 'outgoingdocument/ralatdocument/' + model.id).then(result => {
				this.getOutgoingDocumentsWithoutLoading();
				this.isRalatBusy = false;
			}).catch(error => {
				this.isRalatBusy = false;
			});;
		},
		showPopupDocument(parent) {	
			this.allowUpdate = false;
			if (this.userId == parent.createdBy) {
				this.allowUpdate = true;
			}
			if (this.isSuperUser) {
				this.allowUpdate = true;
			}
			this.parent=parent;
			this.isShowPopupDocument = true;
		},
		getOutgoingDocuments : async function() {
			this.isDataLoading = true;
			this.sortingBy = Array();
			if (this.sortBy != null && this.sortBy != "") {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["createdAt"] = true;
			}
			this.filter["isInternal"] = 1;
			OutgoingDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'documentLocation,applicant,destinationStatus,draftAttachment,lampiranAttachment,outgoingDocumentStatus,createdByUserProfile,documentType,outgoingDocumentCheckerUser,outgoingDocumentSignerUser,outgoingDocumentDestinationUser,outgoingDocumentAttachmentDetail')
			.then(result => {
				if (result != null) {
					this.outgoingDocuments = result.data
					this.outgoingDocumentsPaging = result.pagination
				}
				this.isDataLoading = false;
			})
			.catch(error => {
				this.isDataLoading = false;
			});
		},
		getFilteredOutgoingDocuments : async function(input) {
			let filter = new OutgoingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			filter.isInternal = 1;
			OutgoingDocument.getList(this, filter, this.sortingBy, this.currentPage, this.pageSizeOptions, '')
			.then(result => {
				if (result != null) {
					this.outgoingDocumentFilters = result.data
				}
			})
			.catch(error => {});
		},
		getOutgoingDocumentsWithoutLoading : async function() {
			this.sortingBy = Array();
			if (this.sortBy != null && this.sortBy != "") {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["createdAt"] = true;
			}
			this.filter["isInternal"] = 1;
			OutgoingDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'documentLocation,applicant,destinationStatus,draftAttachment,lampiranAttachment,outgoingDocumentStatus,createdByUserProfile,documentType,outgoingDocumentCheckerUser,outgoingDocumentSignerUser,outgoingDocumentDestinationUser,outgoingDocumentAttachmentDetail')
			.then(result => {
				if (result != null) {
					this.outgoingDocuments = result.data
					this.outgoingDocumentsPaging = result.pagination
				}
			})
			.catch(error => {});
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Branch.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentTypes : async function(input) {
			let filter = new DocumentTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentTypes = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getSenderStatuss : async function(input) {
			let filter = new SenderStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			SenderStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.senderStatuss = result.data
				}
			})
			.catch(error => {});
		},
		getOutgoingDocumentStatuss : async function(input) {
			let filter = new OutgoingDocumentStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			OutgoingDocumentStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.outgoingDocumentStatuss = result.data
				}
			})
			.catch(error => {});
		},
		resetFilter() {
			this.filter = new OutgoingDocumentFilter();
		},
		showPopupUploadData() {
			this.showPopupUpload = true;
		},
		uploadData(modalEvent) {
			this.showPopupUpload = false;
		},
		resetModal() {
			this.showPopupUpload = false;
			this.showPopupDownload = false;
			this.isShowPopupPDF = false;
		},
		resetModalUpload() {
			this.isShowPopupDocument = false;
			this.showPopupUpload = false;
			this.isShowPopupDestinationUser = false;
			this.getOutgoingDocuments();
		},
		showPopupDestinationUser(parent) {
			this.parent=parent;
			this.isShowPopupDestinationUser = true;
		},
		toggleSendData(id) {
			if (this.checkAllData.includes(id)) {
				this.$store.dispatch("removeCheckData", id);
			} else {
				this.$store.dispatch("addCheckData", id);
			}
		},
		showPopupPrint(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di cetak";
			this.processId = id;
		},
		showPopupDownloadData(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di unduh";
			this.processId = id;
		},
		showPopupPDF(url) {
			this.isShowPopupPDF = true;
			this.pdfUrl = url;
		},
		sendData() {
			let data = this.$store.state.dataIds;
			if (data.length > 0) {
				this.isSendDataBusy = true;
				let this_ = this;
				OutgoingDocument.sendMultipleDocument(this).then(result => {
					this_.getOutgoingDocuments();
					this_.$store.dispatch("removeCheckAllData");
					this_.isSendDataBusy = false;
				}).catch(error => { this_.isSendDataBusy = false; });
			}
		},
		printSingleData(id) {
			OutgoingDocument.singleDataPagePrint(this, id).then(result => {
				if (result) {
					this.showPopupPDF(result.download);
				}
			});
		},
		/*downloadData() {
			if (this.sortBy != null) {
					this.sortingBy[this.sortBy] = this.sortDesc;
			}
			this.isDownloadDataBusy = true;
			OutgoingDocument.downloadData(this, this.filter, this.sortingBy).then(result => {
				if (result) {
					this.showPopupDownloadData(result.id);
				}
				this.isDownloadDataBusy = false;
			}).catch(error => { this.isDownloadDataBusy = false; })
		},*/
		deleteData(id) {
			OutgoingDocument.deleteData(this, id).then(result => {
				if (result)
				{
					this.$store.dispatch('addSuccessMessageGlobal', "Data Berhasil dihapus");
					this.getOutgoingDocuments();
				}
			})
		},
		cancelData() {
			this.isBusy = true;
			this.$http.get(process.env.VUE_APP_API_URL + "outgoingdocument/canceldocument/" + this.modelId).then(result => {
				this.isBusy = false;
				this.getOutgoingDocument(this.modelId);
				this.$store.dispatch('addSuccessMessageGlobal', "Dokumen Berhasil dibatalkan");
			})
			.catch(error => {
				this.$store.dispatch('addErrorMessageGlobal', error);
				this.isBusy = false;
				throw error;
			});
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768) {
				this.isShowFilter = true;
			} else {
				this.isShowFilter = this.showFilter;
			}
		},
		createData() {
			this.isCreateBusy = true;
			OutgoingDocument.createData(this).then(result => {
				if (result != null) {
					if (result.id != null && result.id != "") {
						this.$router.push({ name: 'outgoingdocumentedit', params : { id: result.id } });
					}
				}
				this.isCreateBusy = false;
			}).catch(error => {
				this.isCreateBusy = false;
			})
		},
		downloadData(currentModel) {
          if (currentModel == null || currentModel == undefined) return; 
          let url = process.env.VUE_APP_API_URL + 'attachment/download/' + currentModel.id;
          let fileName = currentModel.originalFilename;
          this.$http.get(url, {responseType: 'blob'}).then(response => {
            if (response != null) {
              saveAs(response.data, fileName);
            }
          }).catch(error => {
            this.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
          })
        },
		exportData() {
			this.sortingBy = Array();
			if (this.sortBy != null && this.sortBy != "") {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["createdAt"] = true;
			}
			this.filter["isInternal"] = 1;
			this.isExportDataBusy = true;
			OutgoingDocument.exportData(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'documentLocation,senderStatus,userProfile,destinationUser,copyUser,sifatSurat,documentType,distributionType,incomingDocumentStatus,copyDocumentStatus,documentStatus,documentAttachment,incomingDocumentDestinationUser,incomingDocumentCopyUser')
			.then(result => {
				if (result != null) {
					saveAs(result.data, "surat_keluar_internal.xlsx");
				}
				this.isExportDataBusy = false;
			})
			.catch(error => {
				this.isExportDataBusy = false;
			});
		}
	},
	beforeMount() {
		this.$store.dispatch("removeCheckAllData");
		this.filter = new OutgoingDocumentFilter();
	},
	mounted(){
		let breadcrumb =[
			"Surat Keluar", 
			"Surat Keluar Internal"
		];
		this.getOutgoingDocuments();
		this.$store.dispatch('setBreadCrumb', breadcrumb);
		this.$nextTick(() => {
			this.getDocumentLocations();
			this.getDocumentTypes();
			//this.getOutgoingDocumentStatuss();
		});
	},
	watch: {
		sortBy: {
			handler: function() {
				this.getOutgoingDocuments();
			}
		},
		sortDesc: {
			handler: function() {
				this.getOutgoingDocuments();
			}
		},
		currentPage: {
			handler: function() {
				this.getOutgoingDocuments();
			}
		},
		pageSizeOptions: {
			handler: function() {
				this.getOutgoingDocuments();
			}
		},
		selectAll(newValue) {
			if (newValue) {
				OutgoingDocument.checkAllData(this, this.filter, this.sortingBy);
			} else {
				this.$store.dispatch("removeCheckAllData");
			}
		},
		'$route'(to, from) {
			if (to != null) {
				if (to.name == "outgoingdocumentindex") {
					this.getOutgoingDocumentsWithoutLoading();
				}
			}
		},
	},
	computed: {
		checkAllData() {
			return this.$store.state.dataIds;
		},
		userRole() {
			return RoleDetail.query().where("functionInfoId", "outgoing_document").first();
		},
		...mapGetters({
			isSuperUser: 'isSuperUser',
			user: 'user',
			userId: 'userId',
		}),
	},
	beforeDestroy() {
		this.$store.dispatch("removeCheckAllData");
	},
}
</script>
