import BaseModelAPI from '../BaseModelAPI';

export default class ContractDocument extends BaseModelAPI {
	static entity = 'contractdocument';
	static entityName = 'contractdocument';

    constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.incomingDocumentId = '';
			this.incomingDocument = {};
			this.outgoingDocumentId = '';
			this.outgoingDocument = {};
			this.contractDate = '';
			this.contractStatusId = '';
			this.contractStatus = {};
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

	static async getContractIncomingList(this_, filter, sorting, pageIndex, pageSize, include) {
        //console.log(...arguments);
        let filterArray = Array();
        if (filter != null && filter != undefined) {
            //console.log(filter);
            filterArray = this.getFilter(filter);
        }
        if (sorting != null && sorting != undefined) {
            Object.entries(sorting).map(([key, value]) => { 
                if(key != '') {
                    filterArray.push("sort=" + (value ? "-" : "+") + key);     // draftFromUpload & draftMode
                }
            });
                
        }
        if (pageIndex != null) {
            filterArray.push("page=" + pageIndex);
        }
        if (pageSize != null) {
            filterArray.push("per_page=" + pageSize);
        }
        if (include != null) {
            filterArray.push("include=" + include);
        }
        let params = "";
        if (filterArray.length > 0) {
            params = "?" + filterArray.join("&");
        }
        
        //DEBUG

        return this_.$http.get(`${this.entityName}incoming` + params)
        .then(response => {
            if (response != null) {
                return response.data;
            }
            return response;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async getContractOutgoingList(this_, filter, sorting, pageIndex, pageSize, include) {
        //console.log(...arguments);
        let filterArray = Array();
        if (filter != null && filter != undefined) {
            //console.log(filter);
            filterArray = this.getFilter(filter);
        }
        if (sorting != null && sorting != undefined) {
            Object.entries(sorting).map(([key, value]) => { 
                if(key != '') {
                    filterArray.push("sort=" + (value ? "-" : "+") + key);     // draftFromUpload & draftMode
                }
            });
                
        }
        if (pageIndex != null) {
            filterArray.push("page=" + pageIndex);
        }
        if (pageSize != null) {
            filterArray.push("per_page=" + pageSize);
        }
        if (include != null) {
            filterArray.push("include=" + include);
        }
        let params = "";
        if (filterArray.length > 0) {
            params = "?" + filterArray.join("&");
        }
        
        //DEBUG

        return this_.$http.get(`${this.entityName}outgoing` + params)
        .then(response => {
            if (response != null) {
                return response.data;
            }
            return response;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async archivedData(this_) {
        let _this = this_;
        let ids = _this.$store.state.dataIds;
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}archiveddocument`, {
                ids
                })
            .then(async function(response){
                if (response.data) {
                    return response.data;
                }
                return null;
            })
            .catch(function(error){
                this_.$store.dispatch('addErrorMessageGlobal', error);
                throw error;
            });
    }

}