<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true id="popup-upload" @ok="uploadButtonAction" :okTitle="okTitle" title="Upload Data">
		<b-card>
			<b-row>
				<b-col cols="12">
					<app-input-fileupload label="File" size="sm" :model.sync="fileupload" />
				</b-col>
			</b-row>
			<b-row v-if="isUploadSuccess">
				<b-col cols="12">
					<b-card no-body>
						<b-tabs card>
							<b-tab title="Receipt Document" active>
								<b-row class="mb-2">
									<b-col>
										<span class="float-right">
											<b-form-select v-model="receiptDocumentPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
										</span>
									</b-col>
								</b-row>
								<b-row>
								<b-col>
								<div class="table-corner">
									<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="receiptDocuments" :fields="fieldsReceiptDocument" :busy="isDataLoading" :sort-by.sync="sortByReceiptDocument" :sort-desc.sync="sortDescReceiptDocument" small responsive>
									<template v-slot:head(actions)>
										<b-button @click="isShowReceiptDocumentFilter = !isShowReceiptDocumentFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
									</template>
									<template #top-row v-if="isShowReceiptDocumentFilter">
										<b-th></b-th>
										<b-th stacked-heading="No. Tanda Terima Surat">
											<b-form-input id="input-receipt-document-no-table" v-model="filterReceiptDocument.receiptDocumentNo" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Kode Risalah">
											<b-form-input id="input-document-code-table" v-model="filterReceiptDocument.documentCode" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Tanggal & Waktu">
											<b-form-datepicker boundary="viewport" placeholder="" id="input-document-created-date-from-table" v-model="filterReceiptDocument.documentCreatedDateFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
											<b-form-datepicker boundary="viewport" placeholder="" id="input-document-created-date-to-table" v-model="filterReceiptDocument.documentCreatedDateTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
										</b-th>
										<b-th stacked-heading="Lokasi Surat">
											<v-select append-to-body label="name" :options="documentLocations" :value="filterReceiptDocument.documentLocationId"  :reduce="item => item.id" v-model="filterReceiptDocument.documentLocationId" @search="getDocumentLocations" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Nama Pengirim">
											<b-form-input id="input-sender-name-table" v-model="filterReceiptDocument.senderName" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Ditujukan Kepada">
											<v-select append-to-body label="fullName" :options="userProfiles" :value="filterReceiptDocument.destinationUserId"  :reduce="item => item.id" v-model="filterReceiptDocument.destinationUserId" @search="getUserProfiles" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Tanggal Surat">
											<b-form-datepicker boundary="viewport" placeholder="" id="input-document-date-from-table" v-model="filterReceiptDocument.documentDateFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
											<b-form-datepicker boundary="viewport" placeholder="" id="input-document-date-to-table" v-model="filterReceiptDocument.documentDateTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
										</b-th>
										<b-th stacked-heading="Jenis Surat/Barang">
											<v-select append-to-body label="name" :options="documentTypes" :value="filterReceiptDocument.documentTypeId"  :reduce="item => item.id" v-model="filterReceiptDocument.documentTypeId" @search="getDocumentTypes" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Nomor Surat">
											<b-form-input id="input-document-no-table" v-model="filterReceiptDocument.documentNo" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Ringkasan Surat">
											<b-form-input id="input-document-description-table" v-model="filterReceiptDocument.documentDescription" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Nama Penyerah Surat">
											<v-select append-to-body label="fullName" :options="userProfiles" :value="filterReceiptDocument.deliveryUserId"  :reduce="item => item.id" v-model="filterReceiptDocument.deliveryUserId" @search="getUserProfiles" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Nama Penerima Surat">
											<v-select append-to-body label="fullName" :options="userProfiles" :value="filterReceiptDocument.receiptUserId"  :reduce="item => item.id" v-model="filterReceiptDocument.receiptUserId" @search="getUserProfiles" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Catatan">
											<b-form-input id="input-remarks-table" v-model="filterReceiptDocument.remarks" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Incoming Document">
											<v-select append-to-body label="documentCode" :options="incomingDocuments" :value="filterReceiptDocument.incomingDocumentId"  :reduce="item => item.id" v-model="filterReceiptDocument.incomingDocumentId" @search="getIncomingDocuments" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="OutgoingDocument">
											<b-form-input id="input-outgoing-document-table" v-model="filterReceiptDocument.outgoingDocument" size="sm"></b-form-input>
										</b-th>
										<b-th>
										</b-th>
									</template>
									<template v-slot:table-busys>
										<div class="text-center my-2">
									<b-spinner class="align-middle text-primary"></b-spinner>
										<strong class="ml-2">Loading..</strong>
										</div>
									</template>
									<template v-slot:cell(no)="row">
										{{ ((row.index + 1) + ( receiptDocumentPageSize * (currentReceiptDocumentPage - 1))) }}
									</template>
									<template v-slot:cell(documentCreatedDate)="row">
										{{ row.item.documentCreatedDate | moment("DD-MMM-YYYY")  }}
									</template>
									<template v-slot:cell(documentLocation)="row">
										{{ row.item.documentLocation != null ? row.item.documentLocation.name : "" }}
									</template>
									<template v-slot:cell(destinationUser)="row">
										{{ row.item.destinationUser != null ? row.item.destinationUser.fullName : "" }}
									</template>
									<template v-slot:cell(documentDate)="row">
										{{ row.item.documentDate | moment("DD-MMM-YYYY")  }}
									</template>
									<template v-slot:cell(documentType)="row">
										{{ row.item.documentType != null ? row.item.documentType.name : "" }}
									</template>
									<template v-slot:cell(deliveryUser)="row">
										{{ row.item.deliveryUser != null ? row.item.deliveryUser.fullName : "" }}
									</template>
									<template v-slot:cell(receiptUser)="row">
										{{ row.item.receiptUser != null ? row.item.receiptUser.fullName : "" }}
									</template>
									<template v-slot:cell(incomingDocument)="row">
										{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentCode : "" }}
									</template>
									</b-table>
									</div>
								</b-col>
								<b-col cols="12">
									<b-pagination
									class="float-right btn-paging"
									v-model="currentReceiptDocumentPage"
									:total-rows="totalReceiptDocumentRow"
									:per-page="receiptDocumentPageSize"
									aria-controls="my-table"
									></b-pagination>
								</b-col>
								</b-row>
							</b-tab>
						</b-tabs>
					</b-card>
				</b-col>
			</b-row>
		</b-card>
		<template #modal-footer="{ ok, cancel }">
			<b-button v-if="isUploadSuccess" size="sm" variant="primary" @click="downloadLog()">
				Download Log
			</b-button>
			<b-button size="sm" variant="success" class="float-right" @click="ok()">
				{{ okTitle }}
			</b-button>
			<b-button size="sm" variant="secondary" class="float-right" @click="cancel()">
				Cancel
			</b-button>
		</template>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<style lang="scss" scoped>
.card {
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
:deep .th-message {
	color: red;
}
:deep .modal-footer {
	display: block;
}
</style>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate"; 
import { required, minLength } from "vuelidate/lib/validators"; 
import { saveAs } from 'file-saver';

import ReceiptDocument from '@/models/ReceiptDocument/ReceiptDocument';
import ReceiptDocumentList from '@/models/ReceiptDocument/ReceiptDocumentList';
import ReceiptDocumentFilter from '@/filters/ReceiptDocument/ReceiptDocumentFilter';

import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import DocumentType from '@/models/DocumentType/DocumentType';
import DocumentTypeFilter from '@/filters/DocumentType/DocumentTypeFilter';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import AppInputFileupload from '@/components/AppInputFileupload';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
export default {
	props: ['model', 'openPopup','uploadButtonMethod', 'parentId'],
	components : {AppInputFileupload,AppInputDatetimepicker,AppInputDatetimepickerRange,},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fieldsReceiptDocument: [
				{"no" : { "class" : "th-number"}}, 
				{"receiptDocumentNo": { "class" : "th-receipt-document-no", "label" : "No. Tanda Terima Surat"}, key: 'receiptDocumentNo', sortable: true}, 
				{"documentCode": { "class" : "th-document-code", "label" : "Kode Risalah"}, key: 'documentCode', sortable: true}, 
				{"documentCreatedDate": { "class" : "th-document-created-date", "label" : "Tanggal & Waktu"}, key: 'documentCreatedDate', sortable: true}, 
				{"documentLocation": { "class" : "th-document-location", "label" : "Lokasi Surat"}, key: 'documentLocation', sortable: true}, 
				{"senderName": { "class" : "th-sender-name", "label" : "Nama Pengirim"}, key: 'senderName', sortable: true}, 
				{"destinationUser": { "class" : "th-destination-user", "label" : "Ditujukan Kepada"}, key: 'destinationUser', sortable: true}, 
				{"documentDate": { "class" : "th-document-date", "label" : "Tanggal Surat"}, key: 'documentDate', sortable: true}, 
				{"documentType": { "class" : "th-document-type", "label" : "Jenis Surat/Barang"}, key: 'documentType', sortable: true}, 
				{"documentNo": { "class" : "th-document-no", "label" : "Nomor Surat"}, key: 'documentNo', sortable: true}, 
				{"documentDescription": { "class" : "th-document-description", "label" : "Ringkasan Surat"}, key: 'documentDescription', sortable: true}, 
				{"deliveryUser": { "class" : "th-delivery-user", "label" : "Nama Penyerah Surat"}, key: 'deliveryUser', sortable: true}, 
				{"receiptUser": { "class" : "th-receipt-user", "label" : "Nama Penerima Surat"}, key: 'receiptUser', sortable: true}, 
				{"remarks": { "class" : "th-remarks", "label" : "Catatan"}, key: 'remarks', sortable: true}, 
				{"incomingDocument": { "class" : "th-incoming-document", "label" : "Incoming Document"}, key: 'incomingDocument', sortable: true}, 
				{"outgoingDocument": { "class" : "th-outgoing-document", "label" : "OutgoingDocument"}, key: 'outgoingDocument', sortable: true}, 
				{"class" : "th-status", "label" : "Status", key: 'uploadValidationStatus', sortable: true},
				{"class" : "th-message", "label" : "Message", key: 'uploadValidationMessage', sortable: true},
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isDataLoading: false,
			isBusy : false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			receiptDocuments : [],
			receiptDocumentId : '',
			totalReceiptDocumentRow : 0,
			isShowReceiptDocumentFilter: false,
			filterReceiptDocument : null,
			currentReceiptDocumentPage : 1,
			receiptDocumentPageSize: 10,
			sortByReceiptDocument: '',
			sortDescReceiptDocument: '',
			documentLocations: [],
			userProfiles: [],
			documentTypes: [],
			incomingDocuments: [],
			processId: "",
			isUploadSuccess: false,
		}
	},
	methods : {
		resetModal() {
			this.datas = Array();
			this.$emit('resetMethod');
		},
		uploadButtonAction(modalEvent) {
			modalEvent.preventDefault();
			this.isBusy = true;
			if (this.isUploadSuccess) {
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'receiptdocument/confirmUpload',
					[],
					).then(async function(response){
						if (response != null) {
						if (response.data != null) {
							_this.resetModal();
							_this.datas = Array();
							_this.fileupload = null;
							_this.isUploadSuccess = false;
						}
						}
						_this.isBusy = false;
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
			} else {
				if (this.fileupload != null) {
				let data = new FormData();
				data.append('file', this.fileupload);
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'receiptdocument/upload',
					data,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
					).then(async function(response){
						_this.isBusy = false;
						_this.isUploadSuccess = true;
						_this.getReceiptDocuments();
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
				}
			}
		},
		getReceiptDocuments : async function() {
			let filter = {};
			Object.assign(filter, this.filterReceiptDocument);
			filter.draftFromUpload = "1";
			filter.draftMode = "1";
			this.sortingByReceiptDocument = Array();
			if (this.sortByReceiptDocument != null) {
				this.sortingByReceiptDocument[this.sortByReceiptDocument] = this.sortDescReceiptDocument;
			}
			let currentPage = (this.currentReceiptDocumentPage - 1);
			ReceiptDocument.getList(this, filter, this.sortingByReceiptDocument, currentPage , this.receiptDocumentPageSize).then(result => {
				this.receiptDocuments = result.data;
				this.totalReceiptDocumentRow = result.dataCount;
			}).catch(error => {});
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentLocation.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentTypes : async function(input) {
			let filter = new DocumentTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentTypes = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		downloadLog() {
			let _this = this;
			this.$http.post(process.env.VUE_APP_API_URL + 'receiptdocument/downloadlog', [],
			{ headers: { 'Content-Type': 'application/json' }}
			).then(async function(response){
				await saveAs(process.env.VUE_APP_API_URL + 'receiptdocument/downloadlog', "ReceiptDocument.xslx");
				_this.isBusy = false;
			})
			.catch(error => {
				_this.$store.dispatch('addErrorMessageGlobal', error);
				_this.isBusy = false;
			});
		}
	},
	beforeMount() {
	},
	mounted(){
	},
	watch: {
		sortByReceiptDocument : {
			handler: function() {
				this.getReceiptDocuments();
			}
		},
		sortDescReceiptDocument: {
			handler: function() {
				this.getReceiptDocuments();
			}
		},
		currentReceiptDocumentPage: {
			handler: function() {
				this.getReceiptDocuments();
			}
		},
		receiptDocumentPageSize : {
			handler: function() {
				this.getReceiptDocuments();
			}
		},
		filterReceiptDocument: {
			handler: function() {
				this.getReceiptDocuments();
			},
			deep: true,
		},
		fileupload(newValue, oldValue) {
			this.datas = Array();
			this.isUploadSuccess = false;
		}
	},
	computed: {
		okTitle: {
			get: function() {
				if (this.isUploadSuccess) {
					return "Proses";
				}
				return "Simpan";
				}
		},
		isShowPopup : {
			get: function () {
				if (this.openPopup) {
					this.filterReceiptDocument = new ReceiptDocumentFilter();
					return true;
				}
				else return false;
			},
			set: function (newValue) {}
		},
	}
}
</script>
