<template>
    <b-modal id="modal-file-preview" class="modal-file-viewer" body-class="p-0 modal-body-file-preview" scrollable :no-close-on-backdrop="true" title="File Viewer" :no-close-on-esc=true v-model="isShowPopup" @hidden="resetModal">
        <div class="loading" id="loading">
            <b-spinner variant="secondary" type="grow"></b-spinner>
        </div>
        <iframe id="iframe" :src=" this.serverUrl + 'attachment/filepreview/' + (this.model != null ? this.model.id : '')" frameborder="0" width="100%" height="100%" style="opacity: 0"></iframe>
    </b-modal>
</template>
<style scoped>
.loading {
    
    left: 0;
    position: absolute;
    top: 0;

    height: 100%;
    width: 100%;

    
    align-items: center;
    display: flex;
    justify-content: center;
}
</style>
<script>
import pdf from 'vue-pdf'

export default {
    components: { pdf },
    props: {
        model: {
            type: Object,
            default:null
        },
        src: {
            type: String,
            default: ""
        },
        dataType: {
            type: String,
            default: ""
        },
        openPopup: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            filename: "",
            show : true,
        }
    }, 
    methods: {
        resetModal() {
            if (this.model)
                this.deleteFile(this.model.id);

            this.$emit('resetModalMethod');
        },
        deleteFile(id) {
            return this.$http.delete(process.env.VUE_APP_API_URL + 'attachment/deletefile/' + id)
            .then(response => {
                return true;  
            }).catch(error => {
                console.error(error);
                return false;
            });
        },
    },
    watch: {
        openPopup(newValue) {
            if (newValue) {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        const iframeEle = document.getElementById('iframe');
                        const loadingEle = document.getElementById('loading');
                        
                        console.log(iframeEle);

                        if (iframeEle) {
                            iframeEle.addEventListener('load', function () {
                                loadingEle.style.display = 'none';
                                iframeEle.style.opacity = 1;
                            });
                        }
                    });
                });
            }
        }
    },
    computed: {
        isShowPopup : {
            get: function () {
              if (this.openPopup) return true;
              else return false;
            },
            set: function (newValue) {}
        },
        serverUrl : {
            get: function() {
                return process.env.VUE_APP_API_URL;
            }
        }
    },
}
</script>

<style>

</style>