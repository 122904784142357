<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true id="popup-upload" @ok="uploadButtonAction" :okTitle="okTitle" title="Upload Data">
		<b-card>
			<b-row>
				<b-col cols="12">
					<app-input-fileupload label="File" size="sm" :model.sync="fileupload" />
				</b-col>
			</b-row>
			<b-row v-if="isUploadSuccess">
				<b-col cols="12">
					<b-card no-body>
						<b-tabs card>
							<b-tab title="Incoming Document" active>
								<b-row class="mb-2">
									<b-col>
										<span class="float-right">
											<b-form-select v-model="incomingDocumentPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
										</span>
									</b-col>
								</b-row>
								<b-row>
								<b-col>
								<div class="table-corner">
									<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="incomingDocuments" :fields="fieldsIncomingDocument" :busy="isDataLoading" :sort-by.sync="sortByIncomingDocument" :sort-desc.sync="sortDescIncomingDocument" small responsive>
									<template v-slot:head(actions)>
										<b-button @click="isShowIncomingDocumentFilter = !isShowIncomingDocumentFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
									</template>
									<template #top-row v-if="isShowIncomingDocumentFilter">
										<b-th></b-th>
										<b-th stacked-heading="Kode Risalah">
											<b-form-input id="input-document-code-table" v-model="filterIncomingDocument.documentCode" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Lokasi Surat">
											<v-select append-to-body label="name" :options="documentLocations" :value="filterIncomingDocument.documentLocationId"  :reduce="item => item.id" v-model="filterIncomingDocument.documentLocationId" @search="getDocumentLocations" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Nama Pengirim">
											<b-form-input id="input-sender-name-table" v-model="filterIncomingDocument.senderName" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Status Pengirim">
											<v-select append-to-body label="name" :options="senderStatuss" :value="filterIncomingDocument.senderStatusId"  :reduce="item => item.id" v-model="filterIncomingDocument.senderStatusId" @search="getSenderStatuss" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Contact Person (CP)">
											<b-form-input id="input-contact-person-table" v-model="filterIncomingDocument.contactPerson" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Alamat Pengirim">
											<b-form-input id="input-sender-address-table" v-model="filterIncomingDocument.senderAddress" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="E-Mail Pengirim/CP">
											<b-form-input id="input-sender-email-table" v-model="filterIncomingDocument.senderEmail" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="No. Telepon">
											<b-form-input id="input-sender-phone-number-table" v-model="filterIncomingDocument.senderPhoneNumber" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Ditujukan Kepada">
											<v-select append-to-body label="fullName" :options="userProfiles" :value="filterIncomingDocument.destinationUserId"  :reduce="item => item.id" v-model="filterIncomingDocument.destinationUserId" @search="getUserProfiles" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Ditembuskan Kepada">
											<v-select append-to-body label="fullName" :options="userProfiles" :value="filterIncomingDocument.copyUserId"  :reduce="item => item.id" v-model="filterIncomingDocument.copyUserId" @search="getUserProfiles" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Sifat Surat">
											<v-select append-to-body label="name" :options="sifatSurats" :value="filterIncomingDocument.sifatSuratId"  :reduce="item => item.id" v-model="filterIncomingDocument.sifatSuratId" @search="getSifatSurats" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Jenis Surat/Barang">
											<v-select append-to-body label="name" :options="documentTypes" :value="filterIncomingDocument.documentTypeId"  :reduce="item => item.id" v-model="filterIncomingDocument.documentTypeId" @search="getDocumentTypes" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Nomor Surat">
											<b-form-input id="input-document-no-table" v-model="filterIncomingDocument.documentNo" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Tanggal Surat">
											<b-form-datepicker boundary="viewport" placeholder="" id="input-document-date-from-table" v-model="filterIncomingDocument.documentDateFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
											<b-form-datepicker boundary="viewport" placeholder="" id="input-document-date-to-table" v-model="filterIncomingDocument.documentDateTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
										</b-th>
										<b-th stacked-heading="Ringkasan Surat">
											<b-form-input id="input-description-table" v-model="filterIncomingDocument.description" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Masa Berlaku Kontrak">
											<b-form-datepicker boundary="viewport" placeholder="" id="input-contract-date-from-table" v-model="filterIncomingDocument.contractDateFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
											<b-form-datepicker boundary="viewport" placeholder="" id="input-contract-date-to-table" v-model="filterIncomingDocument.contractDateTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
										</b-th>
										<b-th stacked-heading="Catatan Atas Kontrak">
											<b-form-input id="input-contract-description-table" v-model="filterIncomingDocument.contractDescription" size="sm"></b-form-input>
										</b-th>
										<b-th>
										</b-th>
										<b-th stacked-heading="Bentuk Distribusi">
											<v-select append-to-body label="name" :options="distributionTypes" :value="filterIncomingDocument.distributionTypeId"  :reduce="item => item.id" v-model="filterIncomingDocument.distributionTypeId" @search="getDistributionTypes" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="No. Tanda Terima Surat">
											<b-form-input id="input-receipt-document-table" v-model="filterIncomingDocument.receiptDocument" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Reminder Kegiatan">
											<b-form-checkbox id="input-is-reminder-table" @change="getIncomingDocuments" class="float-right" v-model="filterIncomingDocument.isReminder"></b-form-checkbox>
										</b-th>
										<b-th stacked-heading="Tanggal / Waktu Kegiatan">
											<app-input-datetimepicker-range id="fieldset-reminder-date" label-cols-sm="4" label-cols-lg="3" :nolabel="true" label="Tanggal / Waktu Kegiatan" size="sm" :modelFrom.sync="filter.reminderDateFrom" :modelTo.sync="filter.reminderDateTo" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button/>
										</b-th>
										<b-th stacked-heading="Catatan">
											<b-form-input id="input-remarks-table" v-model="filterIncomingDocument.remarks" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="DocumentStatus">
											<v-select append-to-body label="name" :options="incomingDocumentStatuss" :value="filterIncomingDocument.documentStatusId"  :reduce="item => item.id" v-model="filterIncomingDocument.documentStatusId" @search="getIncomingDocumentStatuss" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="CopyDocumentStatus">
											<v-select append-to-body label="name" :options="incomingDocumentStatuss" :value="filterIncomingDocument.copyDocumentStatusId"  :reduce="item => item.id" v-model="filterIncomingDocument.copyDocumentStatusId" @search="getIncomingDocumentStatuss" :filterable="true"></v-select>
										</b-th>
										<b-th>
										</b-th>
									</template>
									<template v-slot:table-busys>
										<div class="text-center my-2">
									<b-spinner class="align-middle text-primary"></b-spinner>
										<strong class="ml-2">Loading..</strong>
										</div>
									</template>
									<template v-slot:cell(no)="row">
										{{ ((row.index + 1) + ( incomingDocumentPageSize * (currentIncomingDocumentPage - 1))) }}
									</template>
									<template v-slot:cell(documentLocation)="row">
										{{ row.item.documentLocation != null ? row.item.documentLocation.name : "" }}
									</template>
									<template v-slot:cell(senderStatus)="row">
										{{ row.item.senderStatus != null ? row.item.senderStatus.name : "" }}
									</template>
									<template v-slot:cell(destinationUser)="row">
										{{ row.item.destinationUser != null ? row.item.destinationUser.fullName : "" }}
									</template>
									<template v-slot:cell(copyUser)="row">
										{{ row.item.copyUser != null ? row.item.copyUser.fullName : "" }}
									</template>
									<template v-slot:cell(sifatSurat)="row">
										{{ row.item.sifatSurat != null ? row.item.sifatSurat.name : "" }}
									</template>
									<template v-slot:cell(documentType)="row">
										{{ row.item.documentType != null ? row.item.documentType.name : "" }}
									</template>
									<template v-slot:cell(documentDate)="row">
										{{ row.item.documentDate | moment("DD-MMM-YYYY")  }}
									</template>
									<template v-slot:cell(contractDate)="row">
										{{ row.item.contractDate | moment("DD-MMM-YYYY")  }}
									</template>
									<template v-slot:cell(documentAttachment)="row">
										{{ row.item.documentAttachment != null ? row.item.documentAttachment.originalFileName : "" }}
									</template>
									<template v-slot:cell(distributionType)="row">
										{{ row.item.distributionType != null ? row.item.distributionType.name : "" }}
									</template>
									<template v-slot:cell(reminderDate)="row">
										{{ row.item.reminderDate | moment("DD-MMM-YYYY HH:mm")  }}
									</template>
									<template v-slot:cell(documentStatus)="row">
										{{ row.item.documentStatus != null ? row.item.documentStatus.name : "" }}
									</template>
									<template v-slot:cell(copyDocumentStatus)="row">
										{{ row.item.copyDocumentStatus != null ? row.item.copyDocumentStatus.name : "" }}
									</template>
									</b-table>
									</div>
								</b-col>
								<b-col cols="12">
									<b-pagination
									class="float-right btn-paging"
									v-model="currentIncomingDocumentPage"
									:total-rows="totalIncomingDocumentRow"
									:per-page="incomingDocumentPageSize"
									aria-controls="my-table"
									></b-pagination>
								</b-col>
								</b-row>
							</b-tab>
						</b-tabs>
					</b-card>
				</b-col>
			</b-row>
		</b-card>
		<template #modal-footer="{ ok, cancel }">
			<b-button v-if="isUploadSuccess" size="sm" variant="primary" @click="downloadLog()">
				Download Log
			</b-button>
			<b-button size="sm" variant="success" class="float-right" @click="ok()">
				{{ okTitle }}
			</b-button>
			<b-button size="sm" variant="secondary" class="float-right" @click="cancel()">
				Cancel
			</b-button>
		</template>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<style lang="scss" scoped>
.card {
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
:deep .th-message {
	color: red;
}
:deep .modal-footer {
	display: block;
}
</style>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate"; 
import { required, minLength } from "vuelidate/lib/validators"; 
import { saveAs } from 'file-saver';

import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentList from '@/models/IncomingDocument/IncomingDocumentList';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';

import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import SenderStatus from '@/models/SenderStatus/SenderStatus';
import SenderStatusFilter from '@/filters/SenderStatus/SenderStatusFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import SifatSurat from '@/models/SifatSurat/SifatSurat';
import SifatSuratFilter from '@/filters/SifatSurat/SifatSuratFilter';
import DocumentType from '@/models/DocumentType/DocumentType';
import DocumentTypeFilter from '@/filters/DocumentType/DocumentTypeFilter';
import Attachment from '@/models/Core/Attachment';
import AttachmentFilter from '@/filters/Core/AttachmentFilter';
import DistributionType from '@/models/DistributionType/DistributionType';
import DistributionTypeFilter from '@/filters/DistributionType/DistributionTypeFilter';
import IncomingDocumentStatus from '@/models/IncomingDocumentStatus/IncomingDocumentStatus';
import IncomingDocumentStatusFilter from '@/filters/IncomingDocumentStatus/IncomingDocumentStatusFilter';
import AppInputFileupload from '@/components/AppInputFileupload';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
export default {
	props: ['model', 'openPopup','uploadButtonMethod', 'parentId'],
	components : {AppInputFileupload,AppInputDatetimepicker,AppInputDatetimepickerRange,},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fieldsIncomingDocument: [
				{"no" : { "class" : "th-number"}}, 
				{"documentCode": { "class" : "th-document-code", "label" : "Kode Risalah"}, key: 'documentCode', sortable: true}, 
				{"documentLocation": { "class" : "th-document-location", "label" : "Lokasi Surat"}, key: 'documentLocation', sortable: true}, 
				{"senderName": { "class" : "th-sender-name", "label" : "Nama Pengirim"}, key: 'senderName', sortable: true}, 
				{"senderStatus": { "class" : "th-sender-status", "label" : "Status Pengirim"}, key: 'senderStatus', sortable: true}, 
				{"contactPerson": { "class" : "th-contact-person", "label" : "Contact Person (CP)"}, key: 'contactPerson', sortable: true}, 
				{"senderAddress": { "class" : "th-sender-address", "label" : "Alamat Pengirim"}, key: 'senderAddress', sortable: true}, 
				{"senderEmail": { "class" : "th-sender-email", "label" : "E-Mail Pengirim/CP"}, key: 'senderEmail', sortable: true}, 
				{"senderPhoneNumber": { "class" : "th-sender-phone-number", "label" : "No. Telepon"}, key: 'senderPhoneNumber', sortable: true}, 
				{"destinationUser": { "class" : "th-destination-user", "label" : "Ditujukan Kepada"}, key: 'destinationUser', sortable: true}, 
				{"copyUser": { "class" : "th-copy-user", "label" : "Ditembuskan Kepada"}, key: 'copyUser', sortable: true}, 
				{"sifatSurat": { "class" : "th-sifat-surat", "label" : "Sifat Surat"}, key: 'sifatSurat', sortable: true}, 
				{"documentType": { "class" : "th-document-type", "label" : "Jenis Surat/Barang"}, key: 'documentType', sortable: true}, 
				{"documentNo": { "class" : "th-document-no", "label" : "Nomor Surat"}, key: 'documentNo', sortable: true}, 
				{"documentDate": { "class" : "th-document-date", "label" : "Tanggal Surat"}, key: 'documentDate', sortable: true}, 
				{"description": { "class" : "th-description", "label" : "Ringkasan Surat"}, key: 'description', sortable: true}, 
				{"contractDate": { "class" : "th-contract-date", "label" : "Masa Berlaku Kontrak"}, key: 'contractDate', sortable: true}, 
				{"contractDescription": { "class" : "th-contract-description", "label" : "Catatan Atas Kontrak"}, key: 'contractDescription', sortable: true}, 
				{"documentAttachment": { "class" : "th-document-attachment", "label" : "File Dokumen"}, key: 'documentAttachment', sortable: true}, 
				{"distributionType": { "class" : "th-distribution-type", "label" : "Bentuk Distribusi"}, key: 'distributionType', sortable: true}, 
				{"receiptDocument": { "class" : "th-receipt-document", "label" : "No. Tanda Terima Surat"}, key: 'receiptDocument', sortable: true}, 
				{"isReminder": { "class" : "th-is-reminder", "label" : "Reminder Kegiatan"}, key: 'isReminder', sortable: true}, 
				{"reminderDate": { "class" : "th-reminder-date", "label" : "Tanggal / Waktu Kegiatan"}, key: 'reminderDate', sortable: true}, 
				{"remarks": { "class" : "th-remarks", "label" : "Catatan"}, key: 'remarks', sortable: true}, 
				{"documentStatus": { "class" : "th-document-status", "label" : "DocumentStatus"}, key: 'documentStatus', sortable: true}, 
				{"copyDocumentStatus": { "class" : "th-copy-document-status", "label" : "CopyDocumentStatus"}, key: 'copyDocumentStatus', sortable: true}, 
				{"class" : "th-status", "label" : "Status", key: 'uploadValidationStatus', sortable: true},
				{"class" : "th-message", "label" : "Message", key: 'uploadValidationMessage', sortable: true},
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isDataLoading: false,
			isBusy : false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			incomingDocuments : [],
			incomingDocumentId : '',
			totalIncomingDocumentRow : 0,
			isShowIncomingDocumentFilter: false,
			filterIncomingDocument : null,
			currentIncomingDocumentPage : 1,
			incomingDocumentPageSize: 10,
			sortByIncomingDocument: '',
			sortDescIncomingDocument: '',
			documentLocations: [],
			senderStatuss: [],
			userProfiles: [],
			sifatSurats: [],
			documentTypes: [],
			distributionTypes: [],
			incomingDocumentStatuss: [],
			processId: "",
			isUploadSuccess: false,
		}
	},
	methods : {
		resetModal() {
			this.datas = Array();
			this.$emit('resetMethod');
		},
		uploadButtonAction(modalEvent) {
			modalEvent.preventDefault();
			this.isBusy = true;
			if (this.isUploadSuccess) {
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'incomingdocument/confirmUpload',
					[],
					).then(async function(response){
						if (response != null) {
						if (response.data != null) {
							_this.resetModal();
							_this.datas = Array();
							_this.fileupload = null;
							_this.isUploadSuccess = false;
						}
						}
						_this.isBusy = false;
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
			} else {
				if (this.fileupload != null) {
				let data = new FormData();
				data.append('file', this.fileupload);
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'incomingdocument/upload',
					data,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
					).then(async function(response){
						_this.isBusy = false;
						_this.isUploadSuccess = true;
						_this.getIncomingDocuments();
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
				}
			}
		},
		getIncomingDocuments : async function() {
			let filter = {};
			Object.assign(filter, this.filterIncomingDocument);
			filter.draftFromUpload = "1";
			filter.draftMode = "1";
			this.sortingByIncomingDocument = Array();
			if (this.sortByIncomingDocument != null) {
				this.sortingByIncomingDocument[this.sortByIncomingDocument] = this.sortDescIncomingDocument;
			}
			let currentPage = (this.currentIncomingDocumentPage - 1);
			IncomingDocument.getList(this, filter, this.sortingByIncomingDocument, currentPage , this.incomingDocumentPageSize).then(result => {
				this.incomingDocuments = result.data;
				this.totalIncomingDocumentRow = result.dataCount;
			}).catch(error => {});
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentLocation.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getSenderStatuss : async function(input) {
			let filter = new SenderStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			SenderStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.senderStatuss = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getSifatSurats : async function(input) {
			let filter = new SifatSuratFilter();
			if (input != undefined) {
				filter.name = input;
			}
			SifatSurat.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.sifatSurats = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentTypes : async function(input) {
			let filter = new DocumentTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentTypes = result.data
				}
			})
			.catch(error => {});
		},
		getDistributionTypes : async function(input) {
			let filter = new DistributionTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DistributionType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.distributionTypes = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocumentStatuss : async function(input) {
			let filter = new IncomingDocumentStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			IncomingDocumentStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocumentStatuss = result.data
				}
			})
			.catch(error => {});
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		downloadLog() {
			let _this = this;
			this.$http.post(process.env.VUE_APP_API_URL + 'incomingdocument/downloadlog', [],
			{ headers: { 'Content-Type': 'application/json' }}
			).then(async function(response){
				await saveAs(process.env.VUE_APP_API_URL + 'incomingdocument/downloadlog', "IncomingDocument.xslx");
				_this.isBusy = false;
			})
			.catch(error => {
				_this.$store.dispatch('addErrorMessageGlobal', error);
				_this.isBusy = false;
			});
		}
	},
	beforeMount() {
	},
	mounted(){
	},
	watch: {
		sortByIncomingDocument : {
			handler: function() {
				this.getIncomingDocuments();
			}
		},
		sortDescIncomingDocument: {
			handler: function() {
				this.getIncomingDocuments();
			}
		},
		currentIncomingDocumentPage: {
			handler: function() {
				this.getIncomingDocuments();
			}
		},
		incomingDocumentPageSize : {
			handler: function() {
				this.getIncomingDocuments();
			}
		},
		filterIncomingDocument: {
			handler: function() {
				this.getIncomingDocuments();
			},
			deep: true,
		},
		fileupload(newValue, oldValue) {
			this.datas = Array();
			this.isUploadSuccess = false;
		}
	},
	computed: {
		okTitle: {
			get: function() {
				if (this.isUploadSuccess) {
					return "Proses";
				}
				return "Simpan";
				}
		},
		isShowPopup : {
			get: function () {
				if (this.openPopup) {
					this.filterIncomingDocument = new IncomingDocumentFilter();
					return true;
				}
				else return false;
			},
			set: function (newValue) {}
		},
	}
}
</script>
