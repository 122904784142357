<template>
	<div>
		<b-button class="filter" variant="light" block href="#" v-b-toggle.accordion-filter>
			<span><b-icon-funnel-fill></b-icon-funnel-fill> Filter</span>
			<span class="menu-arrow">
				<b-icon-chevron-right class="float-right chevron-right"></b-icon-chevron-right><b-icon-chevron-down class="float-right chevron-down"></b-icon-chevron-down>
			</span>
		</b-button>
		<b-collapse id="accordion-filter" accordion="accordion-filter" role="tabpanel" class="menu-accordion">
			<b-card>
				<b-row>
					<b-col cols="12" lg="6">
					<app-input-combobox-autocomplete :options="documentLocations" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" id="filter-" size="sm" :model.sync="filter.documentLocationId" @input="getDocumentLocations" @focus="getDocumentLocations" />
					<app-input-combobox :options="documentCategory" optionsLabel="text" optionsKey="value"  label-cols-sm="4" label-cols-lg="3" label="Kategori Surat" id="filter-" size="sm" :model.sync="filter.documentCategory"  :isSelectMethodExists="true" @onSelectMethod="changeDocumentCategory" />
					<b-button variant="danger" size="sm" class="float-right" @click="resetFilter"><span style="font-size: 0.75rem" class="mr-1"><b-icon-x></b-icon-x></span> Clear Filter</b-button>
					<b-button variant="primary" size="sm" class="float-right mr-1" @click="getContractDocuments"><span style="font-size: 0.75rem" class="mr-1"><b-icon-search></b-icon-search></span> Cari</b-button>
					</b-col>
				</b-row>
			</b-card>
		</b-collapse>
		<b-row class="mt-3">
			<b-col>
				<b-button variant="warning" v-if="(userRole != null ? userRole.allowUpload : false)" class="float-right ml-2" @click="showPopupUploadData" size="sm"><b-icon-upload class="mr-1"></b-icon-upload> Upload</b-button>
				<b-overlay
					v-if="(userRole != null ? userRole.allowDownload : false)"
					:show="isDownloadDataBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="float-right "
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowDownload : false)" class="float-right ml-2" @click="downloadData" size="sm"><b-icon-download class="mr-1"></b-icon-download> Download</b-button>
				</b-overlay>
				<b-button variant="primary" v-if="(userRole != null ? userRole.allowDownload : false)" class="float-right" href="/files/ContractDocument/ContractDocument.xlsx" size="sm"><b-icon-wallet-fill class="mr-1"></b-icon-wallet-fill> Download Template</b-button>
			</b-col>
		 </b-row>
		<b-card class="mt-3">
		<b-row class="mb-3">
			<b-col>
				<b-overlay
					:show="isMultiplePrintBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="primary" class="ml-2" @click="showPopupPrintData" size="sm"><b-icon-printer-fill class="mr-1"></b-icon-printer-fill> Cetak Daftar Kontrak</b-button>
				</b-overlay>
				<b-overlay
					v-if="false"
					:show="isSendDataBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline ml-2 "
					>
					<b-button variant="success" @click="sendData" size="sm"><b-icon-play class="mr-1"></b-icon-play> Kirim Notifikasi</b-button>
				</b-overlay>
				<b-overlay
					v-if="false"
					:show="isMultiplePrintBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="secondary" class="ml-2" @click="archivedData" size="sm"><b-icon-folder-fill class="mr-1"></b-icon-folder-fill> Arsipkan Surat</b-button>
				</b-overlay>
			</b-col>
			<b-col>
				<span class="float-right">
					<b-form-select v-model="pageSizeOptions" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
				<b-overlay
							:show="isExportDataBusy"
							rounded
							opacity="0.6"
							spinner-small
							spinner-variant="primary"
							class="float-right mr-2"
							>
					<b-button variant="warning" @click="exportData" size="sm"><b-icon icon="file-spreadsheet" class="mr-1"></b-icon> Export Data</b-button>
				</b-overlay>
			</b-col>
		</b-row>
		<div class="table-corner">
			<b-table id="my-table" stacked="md" head-variant="light"  :no-local-sorting="true" :items="contractDocuments" :fields="fields" :busy="isDataLoading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small responsive :tbody-tr-class="rowClass">
			<template v-slot:head(actions)>
				<b-button-group class="float-right"  size="sm">
					<b-button @click="isShowFilter = !isShowFilter" size="sm"><b-icon-funnel-fill /></b-button>
					<b-button><b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" id="input-select-all" class="float-right" v-model="selectAll"></b-form-checkbox></b-button>
				</b-button-group>
			</template>
			<template #top-row v-if="isShowFilter">
				<b-th></b-th>
				<b-th stacked-heading="Kode Risalah">
					<b-form-input id="input-document-code-table" @input="getContractDocumentsWithoutLoading" v-model="filter.documentCode" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Pemohon/Penerima Surat">
					<b-form-input id="input-pemohon-surat-table" @input="getContractDocumentsWithoutLoading" v-model="filter.pemohonSurat" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Nama Mitra Kerjasama">
					<b-form-input id="input-pemohon-surat-table" @input="getContractDocumentsWithoutLoading" v-model="filter.senderName" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Perihal Surat/Kontrak">
					<b-form-input id="input-document-code-table" @input="getContractDocumentsWithoutLoading" v-model="filter.description" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Nomor Surat">
					<b-form-input id="input-document-code-table" @input="getContractDocumentsWithoutLoading" v-model="filter.documentNo" size="sm"></b-form-input>
				</b-th>
				<b-th></b-th>
				<b-th stacked-heading="Masa Berlaku Kontrak">
					<b-form-datepicker boundary="viewport" placeholder="" id="input-contract-date-from-table" reset-button @input="getContractDocumentsWithoutLoading" v-model="filter.contractDateFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
					<b-form-datepicker boundary="viewport" placeholder="" id="input-contract-date-to-table" reset-button @input="getContractDocumentsWithoutLoading" v-model="filter.contractDateTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
				</b-th>
				<b-th></b-th>
				<b-th stacked-heading="Status">
					<v-select append-to-body label="name" :options="contractStatuss" :value="filter.contractStatusId" :reduce="item => item.id" v-model="filter.contractStatusId" :filterable="true" @input="getContractDocumentsWithoutLoading" @search="getContractStatuss"></v-select>
				</b-th>
				<b-th stacked-heading="Select All">
					<b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" id="input-select-all" class="float-right d-block d-md-none" v-model="selectAll"></b-form-checkbox>
				</b-th>
			</template>
			<template v-slot:cell(no)="row">
				{{ ((row.index + 1) + ( pageSizeOptions * (currentPage - 1))) }}
			</template>
			<template v-slot:cell(incomingDocument)="row">
				{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentCode : "" }}
			</template>
			<template v-slot:cell(outgoingDocument)="row">
				{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentCode : "" }}
			</template>
			<template v-slot:cell(pemohonSurat)="row">
				<span v-if="row.item.incomingDocumentDestinationUser != null">
					<b-badge variant="secondary" v-for="item in row.item.incomingDocumentDestinationUser.slice(0,3)" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
						{{ item.destinationUser ? item.destinationUser.fullName : "-" }}
					</b-badge>
					<span v-if="row.item.incomingDocumentDestinationUser.length > 3">
						<br /><b-button size="sm" variant="secondary" class="btn-xs" @click="showPopupDestinationUser(row.item)">Show All</b-button>
					</span>
				</span>
				<span v-else>
					{{ row.item != null ? row.item.createdByUserProfile !=null ? row.item.createdByUserProfile.fullName : "" : "" }}
				</span>
			</template>
			<template v-slot:cell(contractStatus)="row">
				{{ row.item.contractStatus != null ? row.item.contractStatus.name : "" }}
			</template>
			<template v-slot:cell(documentCode)="row">
				<span v-if="row.item.incomingDocument != null">
					{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentCode : "" }}
				</span>
				<span v-else-if="row.item.outgoingDocument != null">
					{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentCode : "" }}
				</span>
			</template>
			<template v-slot:cell(senderName)="row">
				<span v-if="row.item.incomingDocument != null">
					{{ row.item.incomingDocument != null ? row.item.incomingDocument.senderName : "" }}
				</span>
				<span v-else-if="row.item.outgoingDocument != null">
					<b-badge variant="secondary" v-for="item in row.item.outgoingDocumentDestinationUser.slice(0,3)" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
						{{ item.destinationUser ? item.destinationUser.fullName : "-" }}
					</b-badge>
					<span v-if="row.item.outgoingDocumentDestinationUser.length > 3">
						<br /><b-button size="sm" variant="secondary" class="btn-xs" @click="showPopupDestinationUser(row.item)">Show All</b-button>
					</span>
				</span>
			</template>
			<template v-slot:cell(contactPerson)="row">
				<span v-if="row.item.incomingDocument != null">
					{{ row.item.incomingDocument != null ? row.item.incomingDocument.contactPerson : "" }}
				</span>
				<span v-else-if="row.item.outgoingDocument != null">
					{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.contactPerson : "" }}
				</span>
			</template>
			<template v-slot:cell(documentDate)="row">
				<span v-if="row.item.incomingDocument != null">
					{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentDate : "" | moment("DD-MMM-YYYY")}}
				</span>
				<span v-else-if="row.item.outgoingDocument != null">
					{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentDate : "" | moment("DD-MMM-YYYY")}}
				</span>
			</template>
			<template v-slot:cell(documentType)="row">
				<span v-if="row.item.incomingDocument != null">
					{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentType != null ? row.item.incomingDocument.documentType.name : "" : "" }}
				</span>
				<span v-else-if="row.item.outgoingDocument != null">
					{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentType != null ? row.item.outgoingDocument.documentType.name : "" : "" }}
				</span>
			</template>
			<template v-slot:cell(documentNo)="row">
				<span v-if="row.item.incomingDocument != null">
					{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentNo : "" }}
				</span>
				<span v-else-if="row.item.outgoingDocument != null">
					{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentNo : "" }}
				</span>
			</template>
			<template v-slot:cell(description)="row">
				<span v-if="row.item.incomingDocument != null">
					{{ row.item.incomingDocument != null ? row.item.incomingDocument.description : "" }}
				</span>
				<span v-else-if="row.item.outgoingDocument != null">
					{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.description : "" }}
				</span>
			</template>
			<template v-slot:cell(documentAttachment)="row">
				<b-btn-group @click="showPopupDocument((row.item.incomingDocument != null ? row.item.incomingDocument : (row.item.outgoingDocument != null ? row.item.outgoingDocument : null)), (row.item.incomingDocument != null ? 'INCOMING' : (row.item.outgoingDocument != null ? 'OUTGOING' : '')))">
						<b-button size="sm" variant="secondary" class="btn-xs">
							{{ row.item.documentAttachmentCount }} Attachment(s)
						</b-button>
						<b-button size="sm" variant="secondary" class="btn-xs">
							<b-icon-paperclip></b-icon-paperclip>
						</b-button>
					</b-btn-group>
			</template>
			<template v-slot:cell(contractDate)="row">
				{{ row.item.contractDateFrom | moment("DD-MMM-YYYY") }}
				{{ ((row.item.contractDateFrom != null && row.item.contractDateFrom != "") && (row.item.contractDateTo != null && row.item.contractDateTo != "") ? " s/d " : "") }}
				{{ (row.item != null ? row.item.contractDateTo : "") | moment("DD-MMM-YYYY") }}
			</template>
			<template v-slot:cell(remainingContractDay)="row">
				{{ row.item.remainingContractDay }} Hari
			</template>
			<template v-slot:cell(actionsCheck)="row" >
				<span>
					<b-form-checkbox @change="toggleSendData(Number(row.item.id))" :checked="checkAllData.includes(Number(row.item.id))" ></b-form-checkbox>
				</span>
			</template>
			<template v-slot:cell(actions)="row" >
				<div class="div-actions float-right">
				<b-button size="sm" v-if="false" :to="{path: '/contractdocument/edit/' + row.item.id }" variant ="success"  class="mr-2 btn-xs">
					<b-icon-pencil></b-icon-pencil>
				</b-button>
				<b-overlay
					v-if="false"
					:show="busy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<click-confirm>
					<b-button size="sm" variant="danger" @click="deleteData(row.item.id)" class="mr-2 btn-xs">
						<b-icon-trash></b-icon-trash>
					</b-button>
					</click-confirm>
				</b-overlay>
				<span v-if="row.item.incomingDocument != null">
					<b-button size="sm" v-if="(userRole != null ? userRole.allowRead : false)" variant="primary" class="mr-2 mt-md-2 mt-lg-0 btn-xs" :to="{path: '/incomingdocument/detail/' + row.item.incomingDocument.id }" >
						<b-icon-eye-fill></b-icon-eye-fill>
					</b-button>
				</span>
				<span v-else-if="row.item.outgoingDocument != null">
					<span v-if="row.item.outgoingDocument.isInternal">
						<b-button size="sm" v-if="(userRole != null ? userRole.allowRead : false)" variant="primary" class="mr-2 mt-md-2 mt-lg-0 btn-xs" :to="{path: '/outgoingdocument/detail/' + row.item.outgoingDocument.id }" >
							<b-icon-eye-fill></b-icon-eye-fill>
						</b-button>
					</span>
					<span v-else>
						<b-button size="sm" v-if="(userRole != null ? userRole.allowRead : false)" variant="primary" class="mr-2 mt-md-2 mt-lg-0 btn-xs" :to="{path: '/outgoingdocumentexternal/detail/' + row.item.outgoingDocument.id }" >
							<b-icon-eye-fill></b-icon-eye-fill>
						</b-button>
					</span>
				</span>
				
				<b-button size="sm" v-if="false" variant="light"  @click="printSingleData(row.item.id)" class="mr-2 mt-md-2 mt-lg-0 btn-xs">
					<b-icon-printer-fill></b-icon-printer-fill>
				</b-button>
				<b-form-checkbox v-if="false" class="d-inline" :id="'input-is-print-' + (row.index + 1)" @change="toggleIsPrint(Number(row.item.id))" :checked="checkAllData.includes(Number(row.item.id))" ></b-form-checkbox>
				</div>
			</template>
			</b-table>
		</div>
		<b-row>
			<b-col cols="12" md="6" lg="6">
				<b-button variant="success" v-if="false" to="/contractdocument/create" size="sm"><b-icon-pencil class="mr-1"></b-icon-pencil> Create</b-button>
				<b-overlay
					v-if="(userRole != null ? userRole.allowPrint : false)"
					:show="isMultiplePrintBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowPrint : false)" class="ml-2" @click="printData" size="sm"><b-icon-printer-fill class="mr-1"></b-icon-printer-fill> Multiple Print</b-button>
				</b-overlay>
			</b-col>
			<b-col cols="12" md="6" lg="6">
			<b-pagination
				class="float-md-right btn-paging"
				v-model="currentPage"
				:total-rows="(contractDocumentsPaging != null ? contractDocumentsPaging.total : 0)"
				:per-page="(contractDocumentsPaging != null ? contractDocumentsPaging.perPage : 0)"
				aria-controls="my-table"
			></b-pagination>
			</b-col>
		</b-row>
		</b-card>
		<PopupPrintContract :openPopup="isShowPopupPrintData" @resetMethod="resetModal"  />
		<PopupDownloadIncomingDocument :openPopup="isShowPopupIncomingDocument" :allowUpdate="false" :parent="parent" @resetMethod="resetModalUpload" />
		<PopupDownloadOutgoingDocument :openPopup="isShowPopupOutgoingDocument" :allowUpdate="false" :parent="parent" @resetMethod="resetModalUpload" />
	</div>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
</style>
<script>
import ContractDocument from '@/models/ContractDocument/ContractDocument';
import ContractDocumentList from '@/models/ContractDocument/ContractDocumentList';
import ContractDocumentFilter from '@/filters/ContractDocument/ContractDocumentFilter';
import RoleDetail from '@/models/Core/RoleDetail';

import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import AppInputCombobox from '@/components/AppInputCombobox';
import AppInputTextarea from '@/components/AppInputTextarea';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import ContractStatusFilter from '@/filters/ContractStatus/ContractStatusFilter';
import ContractStatus from '@/models/ContractStatus/ContractStatus';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import Branch from '@/models/Branch/Branch';

import PopupDownload from '@/components/PopupDownload';
import PopupPDF from '@/components/PopupPDF';
import PopupDataEditNotification from '@/components/PopupDataEditNotification';
import Upload from './Upload';
import PopupPrintContract from './PopupPrintContract';
import PopupDownloadIncomingDocument from '@/views/IncomingDocument/PopupDownloadDocument';
import PopupDownloadOutgoingDocument from '@/views/OutgoingDocument/PopupDownloadDocument';

export default {
	components : {PopupDownloadIncomingDocument,PopupDownloadOutgoingDocument,AppInputCombobox,PopupPrintContract, ContractDocument, ContractDocumentList, ContractDocumentFilter, AppInputTextarea,AppInputComboboxAutocomplete,AppInputDatepicker,AppInputDatepickerRange,PopupDownload,PopupPDF,Upload,PopupDataEditNotification,},
	data() {
		return {
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fields: [
				//{"actionsCheck": { "class" : "th-actions-check", "label" : ""}},
				{"no" : { "class" : "th-number"}}, 
				{"documentCode": {}, "class" : "th-incoming-document", "label" : "Kode Risalah", key: 'documentCode', sortable: true}, 
				{"pemohonSurat": {}, "class" : "th-incoming-document", "label" : "Pemohon/Penerima Surat", key: 'pemohonSurat', sortable: true}, 
				{"senderName": {}, "class" : "th-incoming-document", "label" : "Nama Mitra Kerjasama", key: 'senderName', sortable: true}, 
				{"description": {}, "class" : "th-description", "label" : "Perihal Surat/Kontrak", key: 'description', sortable: true}, 
				{"documentNo": {}, "class" : "th-document-no", "label" : "Nomor Surat", key: 'documentNo', sortable: true},
				//{"documentDate": {}, "class" : "th-document-date", "label" : "Tanggal Surat", key: 'documentDate', sortable: false}, 
				{"documentAttachment": {}, "class" : "th-document-attachment", "label" : "File Dokumen", key: 'documentAttachment', sortable: false}, 
				{"contractDate": {}, "class" : "th-remaining-day", "label" : "Masa Berlaku Kontrak", key: 'contractDate', sortable: true}, 
				{"remainingContractDay": {}, "class" : "th-remaining-day", "label" : "Sisa Hari Kontrak", key: 'remainingContractDay', sortable: true}, 
				{"contractStatus": {}, "class" : "th-contract-status", "label" : "Status Kontrak", key: 'contractStatus', sortable: true}, 
				{"actions": { "class" : "th-actions", "label" : ""}}
			],
			busy:false,
			isDataLoading: false,
			isDownloadDataBusy: false,
			isMultiplePrintBusy: false,
			showPopupUpload: false,
			showPopupDownload: false,
			isShowPopupPDF: false,
			pdfUrl: "",
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			filter: null,
			processId: "",
			popupDownloadLabel: "",
			hasLoadedData: false,
			showFilter: false,
			isShowFilter: false,
			isCreateBusy: false,
			btnEditData: [],
			currentDataId: null,
			contractDocuments: [],
			contractDocumentsPaging: {},
			incomingDocuments : [],
			outgoingDocuments : [],
			contractStatuss : [],
			isShowPopupPrintData: false,
			isSendDataBusy: false,
			documentCategory:[
				{ value : null, text : 'All' },
				{ value : "SMA", text : 'Surat Masuk' },
				{ value : "SKI", text : 'Surat Keluar Internal' },
				{ value : "SKE", text : 'Surat Keluar External' },
			],
			documentLocations: [],
			parent: {},
			isShowPopupIncomingDocument: false,
			isShowPopupOutgoingDocument: false,
			isExportDataBusy: false,
		}
	},
	methods : {
		showPopupDocument(parent, documentType) {
			this.parent = parent;
			if (documentType == 'INCOMING')
				this.isShowPopupIncomingDocument = true;
			if (documentType == 'OUTGOING')
				this.isShowPopupOutgoingDocument = true;
		},
		getContractDocuments : async function() {
			this.isDataLoading = true;
			this.sortingBy = Array();
			if (this.sortBy != null && this.sortBy !=  "") {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["contractDate"] = true;
			}
			console.log(this.sortingBy);
			console.log(this.sortBy);
			ContractDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'incomingDocument,outgoingDocument,contractStatus,outgoingDocumentDestinationUser,outgoingDocumentAttachmentDetail,incomingDocumentDestinationUser,createdByUserProfile')
			.then(result => {
				if (result != null) {
					this.contractDocuments = result.data
					this.contractDocumentsPaging = result.pagination
				}
				this.isDataLoading = false;
			})
			.catch(error => {
				this.isDataLoading = false;
			});
		},
		getContractDocumentsWithoutLoading : async function() {
			this.sortingBy = Array();
			if (this.sortBy != null && this.sortBy !=  "") {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["contractDate"] = true;
			}
			ContractDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'incomingDocument,outgoingDocument,contractStatus,outgoingDocumentDestinationUser,outgoingDocumentAttachmentDetail,incomingDocumentDestinationUser,createdByUserProfile')
			.then(result => {
				if (result != null) {
					this.contractDocuments = result.data
					this.contractDocumentsPaging = result.pagination
				}
			})
			.catch(error => {});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getOutgoingDocuments : async function(input) {
			let filter = new OutgoingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			OutgoingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.outgoingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getContractStatuss : async function(input) {
			let filter = new ContractStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			ContractStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.contractStatuss = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Branch.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		resetFilter() {
			this.filter = new ContractDocumentFilter();
		},
		showPopupUploadData() {
			this.showPopupUpload = true;
		},
		showPopupPrintData() {
			this.isShowPopupPrintData = true;
		},
		uploadData(modalEvent) {
			this.showPopupUpload = false;
		},
		resetModal() {
			this.showPopupUpload = false;
			this.showPopupDownload = false;
			this.isShowPopupPDF = false;
			this.isShowPopupPrintData = false;
		},
		resetModalUpload() {
			this.isShowPopupIncomingDocument = false,
			this.isShowPopupOutgoingDocument = false,
			this.showPopupUpload = false;
			this.getContractDocuments();
		},
		toggleIsPrint(id) {
			if (this.checkAllData.includes(id)) {
				this.$store.dispatch("removeCheckData", id);
			} else {
				this.$store.dispatch("addCheckData", id);
			}
		},
		showPopupPrint(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di cetak";
			this.processId = id;
		},
		showPopupDownloadData(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di unduh";
			this.processId = id;
		},
		showPopupPDF(url) {
			this.isShowPopupPDF = true;
			this.pdfUrl = url;
		},
		archivedData() {
			let data = this.$store.state.dataIds;
			if (data.length > 0) {
				this.isMultiplePrintBusy = true;
				ContractDocument.archivedData(this).then(result => {
					/*if (result) {
						this.showPopupPrint(result.id);
					}*/
					this.getContractDocuments();
					this.isMultiplePrintBusy = false;
				}).catch(error => { this.isMultiplePrintBusy = false; });
			} else {
				alert("Please Choose Document");
			}
		},
		sendData() {
			let data = this.$store.state.dataIds;
			if (data.length > 0) {
				this.isMultiplePrintBusy = true;
				ContractDocument.multiPagePrint(this).then(result => {
					if (result) {
						this.showPopupPrint(result.id);
					}
					this.isMultiplePrintBusy = false;
				}).catch(error => { this.isMultiplePrintBusy = false; });
			}
		},
		printSingleData(id) {
			ContractDocument.singleDataPagePrint(this, id).then(result => {
				if (result) {
					this.showPopupPDF(result.download);
				}
			});
		},
		downloadData() {
			if (this.sortBy != null) {
					this.sortingBy[this.sortBy] = this.sortDesc;
			}
			this.isDownloadDataBusy = true;
			ContractDocument.downloadData(this, this.filter, this.sortingBy).then(result => {
				if (result) {
					this.showPopupDownloadData(result.id);
				}
				this.isDownloadDataBusy = false;
			}).catch(error => { this.isDownloadDataBusy = false; })
		},
		deleteData(id) {
			ContractDocument.deleteData(this, id).then(result => {
				if (result)
				{
					this.getContractDocuments();
				}
			})
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768) {
				this.isShowFilter = true;
			} else {
				this.isShowFilter = this.showFilter;
			}
		},
		rowClass(item, type) {
			if (!item || type !== 'row') return;

			if (item.remainingContractDay <= 15 && item.remainingContractDay > 10) return "table-success";
			if (item.remainingContractDay <= 10 && item.remainingContractDay > 5) return "table-warning";
			if (item.remainingContractDay <= 5) return "table-danger";
		},
		toggleSendData(id) {
			if (this.checkAllData.includes(id)) {
				this.$store.dispatch("removeCheckData", id);
			} else {
				this.$store.dispatch("addCheckData", id);
			}
		},
		changeDocumentCategory(selectedValue, data){
			if (selectedValue) {
				this.filter.documentCategory = selectedValue.value;
			} else {
				this.filter.documentCategory = null;
			}
		},
		exportData() {
			this.sortingBy = Array();
			if (this.sortBy != null && this.sortBy !=  "") {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["contractDate"] = true;
			}
			this.isExportDataBusy = true;
			ContractDocument.exportData(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'incomingDocument,userReceiver,incomingDocumentAttachmentDetail')
			.then(result => {
				if (result != null) {
					saveAs(result.data, "monitoring_kontrak.xlsx");
				}
				this.isExportDataBusy = false;
			})
			.catch(error => {
				this.isExportDataBusy = false;
			});
		}
	},
	beforeMount() {
		this.$store.dispatch("removeCheckAllData");
		this.filter = new ContractDocumentFilter();
		//this.getIncomingDocuments()
		//this.getOutgoingDocuments()
		this.getContractStatuss();
		this.getDocumentLocations();
	},
	mounted(){
		let breadcrumb =[
			"Monitoring Kontrak",
		];
		this.getContractDocuments();
		this.$store.dispatch('setBreadCrumb', breadcrumb);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
		});
	},
	watch: {
		sortBy: {
			handler: function() {
				this.getContractDocuments();
			}
		},
		sortDesc: {
			handler: function() {
				this.getContractDocuments();
			}
		},
		currentPage: {
			handler: function() {
				this.getContractDocuments();
			}
		},
		pageSizeOptions: {
			handler: function() {
				this.getContractDocuments();
			}
		},
		selectAll(newValue) {
			if (newValue) {
				ContractDocument.checkAllData(this, this.filter, this.sortingBy);
			} else {
				this.$store.dispatch("removeCheckAllData");
			}
		},
		'$route'(to, from) {
			if (to != null) {
				if (to.name == "contractdocumentindex") {
					this.getContractDocumentsWithoutLoading();
				}
			}
		}
	},
	computed: {
		checkAllData() {
			return this.$store.state.dataIds;
		},
		userRole() {
			return RoleDetail.query().where("functionInfoId", "contract_document").first();
		}
	},
	beforeDestroy() {
		this.$store.dispatch("removeCheckAllData");
	},
}
</script>
