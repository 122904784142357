<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="addButtonAction" title="Add Data Document Attachment Detail">
		<b-card>
			<b-col cols="12" lg="6">
				<app-input-textbox id="fieldset-file-name" label-cols-sm="4" label-cols-lg="3" label="File Name" label-for="input-file-name" size="sm" :model.sync="model.fileName"  />
				<app-input-fileupload label-cols-sm="4" label-cols-lg="3" label="Attachment" size="sm" :model.sync="attachment" />
			</b-col>
		</b-card>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<style lang="scss" scoped>
	:deep  .modal-dialog {
		width: 70% !important; 
	}
</style> 
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import DocumentAttachmentDetail from '@/models/DocumentAttachment/DocumentAttachmentDetail';
import DocumentAttachmentDetailFilter from '@/filters/DocumentAttachment/DocumentAttachmentDetailFilter';

import DocumentAttachment from '@/models/DocumentAttachment/DocumentAttachment';
import DocumentAttachmentFilter from '@/filters/DocumentAttachment/DocumentAttachmentFilter';
import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputFileupload from '@/components/AppInputFileupload';
import Attachment from '@/models/Core/Attachment';
import AttachmentFilter from '@/filters/Core/AttachmentFilter';
import PopupRouterView from '@/components/PopupRouterView';
export default {
	props: ['modelId', 'openPopup','addButtonMethod', 'resetMethod'],
	components : {AppInputTextbox,AppInputFileupload,PopupRouterView,},
	mixins : [validationMixin],
	validations: {
		model: {
		}
	},
	data() {
		return {
			model : {},
			attachment: null,
			errorMessage : {
			},
			isBusy: false,
		}
	},
	methods : {
		getDocumentAttachmentDetail : async function(id) {
			this.isBusy = true;
			DocumentAttachmentDetail.getData(this, id).then(result => { this.model = result; this.isBusy = false }).catch(error => { this.isBusy = false; });
		},
		validateState(name) {
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		addButtonAction(modalEvent) {
			modalEvent.preventDefault();

			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.$emit("addButtonMethod", modalEvent, this.model); 
			}
		},
		resetModal() {
			this.$emit('resetMethod');
		},
	},
	beforeMount() {
		this.getAttachments();
	},
	async mounted(){
	},
	watch : {
		modelId(newValue) {
			if (newValue != null && newValue != undefined && newValue != '') {
				this.getDocumentAttachmentDetail(newValue);
			} else {
				this.model = {};
			}
		},
		openPopup(newValue) {
			if (newValue) {
			} else {
				this.model = {};
			}
		}
	},
	computed: {
		isShowPopup : {
			get: function () {
				if (this.openPopup) return true;
				else return false;
			},
			set: function (newValue) {}
		},
	}
}
</script>
