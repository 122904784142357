<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true id="popup-upload" @ok="uploadButtonAction" :okTitle="okTitle" title="Upload Data">
		<b-card>
			<b-row>
				<b-col cols="12">
					<app-input-fileupload label="File" size="sm" :model.sync="fileupload" />
				</b-col>
			</b-row>
			<b-row v-if="isUploadSuccess">
				<b-col cols="12">
					<b-card no-body>
						<b-tabs card>
							<b-tab title="Disposition" active>
								<b-row class="mb-2">
									<b-col>
										<span class="float-right">
											<b-form-select v-model="dispositionPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
										</span>
									</b-col>
								</b-row>
								<b-row>
								<b-col>
								<div class="table-corner">
									<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="dispositions" :fields="fieldsDisposition" :busy="isDataLoading" :sort-by.sync="sortByDisposition" :sort-desc.sync="sortDescDisposition" small responsive>
									<template v-slot:head(actions)>
										<b-button @click="isShowDispositionFilter = !isShowDispositionFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
									</template>
									<template #top-row v-if="isShowDispositionFilter">
										<b-th></b-th>
										<b-th stacked-heading="Kode Risalah">
											<v-select append-to-body label="documentCode" :options="incomingDocuments" :value="filterDisposition.incomingDocumentId"  :reduce="item => item.id" v-model="filterDisposition.incomingDocumentId" @search="getIncomingDocuments" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Penerima Disposisi">
											<v-select append-to-body label="fullName" :options="userProfiles" :value="filterDisposition.userReceiverId"  :reduce="item => item.id" v-model="filterDisposition.userReceiverId" @search="getUserProfiles" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Pesan Disposisi">
											<b-form-input id="input-remarks-table" v-model="filterDisposition.remarks" size="sm"></b-form-input>
										</b-th>
										<b-th>
										</b-th>
									</template>
									<template v-slot:table-busys>
										<div class="text-center my-2">
									<b-spinner class="align-middle text-primary"></b-spinner>
										<strong class="ml-2">Loading..</strong>
										</div>
									</template>
									<template v-slot:cell(no)="row">
										{{ ((row.index + 1) + ( dispositionPageSize * (currentDispositionPage - 1))) }}
									</template>
									<template v-slot:cell(incomingDocument)="row">
										{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentCode : "" }}
									</template>
									<template v-slot:cell(userReceiver)="row">
										{{ row.item.userReceiver != null ? row.item.userReceiver.fullName : "" }}
									</template>
									</b-table>
									</div>
								</b-col>
								<b-col cols="12">
									<b-pagination
									class="float-right btn-paging"
									v-model="currentDispositionPage"
									:total-rows="totalDispositionRow"
									:per-page="dispositionPageSize"
									aria-controls="my-table"
									></b-pagination>
								</b-col>
								</b-row>
							</b-tab>
						</b-tabs>
					</b-card>
				</b-col>
			</b-row>
		</b-card>
		<template #modal-footer="{ ok, cancel }">
			<b-button v-if="isUploadSuccess" size="sm" variant="primary" @click="downloadLog()">
				Download Log
			</b-button>
			<b-button size="sm" variant="success" class="float-right" @click="ok()">
				{{ okTitle }}
			</b-button>
			<b-button size="sm" variant="secondary" class="float-right" @click="cancel()">
				Cancel
			</b-button>
		</template>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<style lang="scss" scoped>
.card {
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
:deep .th-message {
	color: red;
}
:deep .modal-footer {
	display: block;
}
</style>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate"; 
import { required, minLength } from "vuelidate/lib/validators"; 
import { saveAs } from 'file-saver';

import Disposition from '@/models/Disposition/Disposition';
import DispositionList from '@/models/Disposition/DispositionList';
import DispositionFilter from '@/filters/Disposition/DispositionFilter';

import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import AppInputFileupload from '@/components/AppInputFileupload';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
export default {
	props: ['model', 'openPopup','uploadButtonMethod', 'parentId'],
	components : {AppInputFileupload,AppInputDatetimepicker,AppInputDatetimepickerRange,},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fieldsDisposition: [
				{"no" : { "class" : "th-number"}}, 
				{"incomingDocument": { "class" : "th-incoming-document", "label" : "Kode Risalah"}, key: 'incomingDocument', sortable: true}, 
				{"userReceiver": { "class" : "th-user-receiver", "label" : "Penerima Disposisi"}, key: 'userReceiver', sortable: true}, 
				{"remarks": { "class" : "th-remarks", "label" : "Pesan Disposisi"}, key: 'remarks', sortable: true}, 
				{"class" : "th-status", "label" : "Status", key: 'uploadValidationStatus', sortable: true},
				{"class" : "th-message", "label" : "Message", key: 'uploadValidationMessage', sortable: true},
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isDataLoading: false,
			isBusy : false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			dispositions : [],
			dispositionId : '',
			totalDispositionRow : 0,
			isShowDispositionFilter: false,
			filterDisposition : null,
			currentDispositionPage : 1,
			dispositionPageSize: 10,
			sortByDisposition: '',
			sortDescDisposition: '',
			incomingDocuments: [],
			userProfiles: [],
			processId: "",
			isUploadSuccess: false,
		}
	},
	methods : {
		resetModal() {
			this.datas = Array();
			this.$emit('resetMethod');
		},
		uploadButtonAction(modalEvent) {
			modalEvent.preventDefault();
			this.isBusy = true;
			if (this.isUploadSuccess) {
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'disposition/confirmUpload',
					[],
					).then(async function(response){
						if (response != null) {
						if (response.data != null) {
							_this.resetModal();
							_this.datas = Array();
							_this.fileupload = null;
							_this.isUploadSuccess = false;
						}
						}
						_this.isBusy = false;
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
			} else {
				if (this.fileupload != null) {
				let data = new FormData();
				data.append('file', this.fileupload);
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'disposition/upload',
					data,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
					).then(async function(response){
						_this.isBusy = false;
						_this.isUploadSuccess = true;
						_this.getDispositions();
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
				}
			}
		},
		getDispositions : async function() {
			let filter = {};
			Object.assign(filter, this.filterDisposition);
			filter.draftFromUpload = "1";
			filter.draftMode = "1";
			this.sortingByDisposition = Array();
			if (this.sortByDisposition != null) {
				this.sortingByDisposition[this.sortByDisposition] = this.sortDescDisposition;
			}
			let currentPage = (this.currentDispositionPage - 1);
			Disposition.getList(this, filter, this.sortingByDisposition, currentPage , this.dispositionPageSize).then(result => {
				this.dispositions = result.data;
				this.totalDispositionRow = result.dataCount;
			}).catch(error => {});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		downloadLog() {
			let _this = this;
			this.$http.post(process.env.VUE_APP_API_URL + 'disposition/downloadlog', [],
			{ headers: { 'Content-Type': 'application/json' }}
			).then(async function(response){
				await saveAs(process.env.VUE_APP_API_URL + 'disposition/downloadlog', "Disposition.xslx");
				_this.isBusy = false;
			})
			.catch(error => {
				_this.$store.dispatch('addErrorMessageGlobal', error);
				_this.isBusy = false;
			});
		}
	},
	beforeMount() {
	},
	mounted(){
	},
	watch: {
		sortByDisposition : {
			handler: function() {
				this.getDispositions();
			}
		},
		sortDescDisposition: {
			handler: function() {
				this.getDispositions();
			}
		},
		currentDispositionPage: {
			handler: function() {
				this.getDispositions();
			}
		},
		dispositionPageSize : {
			handler: function() {
				this.getDispositions();
			}
		},
		filterDisposition: {
			handler: function() {
				this.getDispositions();
			},
			deep: true,
		},
		fileupload(newValue, oldValue) {
			this.datas = Array();
			this.isUploadSuccess = false;
		}
	},
	computed: {
		okTitle: {
			get: function() {
				if (this.isUploadSuccess) {
					return "Proses";
				}
				return "Simpan";
				}
		},
		isShowPopup : {
			get: function () {
				if (this.openPopup) {
					this.filterDisposition = new DispositionFilter();
					return true;
				}
				else return false;
			},
			set: function (newValue) {}
		},
	}
}
</script>
