<template>
	<b-card>
		<b-table-simple
			:bordered="true"
			
			:small="true"
			:no-border-collapse="true"
			>
			<b-tbody>
				<b-tr>
					<b-td rowspan="3" style="width:210px">
						<img src="/img/logo.png" style="width: 200px" class="menu-logo" id="menu-logo" alt="menu logo" />
					</b-td>
					<b-td colspan="3" class="text-center"><b>INISIATIF ZAKAT INDONESIA</b></b-td>
				</b-tr>
				<b-tr>
					<b-td colspan="3" class="text-center"><b>BERITA ACARA PENGHAPUSAN ARSIP</b></b-td>
				</b-tr>
				<b-tr>
					<b-td>No. Dokumen :</b-td>
					<b-td>No. Revisi: </b-td>
					<b-td>Tgl. Berlaku: </b-td>
				</b-tr>
				<b-tr>
					<b-td colspan="4">
						<b-row>
							<b-col cols="6">
								<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="No. Berita Acara" label-for="filter-incoming-document">
									{{ model != null ? model.noBeritaAcara : "-" }}
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="Periode Penghapusan Arsip" label-for="filter-incoming-document">
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="Kategori Surat " label-for="filter-incoming-document">
									{{ "-" }}
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<b-form-group id="fieldset-incoming-document" label="" label-for="filter-incoming-document">
									{{ (model != null ? model.archivedDateFrom : "") | moment("DD-MMM-YYYY") }} -  {{ (model != null ? model.archivedDateTo : "") | moment("DD-MMM-YYYY") }}
								</b-form-group>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col cols="12">
								<p>Pada hari ini {{ model.createdAt | moment('dddd') }} tanggal {{ model.createdAt | moment('DD') }} bulan {{ model.createdAt | moment('MMMM') }} tahun {{ model.createdAt | moment('YYYY') }}, waktu {{ model.createdAt | moment('hh:mm') }} , {{ model.documentLocationName }} </p>
								<p>Kami yang bertandatangan di bawah ini telah melakukan penghapusan arsip (fisik) surat sebagai berikut:</p>
								<b-table bordered id="my-table" head-variant="light"  :no-local-sorting="true" :items="archivedDocuments" :fields="fields" :busy="isDataLoading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small responsive>
									<template v-slot:cell(no)="row">
										{{ (row.index + 1) }}
									</template>
									<template v-slot:cell(documentCode)="row">
										{{ row.item.documentCode }}
									</template>
									<template v-slot:cell(createdAt)="row">
										{{ row.item != null ? row.item.createdAt : "" | moment("DD-MMM-YYYY")}}
									</template>
									<template v-slot:cell(documentDate)="row">
										{{ row.item.documentDate | moment("DD-MMM-YYYY")}}
									</template>
									<template v-slot:cell(documentLocation)="row">
										{{ row.item.documentLocationName }}
									</template>
									<template v-slot:cell(documentType)="row">
										{{ row.item.documentTypeName}}
									</template>
									<template v-slot:cell(documentNo)="row">
										{{ row.item.documentNo }}
									</template>
									<template v-slot:cell(sifatSurat)="row">
										{{ row.item.sifatSuratName }}
									</template>
									<template v-slot:cell(archivedStatus)="row">
										{{ row.item.archivedDocumentStatusName }}
									</template>
								</b-table>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col cols="12">
								<p>Arsip (fisik) surat sebagaimana tersebut diatas telah dihapus/dimusnahkan dengan cara: <b>{{ model.removalMethodName}}</b></p>
								<p>Demikian Berita Acara ini dibuat dengan sebenar-benarnya.</p>
							</b-col>
							<b-col cols="12">
								<b-form-group id="fieldset-incoming-document" class="text-italic" label-cols-sm="1" label-cols-lg="1" label="Catatan" label-for="filter-incoming-document">
									{{ model.remarks }}
								</b-form-group>
							</b-col>
						</b-row>
						<b-row class="float-right">
							<b-col cols="12" class="float-right">
								<b-table-simple
									class="float-right"
									borderless
									:small="true"
									:no-border-collapse="true"
									>
									<b-tbody>
										<b-tr>
											<b-td>
												<p>Pelaksana,</p><br /><br />

												<p>{{ model.picFullName }}</p>
												<p>{{ model.pic != null ? model.pic.jobDescription : ''}}</p>
											</b-td>
											<b-td v-for="(item, index) in model.investigatorDetail" :key="item.id">
												<p v-if="index == 0">Saksi-Saksi,</p>
												<p v-else>
													<br />
												</p>
												<br />
												<br />
												<p>{{ item.userProfileFullName }}</p>
												<p>{{ item.userProfileJobDescription }}</p>
											</b-td>
											<b-td>
												<p>Disetujui oleh,</p><br /><br />

												<p>{{ model.approvedByFullName }}</p>
												<p>{{ model.approvedBy != null ? model.approvedBy.jobDescription : ''}}</p>
											</b-td>
										</b-tr>
									</b-tbody>
									</b-table-simple>
							</b-col>
						</b-row>
					</b-td>
				</b-tr>
				</b-tbody>
		</b-table-simple>
		<b-row class="mt-3" id="btn-action">
			<b-button class="ml-2" variant="success" size="sm" @click="printPage"><b-icon-printer /> Print</b-button>
			<b-button class="ml-2" variant="danger" size="sm" @click="closePage"><b-icon-x /> Close</b-button>
		</b-row>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-card>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
	:deep .col-form-label {
		padding-top: 0rem !important;
		padding-bottom: 0rem !important;
	}

	h6 {
		font-weight: bold;
	}
</style>
<script>
import BeritaAcaraArchivedDocument from '@/models/ArchivedDocument/BeritaAcaraArchivedDocument';
import ContractDocumentList from '@/models/ContractDocument/ContractDocumentList';
import ContractDocumentFilter from '@/filters/ContractDocument/ContractDocumentFilter';
import RoleDetail from '@/models/Core/RoleDetail';

import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import AppInputTextarea from '@/components/AppInputTextarea';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import ContractStatusFilter from '@/filters/ContractStatus/ContractStatusFilter';
import ContractStatus from '@/models/ContractStatus/ContractStatus';
import PopupDownload from '@/components/PopupDownload';
import PopupPDF from '@/components/PopupPDF';
import PopupDataEditNotification from '@/components/PopupDataEditNotification';
import Upload from './Upload';
import { mapGetters } from 'vuex'

export default {
	components : {
		AppInputDatepicker,AppInputDatepickerRange,AppInputComboboxAutocomplete,AppInputTextarea
	},
	data() {
		return {
			model: null,
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			filter : {},
			currentContractDocumentPage : 1,
			contractDocumentPageSize: 10,
			sortByContractDocument: '',
			sortDescContractDocument: '',
			incomingDocuments : [],
			sortingBy: Array(),
			filter: null,
			sortBy: '',
			sortDesc: '',
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fields: [
				{"no" : { "class" : "th-number"}}, 
				{"documentCode": {}, "class" : "th-incoming-document", "label" : "Kode Risalah", key: 'documentCode', sortable: true}, 
				{"documentNo": {}, "class" : "th-document-no", "label" : "Nomor Surat", key: 'documentNo', sortable: true},
				{"documentDate": {}, "class" : "th-document-date", "label" : "Tanggal Surat", key: 'documentDate', sortable: true}, 
				{"documentType": {}, "class" : "th-document-type", "label" : "Jenis Surat", key: 'documentType', sortable: true}, 
				{"description": {}, "class" : "th-description", "label" : "Perihal Surat", key: 'description', sortable: true}, 
				{"archivedStatus": {}, "class" : "th-contract-status", "label" : "Status Arsip", key: 'archivedStatus', sortable: true}, 
				{"documentLocation": {}, "class" : "th-remaining-day", "label" : "Lokasi Arsip Surat", key: 'documentLocation', sortable: true},
			],
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			contractDocuments: [],
			isShowFilter: false,
			printedDate : null

		}
	},
	methods : {
		printPage() { window.print() },
		closePage() { window.close() },
		getArchivedDocuments : async function() {
			this.isDataLoading = true;
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			}
			ArchivedDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'incomingDocument,outgoingDocument')
			.then(result => {
				if (result != null) {
					this.archivedDocuments = result.data
					this.archivedDocumentsPaging = result
				}
				this.isDataLoading = false;
			})
			.catch(error => {
				this.isDataLoading = false;
			});
		},
		getArchivedDocumentsWithoutLoading : async function() {
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			}
			ArchivedDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'incomingDocument,outgoingDocument')
			.then(result => {
				if (result != null) {
					this.archivedDocuments = result.data
					this.archivedDocumentsPaging = result
				}
			})
			.catch(error => {});
		},
		getArchivedDocument : async function(id) {
			this.isBusy = true;
			BeritaAcaraArchivedDocument.getData(this, id, 'investigatorDetail,archivedDocument,pic,approvedBy').then(result => { 
				this.model = result; 
				this.isBusy = false; 
				this.archivedDocuments = result.archivedDocument;
			}).catch(error => { this.isBusy = false; });
		},
	},
	beforeMount() {
		this.filter = new ContractDocumentFilter();
	},
	watch : {
	},
	mounted(){
		let id = this.$route.params.id;
		this.getArchivedDocument(id);
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		userRole() {
			return RoleDetail.query().where("functionInfoId", "contract_document").first();
		},
		...mapGetters({
			user: 'user',
		})
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
