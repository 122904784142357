<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true id="popup-upload" @ok="uploadButtonAction" :okTitle="okTitle" title="Upload Data">
		<b-card>
			<b-row>
				<b-col cols="12">
					<app-input-fileupload label="File" size="sm" :model.sync="fileupload" />
				</b-col>
			</b-row>
			<b-row v-if="isUploadSuccess">
				<b-col cols="12">
					<b-card no-body>
						<b-tabs card>
							<b-tab title="User Profile" active>
								<b-row class="mb-2">
									<b-col>
										<span class="float-right">
											<b-form-select v-model="userProfilePageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
										</span>
									</b-col>
								</b-row>
								<b-row>
								<b-col>
								<div class="table-corner">
									<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="userProfiles" :fields="fieldsUserProfile" :busy="isDataLoading" :sort-by.sync="sortByUserProfile" :sort-desc.sync="sortDescUserProfile" small responsive>
									<template v-slot:head(actions)>
										<b-button @click="isShowUserProfileFilter = !isShowUserProfileFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
									</template>
									<template #top-row v-if="isShowUserProfileFilter">
										<b-th></b-th>
										<b-th stacked-heading="Name">
											<b-form-input id="input-full-name-table" v-model="filterUserProfile.fullName" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Job">
											<b-form-input id="input-job-description-table" v-model="filterUserProfile.jobDescription" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Email">
											<b-form-input id="input-email-table" v-model="filterUserProfile.email" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Core User">
											<v-select append-to-body label="firstName" :options="coreUsers" :value="filterUserProfile.coreUserId"  :reduce="item => item.id" v-model="filterUserProfile.coreUserId" @search="getCoreUsers" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Kantor">
											<v-select append-to-body label="name" :options="branchs" :value="filterUserProfile.branchId"  :reduce="item => item.id" v-model="filterUserProfile.branchId" @search="getBranchs" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Direktorat">
											<v-select append-to-body label="name" :options="directorates" :value="filterUserProfile.directorateId"  :reduce="item => item.id" v-model="filterUserProfile.directorateId" @search="getDirectorates" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Employee Level">
											<v-select append-to-body label="name" :options="employeeLevels" :value="filterUserProfile.employeeLevelId"  :reduce="item => item.id" v-model="filterUserProfile.employeeLevelId" @search="getEmployeeLevels" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Employee Status">
											<v-select append-to-body label="name" :options="employeeStatuss" :value="filterUserProfile.employeeStatusId"  :reduce="item => item.id" v-model="filterUserProfile.employeeStatusId" @search="getEmployeeStatuss" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Education Level">
											<v-select append-to-body label="name" :options="educationLevels" :value="filterUserProfile.educationLevelId"  :reduce="item => item.id" v-model="filterUserProfile.educationLevelId" @search="getEducationLevels" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Blood Type">
											<v-select append-to-body label="name" :options="bloodTypes" :value="filterUserProfile.bloodTypeId"  :reduce="item => item.id" v-model="filterUserProfile.bloodTypeId" @search="getBloodTypes" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Marital Status">
											<v-select append-to-body label="name" :options="maritalStatuss" :value="filterUserProfile.maritalStatusId"  :reduce="item => item.id" v-model="filterUserProfile.maritalStatusId" @search="getMaritalStatuss" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Join Date">
											<b-form-datepicker boundary="viewport" placeholder="" id="input-join-date-from-table" v-model="filterUserProfile.joinDateFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
											<b-form-datepicker boundary="viewport" placeholder="" id="input-join-date-to-table" v-model="filterUserProfile.joinDateTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
										</b-th>
										<b-th stacked-heading="Birth Date">
											<b-form-datepicker boundary="viewport" placeholder="" id="input-birth-date-from-table" v-model="filterUserProfile.birthDateFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
											<b-form-datepicker boundary="viewport" placeholder="" id="input-birth-date-to-table" v-model="filterUserProfile.birthDateTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
										</b-th>
										<b-th stacked-heading="Kids Count">
											<b-form-input id="input-kids-count-table" v-model="filterUserProfile.kidsCount" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Address">
											<b-form-input id="input-address-table" v-model="filterUserProfile.address" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Phone Number">
											<b-form-input id="input-phone-number-table" v-model="filterUserProfile.phoneNumber" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Npwp Number">
											<b-form-input id="input-npwp-number-table" v-model="filterUserProfile.npwpNumber" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Nip">
											<b-form-input id="input-nip-table" v-model="filterUserProfile.nip" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Real Email">
											<b-form-input id="input-real-email-table" v-model="filterUserProfile.realEmail" size="sm"></b-form-input>
										</b-th>
										<b-th>
										</b-th>
									</template>
									<template v-slot:table-busys>
										<div class="text-center my-2">
									<b-spinner class="align-middle text-primary"></b-spinner>
										<strong class="ml-2">Loading..</strong>
										</div>
									</template>
									<template v-slot:cell(no)="row">
										{{ ((row.index + 1) + ( userProfilePageSize * (currentUserProfilePage - 1))) }}
									</template>
									<template v-slot:cell(coreUser)="row">
										{{ row.item.coreUser != null ? row.item.coreUser.firstName : "" }}
									</template>
									<template v-slot:cell(branch)="row">
										{{ row.item.branch != null ? row.item.branch.name : "" }}
									</template>
									<template v-slot:cell(directorate)="row">
										{{ row.item.directorate != null ? row.item.directorate.name : "" }}
									</template>
									<template v-slot:cell(employeeLevel)="row">
										{{ row.item.employeeLevel != null ? row.item.employeeLevel.name : "" }}
									</template>
									<template v-slot:cell(employeeStatus)="row">
										{{ row.item.employeeStatus != null ? row.item.employeeStatus.name : "" }}
									</template>
									<template v-slot:cell(educationLevel)="row">
										{{ row.item.educationLevel != null ? row.item.educationLevel.name : "" }}
									</template>
									<template v-slot:cell(bloodType)="row">
										{{ row.item.bloodType != null ? row.item.bloodType.name : "" }}
									</template>
									<template v-slot:cell(maritalStatus)="row">
										{{ row.item.maritalStatus != null ? row.item.maritalStatus.name : "" }}
									</template>
									<template v-slot:cell(joinDate)="row">
										{{ row.item.joinDate | moment("DD-MMM-YYYY")  }}
									</template>
									<template v-slot:cell(birthDate)="row">
										{{ row.item.birthDate | moment("DD-MMM-YYYY")  }}
									</template>
									</b-table>
									</div>
								</b-col>
								<b-col cols="12">
									<b-pagination
									class="float-right btn-paging"
									v-model="currentUserProfilePage"
									:total-rows="totalUserProfileRow"
									:per-page="userProfilePageSize"
									aria-controls="my-table"
									></b-pagination>
								</b-col>
								</b-row>
							</b-tab>
						</b-tabs>
					</b-card>
				</b-col>
			</b-row>
		</b-card>
		<template #modal-footer="{ ok, cancel }">
			<b-button v-if="isUploadSuccess" size="sm" variant="primary" @click="downloadLog()">
				Download Log
			</b-button>
			<b-button size="sm" variant="success" class="float-right" @click="ok()">
				{{ okTitle }}
			</b-button>
			<b-button size="sm" variant="secondary" class="float-right" @click="cancel()">
				Cancel
			</b-button>
		</template>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<style lang="scss" scoped>
.card {
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
:deep .th-message {
	color: red;
}
:deep .modal-footer {
	display: block;
}
</style>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate"; 
import { required, minLength } from "vuelidate/lib/validators"; 
import { saveAs } from 'file-saver';

import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileList from '@/models/UserProfile/UserProfileList';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';

import CoreUser from '@/models/Core/CoreUser';
import CoreUserFilter from '@/filters/Core/CoreUserFilter';
import Branch from '@/models/Branch/Branch';
import BranchFilter from '@/filters/Branch/BranchFilter';
import Directorate from '@/models/Directorate/Directorate';
import DirectorateFilter from '@/filters/Directorate/DirectorateFilter';
import EmployeeLevel from '@/models/EmployeeLevel/EmployeeLevel';
import EmployeeLevelFilter from '@/filters/EmployeeLevel/EmployeeLevelFilter';
import EmployeeStatus from '@/models/EmployeeStatus/EmployeeStatus';
import EmployeeStatusFilter from '@/filters/EmployeeStatus/EmployeeStatusFilter';
import EducationLevel from '@/models/EducationLevel/EducationLevel';
import EducationLevelFilter from '@/filters/EducationLevel/EducationLevelFilter';
import BloodType from '@/models/BloodType/BloodType';
import BloodTypeFilter from '@/filters/BloodType/BloodTypeFilter';
import MaritalStatus from '@/models/MaritalStatus/MaritalStatus';
import MaritalStatusFilter from '@/filters/MaritalStatus/MaritalStatusFilter';
import AppInputFileupload from '@/components/AppInputFileupload';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
export default {
	props: ['model', 'openPopup','uploadButtonMethod', 'parentId'],
	components : {AppInputFileupload,AppInputDatetimepicker,AppInputDatetimepickerRange,},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fieldsUserProfile: [
				{"no" : { "class" : "th-number"}}, 
				{"fullName": { "class" : "th-full-name", "label" : "Name"}, key: 'fullName', sortable: true}, 
				{"jobDescription": { "class" : "th-job-description", "label" : "Job"}, key: 'jobDescription', sortable: true}, 
				{"email": { "class" : "th-email", "label" : "Email"}, key: 'email', sortable: true}, 
				{"coreUser": { "class" : "th-core-user", "label" : "Core User"}, key: 'coreUser', sortable: true}, 
				{"branch": { "class" : "th-branch", "label" : "Kantor"}, key: 'branch', sortable: true}, 
				{"directorate": { "class" : "th-directorate", "label" : "Direktorat"}, key: 'directorate', sortable: true}, 
				{"employeeLevel": { "class" : "th-employee-level", "label" : "Employee Level"}, key: 'employeeLevel', sortable: true}, 
				{"employeeStatus": { "class" : "th-employee-status", "label" : "Employee Status"}, key: 'employeeStatus', sortable: true}, 
				{"educationLevel": { "class" : "th-education-level", "label" : "Education Level"}, key: 'educationLevel', sortable: true}, 
				{"bloodType": { "class" : "th-blood-type", "label" : "Blood Type"}, key: 'bloodType', sortable: true}, 
				{"maritalStatus": { "class" : "th-marital-status", "label" : "Marital Status"}, key: 'maritalStatus', sortable: true}, 
				{"joinDate": { "class" : "th-join-date", "label" : "Join Date"}, key: 'joinDate', sortable: true}, 
				{"birthDate": { "class" : "th-birth-date", "label" : "Birth Date"}, key: 'birthDate', sortable: true}, 
				{"kidsCount": { "class" : "th-kids-count", "label" : "Kids Count"}, key: 'kidsCount', sortable: true}, 
				{"address": { "class" : "th-address", "label" : "Address"}, key: 'address', sortable: true}, 
				{"phoneNumber": { "class" : "th-phone-number", "label" : "Phone Number"}, key: 'phoneNumber', sortable: true}, 
				{"npwpNumber": { "class" : "th-npwp-number", "label" : "Npwp Number"}, key: 'npwpNumber', sortable: true}, 
				{"nip": { "class" : "th-nip", "label" : "Nip"}, key: 'nip', sortable: true}, 
				{"realEmail": { "class" : "th-real-email", "label" : "Real Email"}, key: 'realEmail', sortable: true}, 
				{"class" : "th-status", "label" : "Status", key: 'uploadValidationStatus', sortable: true},
				{"class" : "th-message", "label" : "Message", key: 'uploadValidationMessage', sortable: true},
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isDataLoading: false,
			isBusy : false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			userProfiles : [],
			userProfileId : '',
			totalUserProfileRow : 0,
			isShowUserProfileFilter: false,
			filterUserProfile : null,
			currentUserProfilePage : 1,
			userProfilePageSize: 10,
			sortByUserProfile: '',
			sortDescUserProfile: '',
			coreUsers: [],
			branchs: [],
			directorates: [],
			employeeLevels: [],
			employeeStatuss: [],
			educationLevels: [],
			bloodTypes: [],
			maritalStatuss: [],
			processId: "",
			isUploadSuccess: false,
		}
	},
	methods : {
		resetModal() {
			this.datas = Array();
			this.$emit('resetMethod');
		},
		uploadButtonAction(modalEvent) {
			modalEvent.preventDefault();
			this.isBusy = true;
			if (this.isUploadSuccess) {
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'userprofile/confirmUpload',
					[],
					).then(async function(response){
						if (response != null) {
						if (response.data != null) {
							_this.resetModal();
							_this.datas = Array();
							_this.fileupload = null;
							_this.isUploadSuccess = false;
						}
						}
						_this.isBusy = false;
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
			} else {
				if (this.fileupload != null) {
				let data = new FormData();
				data.append('file', this.fileupload);
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'userprofile/upload',
					data,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
					).then(async function(response){
						_this.isBusy = false;
						_this.isUploadSuccess = true;
						_this.getUserProfiles();
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
				}
			}
		},
		getUserProfiles : async function() {
			let filter = {};
			Object.assign(filter, this.filterUserProfile);
			filter.draftFromUpload = "1";
			filter.draftMode = "1";
			this.sortingByUserProfile = Array();
			if (this.sortByUserProfile != null) {
				this.sortingByUserProfile[this.sortByUserProfile] = this.sortDescUserProfile;
			}
			let currentPage = (this.currentUserProfilePage - 1);
			UserProfile.getList(this, filter, this.sortingByUserProfile, currentPage , this.userProfilePageSize).then(result => {
				this.userProfiles = result.data;
				this.totalUserProfileRow = result.dataCount;
			}).catch(error => {});
		},
		getCoreUsers : async function(input) {
			let filter = new CoreUserFilter();
			if (input != undefined) {
				filter.firstName = input;
			}
			CoreUser.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.coreUsers = result.data
				}
			})
			.catch(error => {});
		},
		getBranchs : async function(input) {
			let filter = new BranchFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Branch.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.branchs = result.data
				}
			})
			.catch(error => {});
		},
		getDirectorates : async function(input) {
			let filter = new DirectorateFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Directorate.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.directorates = result.data
				}
			})
			.catch(error => {});
		},
		getEmployeeLevels : async function(input) {
			let filter = new EmployeeLevelFilter();
			if (input != undefined) {
				filter.name = input;
			}
			EmployeeLevel.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.employeeLevels = result.data
				}
			})
			.catch(error => {});
		},
		getEmployeeStatuss : async function(input) {
			let filter = new EmployeeStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			EmployeeStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.employeeStatuss = result.data
				}
			})
			.catch(error => {});
		},
		getEducationLevels : async function(input) {
			let filter = new EducationLevelFilter();
			if (input != undefined) {
				filter.name = input;
			}
			EducationLevel.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.educationLevels = result.data
				}
			})
			.catch(error => {});
		},
		getBloodTypes : async function(input) {
			let filter = new BloodTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			BloodType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.bloodTypes = result.data
				}
			})
			.catch(error => {});
		},
		getMaritalStatuss : async function(input) {
			let filter = new MaritalStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			MaritalStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.maritalStatuss = result.data
				}
			})
			.catch(error => {});
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		downloadLog() {
			let _this = this;
			this.$http.post(process.env.VUE_APP_API_URL + 'userprofile/downloadlog', [],
			{ headers: { 'Content-Type': 'application/json' }}
			).then(async function(response){
				await saveAs(process.env.VUE_APP_API_URL + 'userprofile/downloadlog', "UserProfile.xslx");
				_this.isBusy = false;
			})
			.catch(error => {
				_this.$store.dispatch('addErrorMessageGlobal', error);
				_this.isBusy = false;
			});
		}
	},
	beforeMount() {
	},
	mounted(){
	},
	watch: {
		sortByUserProfile : {
			handler: function() {
				this.getUserProfiles();
			}
		},
		sortDescUserProfile: {
			handler: function() {
				this.getUserProfiles();
			}
		},
		currentUserProfilePage: {
			handler: function() {
				this.getUserProfiles();
			}
		},
		userProfilePageSize : {
			handler: function() {
				this.getUserProfiles();
			}
		},
		filterUserProfile: {
			handler: function() {
				this.getUserProfiles();
			},
			deep: true,
		},
		fileupload(newValue, oldValue) {
			this.datas = Array();
			this.isUploadSuccess = false;
		}
	},
	computed: {
		okTitle: {
			get: function() {
				if (this.isUploadSuccess) {
					return "Proses";
				}
				return "Simpan";
				}
		},
		isShowPopup : {
			get: function () {
				if (this.openPopup) {
					this.filterUserProfile = new UserProfileFilter();
					return true;
				}
				else return false;
			},
			set: function (newValue) {}
		},
	}
}
</script>
