import { Model } from '@vuex-orm/core';
import axios from 'axios';
import { saveAs } from 'file-saver';

export default class BaseModelAPI {
    static entityName;
    static listModel;
    static useAuth = true;
    static filterMapping = {};
    
    static getSavedData() {
        return this.query().where("isSavedToBackend", true).where("isFromUpload", false);
    }

    static async getList(this_, filter, sorting, pageIndex, pageSize, include) {
        //console.log(...arguments);
        let filterArray = Array();
        if (filter != null && filter != undefined) {
            //console.log(filter);
            filterArray = this.getFilter(filter);
        }
        if (sorting != null && sorting != undefined) {
            Object.entries(sorting).map(([key, value]) => { 
                key = this.getFilterMapping(key);
                if(key != '') {
                    if (key.includes("|")) {
                        let keys = key.split("|");
                        keys.forEach((item) => {
                            filterArray.push("sort=" + (value ? "-" : "+") + item);
                        })
                    } else {
                        filterArray.push("sort=" + (value ? "-" : "+") + key);     // draftFromUpload & draftMode
                    }
                }
            });
                
        }
        if (pageIndex != null) {
            filterArray.push("page=" + pageIndex);
        }
        if (pageSize != null) {
            filterArray.push("per_page=" + pageSize);
        }
        if (include != null) {
            filterArray.push("include=" + include);
        }
        let params = "";
        if (filterArray.length > 0) {
            params = "?" + filterArray.join("&");
        }
        
        //DEBUG

        return this_.$http.get(`${this.entityName}` + params)
        .then(response => {
            if (response != null) {
                return response.data;
            }
            return response;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async getListInbox(this_, filter, sorting, pageIndex, pageSize, include) {
        //console.log(...arguments);
        let filterArray = Array();
        if (filter != null && filter != undefined) {
            //console.log(filter);
            filterArray = this.getFilter(filter);
        }
        if (sorting != null && sorting != undefined) {
            Object.entries(sorting).map(([key, value]) => { 
                if(key != '') {
                    filterArray.push("sort=" + (value ? "-" : "+") + key);     // draftFromUpload & draftMode
                }
            });
                
        }
        if (pageIndex != null) {
            filterArray.push("page=" + pageIndex);
        }
        if (pageSize != null) {
            filterArray.push("per_page=" + pageSize);
        }
        if (include != null) {
            filterArray.push("include=" + include);
        }
        let params = "";
        if (filterArray.length > 0) {
            params = "?" + filterArray.join("&");
        }
        
        //DEBUG

        return this_.$http.get(`${this.entityName}/inbox` + params)
        .then(response => {
            if (response != null) {
                return response.data;
            }
            return response;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async getData(this_, id, include) {
        let filterArray = Array();
        if (include != null) {
            filterArray.push("include=" + include);
        }
        let params = "";
        if (filterArray.length > 0) {
            params = "?" + filterArray.join("&");
        }
        return this_.$http.get(process.env.VUE_APP_API_URL + `${this.entityName}/${id}${params}`)
        .then(response => {
            let responseData = response.data;
            if ("data" in responseData) {
                responseData = responseData.data;
            }
            //console.log(responseData);
            return responseData;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });;
    }
    
    static async createData(this_, data) {
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}/create`, data)
        .then(response => {
            //console.log(response);
            if (response.data) {
                if (response.data.data) {
                    return response.data.data;
                }
                return response.data;
            }
            
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async createDataDetail(this_, data) {
        // alert('createDataDetail')
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}`, data)
        .then(response => {
                return response.data;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async insertData(this_, data) {
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}`, data)
        .then(response => {
            if (response.data) {
                if (response.data.data) {
                    return response.data.data;
                }
                return response.data;
            }
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }
    
    static async updateData(this_, data, id) {
        return this_.$http.put(process.env.VUE_APP_API_URL + `${this.entityName}/update/${id}`, data)
        .then(response => {
            if (response.data) {
                if (response.data.data) {
                    return response.data.data;
                }
                return response.data;
            }
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async updateDataDetail(this_, data, id) {
        return this_.$http.put(process.env.VUE_APP_API_URL + `${this.entityName}/${id}`, data)
        .then(response => {
            return response.data;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async editData(this_, id) {
        return this_.$http.get(process.env.VUE_APP_API_URL + `${this.entityName}/edit/${id}`)
        .then(response => {
            return response.data;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async commitData(this_, data, id) {
        return this_.$http.put(process.env.VUE_APP_API_URL + `${this.entityName}/commit/${id}`, data)
        .then(response => {
            return response.data;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async deleteData(this_, id) {
        

        return this_.$http.delete(process.env.VUE_APP_API_URL + `${this.entityName}/${id}`, {
            delete: id,

        }).catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });;
    }

    static async discardData(this_, id) {
        return this_.$http.delete(process.env.VUE_APP_API_URL + `${this.entityName}/discard/${id}`).catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });;
    }

    static async discardDetailUploadData(this_) {
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}/discardupload`).catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }
    static async discardUpload(this_) {
        return this_.$http.get(process.env.VUE_APP_API_URL + `${this.entityName}/discardupload/`).catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async validateData(this_, data, id) {
        return this_.$http.put(process.env.VUE_APP_API_URL + `${this.entityName}/validatedata/${id}`, data)
        .then(response => {
            //console.log(response);
            return response.data;
        })
        .catch(function(error){
            //console.log(error);
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async addFromClipboard(this_, data) {
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}/add`, data)
        .then(response => {
                return response.data;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async replaceFromClipboard(this_, data) {
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}/replace`, data)
        .then(response => {
                return response.data;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async checkAllData(this_, filter, sorting) {
        

        let filterArray = Array();
        if (filter != null && filter != undefined) {
            filterArray = this.getFilter(filter);
        }
        if (sorting != null && sorting != undefined) {
            Object.entries(sorting).map(([key, value]) => { if(value != '') filterArray.push("sorting[" + key +"]=" + value); });
        }
        let params = "";
        if (filterArray.length > 0) {
            params = "?" + filterArray.join("&");
        }
        let _this = this_;
        this_.$http.get(process.env.VUE_APP_API_URL + `${this.entityName}/ids` + params)
            .then(async function(response){
                if (response.data) {
                    _this.$store.dispatch('addCheckAllData', response.data);
                }
                return true;
            })
            .catch(function(error){
                this_.$store.dispatch('addErrorMessageGlobal', error);
                throw error;
            });
    }

    static async multiPagePrint(this_) {
        let _this = this_;
        let ids = _this.$store.state.dataIds;
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}/multipagepdf`, {
                ids
                })
            .then(async function(response){
                if (response.data) {
                    _this.$store.dispatch('addDownloadPDFId', response.data);
                    return response.data;
                }
                return null;
            })
            .catch(function(error){
                this_.$store.dispatch('addErrorMessageGlobal', error);
                throw error;
            });
    }

    static async sendMultipleDocument(this_) {
        let _this = this_;
        let ids = _this.$store.state.dataIds;
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}/sendmultipledocument`, {
                ids
                })
            .then(async function(response){
                if (response.data) {
                    return response.data;
                }
                return null;
            })
            .catch(function(error){
                this_.$store.dispatch('addErrorMessageGlobal', error);
                throw error;
            });
    }

    static async sendEmail(this_) {
        let _this = this_;
        let ids = _this.$store.state.dataIds;
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}/sendemail`, {
                ids
                })
            .then(async function(response){
                if (response.data) {
                    return response.data;
                }
                return null;
            })
            .catch(function(error){
                this_.$store.dispatch('addErrorMessageGlobal', error);
                throw error;
            });
    }

    static async singlePagePrint(this_) {
        

        let _this = this_;
        let ids = _this.$store.state.dataIds;
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}/singlepagepdf`, {
                ids
            })
            .then(async function(response){
                if (response.data) {
                    return response.data;
                }
                return null;
            })
            .catch(function(error){
                this_.$store.dispatch('addErrorMessageGlobal', error);
                throw error;
            });
    }

    static async singleDataPagePrint(this_, id) {
         let _this = this_;
        let ids = Array();
        ids.push(id);
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}/singlepagepdf`, {
            ids
            })
            .then(async function(response){
                if (response.data) {
                    return response.data;
                }
                return null;
            })
            .catch(function(error){
                this_.$store.dispatch('addErrorMessageGlobal', error);
                throw error;
            });
    }

    static async downloadData(this_, filter, sorting) {
        

        let filterArray = Array();
        if (filter != null && filter != undefined) {
            filterArray = this.getFilter(filter);
        }
        if (sorting != null && sorting != undefined) {
            Object.entries(sorting).map(([key, value]) => { if(value != '') filterArray.push("sorting[" + key +"]=" + (value ? "1" : "0")); });
        }

        let params = "";
        if (filterArray.length > 0) {
            params = "?" + filterArray.join("&");
        }
        let _this = this;
        return this_.$http.get(process.env.VUE_APP_API_URL + `${this.entityName}/downloaddata` + params)
            .then(async function(response){
                if (response.data) {
                    return response.data;
                }
                return null;
            })
            .catch(function(error){
                this_.$store.dispatch('addErrorMessageGlobal', error);
                throw error;
            });
    }

    static async downloadDataDetail(this_, filter, sorting) {
        let filterArray = Array();
        if (filter != null && filter != undefined) {
            filterArray = this.getFilter(filter);
        }
        if (sorting != null && sorting != undefined) {
            Object.entries(sorting).map(([key, value]) => { if(value != '') filterArray.push("sorting[" + key +"]=" + (value ? "1" : "0")); });
        }

        let params = "";
        if (filterArray.length > 0) {
            params = "?" + filterArray.join("&");
        }
        let _this = this;
        return await this_.$http.get(process.env.VUE_APP_API_URL + `${this.entityName}/downloaddata` + params, {responseType: 'blob'})
            .then(async function(response){
                if (response.data) {
                    await saveAs(response.data, _this.entityName + ".xlsx");
                }
                return null;
            })
            .catch(function(error){
                this_.$store.dispatch('addErrorMessageGlobal', error);
                throw error;
            });
    }

    static getFilter(filter) {
        let filterArray = Array();
        Object.entries(filter).map(([key, value]) => { 
            if (key != "exactType") {
                if(value != '' && value != null) {
                    let values = Array();
                    if (typeof value === "string") {
                        if(value.match(/(?:\r\n|\r|\n)/g)) { 
                            value=value.replace(/(?:\r\n|\r|\n)/g, ','); 
                        }
                        values = value.split(","); 
                    } else {
                        values.push(value);
                    }
                    
                    let operator = "like";
                    if (filter.exactType != undefined && filter.exactType != null) {
                        if (filter.exactType[key] != undefined) {
                            filterArray.push("exact[" + key +"]=" + filter.exactType[key]);
                            if (filter.exactType[key] == "1") {
                                operator = "eq";
                            }
                        }
                    }
                    if (key.indexOf("-") >= 0) {
                        let keys = key.split("-");
                        operator = keys[0];
                        key = keys[1];
                    }
                    key = this.getFilterMapping(key);
                    if (key.includes("|")) {
                        let keys = key.split("|");
                        keys.forEach((item) => {
                            values.forEach(itemValue => filterArray.push("filter[" + item +"][" + operator + "]=" + itemValue));
                        });
                    } else {
                        values.forEach(itemValue => filterArray.push("filter[" + key +"][" + operator + "]=" + itemValue));
                    }
                } else {
                    if (filter.exactType != undefined && filter.exactType != null) {
                        if (filter.exactType[key] != undefined) {
                            if (typeof value === "boolean") {
                                if (filter.exactType[key] == "1") {
                                    filterArray.push("filter[" + key +"][]=" + value);
                                }
                            }
                        }
                    }
                }
            }
        });

        return filterArray;
    }

    static async downloadFile(this_, dataUrl, fileName) {
        this_.$http.get(dataUrl, {responseType: 'blob'}).then(async response => {
            //console.log(response);
            if (response != null) {
                await saveAs(response.data, fileName);
            }
        }).catch(error => {
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async checkProgress(this_, processId) {
        let progressInterval = setInterval(() => {
            this_.$http.get(process.env.VUE_APP_API_URL + 'DownloadProcess/status/' + processId).then(response => {
                if (response.data != null) {
                    if (response.data.status == "SUCCESS") {
                        let dataUrl = response.data.filename;
                        let fileName = this.GetFilename(dataUrl);
                        this.downloadFile(this_, dataUrl, fileName)
                        clearInterval(progressInterval);
                    } else if (response.data.status == "FAILED") {
                        clearInterval(progressInterval);
                    }
              } else {
                clearInterval(progressInterval);
              }
              return true;
            }).catch(error => {
              clearInterval(progressInterval);
              this_.$store.dispatch('addErrorMessageGlobal', error);
              throw error;
            })
        }, 500);
      }

      static GetFilename(url)
      {
        if (url)
        {
            var m = url.toString().match(/.*\/(.+?)\./);
            if (m && m.length > 1)
            {
              return m[1];
            }
        }
        return "";
      }

      static async userProfileLogin(this_) {

        let _this = this_;
        return this_.$http.get(process.env.VUE_APP_API_URL + `userprofilelogin`, {
            })
            .then(async function(response){
                if (response.data) {
                    if (response.data.data) {
                        return response.data.data;
                    }
                    return response.data;
                }
                return null;
            })
            .catch(function(error){
                this_.$store.dispatch('addErrorMessageGlobal', error);
                throw error;
            });
    }

    static async exportData(this_, filter, sorting, pageIndex, pageSize, include) {
        let filterArray = Array();
        if (filter != null && filter != undefined) {
            filterArray = this.getFilter(filter);
        }
        if (sorting != null && sorting != undefined) {
            Object.entries(sorting).map(([key, value]) => { 
                if(key != '') {
                    filterArray.push("sort=" + (value ? "-" : "+") + key);
                }
            });
                
        }
        if (pageIndex != null) {
            filterArray.push("page=" + pageIndex);
        }
        if (pageSize != null) {
            filterArray.push("per_page=" + pageSize);
        }
        if (include != null) {
            filterArray.push("include=" + include);
        }
        let params = "";
        if (filterArray.length > 0) {
            params = "?" + filterArray.join("&");
        }
        
        //DEBUG

        return this_.$http.get(`${this.entityName}exportdata` + params, {responseType: 'blob'})
        .then(response => {
            return response;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static async exportDataInbox(this_, filter, sorting, pageIndex, pageSize, include) {
        let filterArray = Array();
        if (filter != null && filter != undefined) {
            filterArray = this.getFilter(filter);
        }
        if (sorting != null && sorting != undefined) {
            Object.entries(sorting).map(([key, value]) => { 
                if(key != '') {
                    filterArray.push("sort=" + (value ? "-" : "+") + key);
                }
            });
                
        }
        if (pageIndex != null) {
            filterArray.push("page=" + pageIndex);
        }
        if (pageSize != null) {
            filterArray.push("per_page=" + pageSize);
        }
        if (include != null) {
            filterArray.push("include=" + include);
        }
        let params = "";
        if (filterArray.length > 0) {
            params = "?" + filterArray.join("&");
        }
        
        //DEBUG

        return this_.$http.get(`${this.entityName}exportdataapproval` + params, {responseType: 'blob'})
        .then(response => {
            return response;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

    static getFilterMapping(filterBy) {
        if (this.filterMapping != null && this.filterMapping != undefined)
            if (this.filterMapping[filterBy] !== undefined)
                return this.filterMapping[filterBy];

		return filterBy;
	}

}