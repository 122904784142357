<template>
    <div>
        <div v-if="!nolabel">
            <b-form-group id="fieldset-is-received" boundary="viewport" v-bind="$attrs">
                <template v-slot:label>{{ labelStr }} <span v-if="isRequired" class="required"></span></template>
                <div v-if="isAllowEdit">
                    <b-input-group class="mb-3">
                        <b-input-group-prepend>
                            <b-form-datepicker
                                v-model="inputModelDate"
                                button-only
                                locale="en-US"
                                aria-controls="input-datepicker"
                                size="sm"
                                class="bg-transparent"
                                @input="setDate"
                                :min="minDate"
                            ></b-form-datepicker>
                            <b-form-timepicker
                                v-model="inputModelTime"
                                button-only
                                :hour12="false"
                                locale="en"
                                size="sm"
                                class="bg-transparent"
                                aria-controls="input-timepicker"
                                @input="setTime"
                            ></b-form-timepicker>
                        </b-input-group-prepend>
                        <b-form-input
                            id="datetimepicker-input"
                            v-model="inputModelStr"
                            type="text"
                            placeholder="No datetime selected"
                            autocomplete="off"
                            size="sm"
                            v-mask="'##/##/#### ##:##'"
                            @blur="updateInput"
                        ></b-form-input>
                    </b-input-group>
                    <b-form-invalid-feedback id="input-name-live-feedback" :state="state">
                        {{ errorMessage }}
                    </b-form-invalid-feedback>
                </div>
                <div v-else>
                    {{ inputModel }}
                </div>
            </b-form-group>
        </div>
        <div v-else>
            <div v-if="isAllowEdit">
                <b-input-group class="mb-3">
                    <b-input-group-prepend>
                        <b-form-datepicker
                            v-model="inputModelDate"
                            button-only
                            locale="en-US"
                            aria-controls="input-datepicker"
                            size="sm"
                            class="bg-transparent"
                            @input="setDate"
                        ></b-form-datepicker>
                        <b-form-timepicker
                            v-model="inputModelTime"
                            button-only
                            :hour12="false"
                            locale="en"
                            size="sm"
                            class="bg-transparent"
                            aria-controls="input-timepicker"
                            @input="setTime"
                        ></b-form-timepicker>
                    </b-input-group-prepend>
                    <b-form-input
                        id="example-input"
                        v-model="inputModelStr"
                        type="text"
                        placeholder="No datetime selected"
                        autocomplete="off"
                        size="sm"
                        readonly
                    ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback id="input-name-live-feedback" :state="state">
                    {{ errorMessage }}
                </b-form-invalid-feedback>
            </div>
            <div v-else>
                {{ inputModel }}
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    :deep .btn.btn-secondary, :deep .btn.btn-secondary:active, :deep .btn.btn-secondary:hover {
        background-color: transparent !important;
    }
    :deep .form-control:disabled, .form-control[readonly] {
        background-color: transparent !important;
    }
    :deep ::placeholder {
        color: #b5b5c3 !important;
        background-color: transparent !important;
    }

    :deep :-ms-input-placeholder {
        color: #b5b5c3 !important;
        background-color: transparent !important;
    }

    :deep ::-ms-input-placeholder {
        color: #b5b5c3 !important;
        background-color: transparent !important;
    }
</style>
<script>
import moment from 'moment';

export default {
    props: ["model", "errorMessage", "state", "suffix", "prefix", "isEditable", "change", "nolabel", "label", "isRequired","min", "isMinActive"],
    data() {
        return {
            labelStr: this.label,
            inputModel:this.model,
            inputModelDate: '',
            inputModelTime: '',
            inputModelStr: '',
            prefixExist: (this.prefix ? true : false),
            suffixExist: (this.suffix ? true : false),
            value:'',
            minDate:this.min,
        }
    }, 
    methods: {
        setDate() {
            let inputModelDate = '1970-01-01';
            if (this.inputModelDate != '') {
                inputModelDate = this.inputModelDate;
            }
            let inputModelTime = '00:00';
            if (this.inputModelTime != '') {
                inputModelTime = this.inputModelTime;
            }
            this.inputModel = inputModelDate + ' ' + inputModelTime;
            let date = new Date(this.inputModel);
            if (date) {
                this.inputModelStr = moment(date).format('DD/MM/YYYY HH:mm');
            }
            this.$emit('update:model', this.inputModel);
            this.$emit('change')
        },
        setTime() {
            let inputModelDate = '1970-01-01';
            if (this.inputModelDate != '') {
                inputModelDate = this.inputModelDate;
            }
            const now = new Date()
      	    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            
            var inputModelDateObj = new Date(inputModelDate);
            inputModelDateObj.setHours(0,0,0,0);

            let inputModelTime = '00:00';
            if (this.inputModelTime != '') {
                inputModelTime = this.inputModelTime;
            }

            let updateData = true;
            if (this.isMinActive) {
                if (today.getTime() === inputModelDateObj.getTime()) {
                    let timeNow = (now.getHours() + 3 < 10 ? '0' : '') + (now.getHours() + 3) + ":" + now.getMinutes() + ":00";
                    if (inputModelTime < timeNow) {
                        updateData = false;
                    } 
                }
            }

            if (updateData) {
                this.inputModel = inputModelDate + ' ' + inputModelTime;
                let date = new Date(this.inputModel);
                if (date) {
                    this.inputModelStr = moment(date).format('DD/MM/YYYY HH:mm');
                }
                this.$emit('update:model', this.inputModel);
                this.$emit('change')
            } else {
                let date = new Date(this.inputModel);
                this.inputModelTime = (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + date.getMinutes() + ":00";
            }
        },
        updateInput() {
            console.log(this.inputModelStr);
            let arrDateTime = this.inputModelStr.split(" ");
            
            let inputModelDate = this.inputModelDate;
            if (arrDateTime.length > 0) {
                let arrDate = arrDateTime[0].split("/");
                if (arrDate.length > 2) {
                    inputModelDate = arrDate[2] + "-" + arrDate[1] + "-" + arrDate[0];
                }
                this.inputModelDate = inputModelDate;
            }
            let inputModelTime = this.inputModelTime;
            if (arrDateTime.length > 1) {
                inputModelTime = arrDateTime[1];
                this.inputModelTime = inputModelTime;
            }
            this.inputModel = inputModelDate + ' ' + inputModelTime;
            //console.log(this.inputModel);
            this.$emit('update:model', this.inputModel);
        }
    },
    mounted() {
        let newValue = this.model;
        let date = new Date();
        if (newValue != null) {
            date = new Date(newValue);
        } else {
            if (this.isMinActive) {
                date.setHours(date.getHours() + 3);
            }
        }
        //console.log(date);
        if (date != 'Invalid Date') {
            this.inputModelStr = moment(date).format('DD/MM/YYYY HH:mm');
            this.inputModelDate = moment(date).format('YYYY-MM-DD');
            this.inputModelTime = moment(date).format('HH:mm');
        } else {

        }
        this.inputModel = newValue;
    },
    computed : {
        isAllowEdit : {
            get: function() {
                if (this.isEditable != undefined) return this.isEditable;
                else return true;
            }
        }
    },
    watch: {
        model(newValue) {
            let date = new Date(newValue);
            //console.log(date);
            if (date != 'Invalid Date') {
                this.inputModelStr = moment(date).format('DD/MM/YYYY HH:mm');
                this.inputModelDate = moment(date).format('YYYY-MM-DD');
                this.inputModelTime = moment(date).format('HH:mm');
            }
            this.inputModel = newValue
            //console.log(this.inputModel);
            //console.log(this.inputModelStr);
            //console.log(this.inputModelDate);
            //console.log(this.inputModelTime);
        },
        
    }
}
</script>
<style scoped>
.input-group-text {
    font-size: 0.7rem !important;
}
</style>