import Vue from 'vue';
import Router from 'vue-router';
// import { authGuard } from "./auth";
import store from "./store";

import Home from './views/Home/Home.vue';
import LoginIndex from './views/Login/Index.vue';

import FunctionInfoIndex from './views/FunctionInfo/Index.vue';
import FunctionInfoInput from './views/FunctionInfo/Input.vue';
import FunctionInfoDetail from './views/FunctionInfo/Detail.vue';

import DocumentLocationIndex from './views/DocumentLocation/Index.vue';
import DocumentLocationInput from './views/DocumentLocation/Input.vue';
import DocumentLocationDetail from './views/DocumentLocation/Detail.vue';

import DocumentCategoryIndex from './views/DocumentCategory/Index.vue';
import DocumentCategoryInput from './views/DocumentCategory/Input.vue';
import DocumentCategoryDetail from './views/DocumentCategory/Detail.vue';

import DocumentTypeIndex from './views/DocumentType/Index.vue';
import DocumentTypeInput from './views/DocumentType/Input.vue';
import DocumentTypeDetail from './views/DocumentType/Detail.vue';

import SenderStatusIndex from './views/SenderStatus/Index.vue';
import SenderStatusInput from './views/SenderStatus/Input.vue';
import SenderStatusDetail from './views/SenderStatus/Detail.vue';

import DistributionTypeIndex from './views/DistributionType/Index.vue';
import DistributionTypeInput from './views/DistributionType/Input.vue';
import DistributionTypeDetail from './views/DistributionType/Detail.vue';

import IncomingDocumentTypeIndex from './views/IncomingDocumentType/Index.vue';
import IncomingDocumentTypeInput from './views/IncomingDocumentType/Input.vue';
import IncomingDocumentTypeDetail from './views/IncomingDocumentType/Detail.vue';

import IncomingDocumentStatusIndex from './views/IncomingDocumentStatus/Index.vue';
import IncomingDocumentStatusInput from './views/IncomingDocumentStatus/Input.vue';
import IncomingDocumentStatusDetail from './views/IncomingDocumentStatus/Detail.vue';

import OutgoingDocumentStatusIndex from './views/OutgoingDocumentStatus/Index.vue';
import OutgoingDocumentStatusInput from './views/OutgoingDocumentStatus/Input.vue';
import OutgoingDocumentStatusDetail from './views/OutgoingDocumentStatus/Detail.vue';

import OutgoingDocumentApprovalStatusIndex from './views/OutgoingDocumentApprovalStatus/Index.vue';
import OutgoingDocumentApprovalStatusInput from './views/OutgoingDocumentApprovalStatus/Input.vue';
import OutgoingDocumentApprovalStatusDetail from './views/OutgoingDocumentApprovalStatus/Detail.vue';

import ContractStatusIndex from './views/ContractStatus/Index.vue';
import ContractStatusInput from './views/ContractStatus/Input.vue';
import ContractStatusDetail from './views/ContractStatus/Detail.vue';

import ArchivedDocumentStatusIndex from './views/ArchivedDocumentStatus/Index.vue';
import ArchivedDocumentStatusInput from './views/ArchivedDocumentStatus/Input.vue';
import ArchivedDocumentStatusDetail from './views/ArchivedDocumentStatus/Detail.vue';

import RemovalMethodIndex from './views/RemovalMethod/Index.vue';
import RemovalMethodInput from './views/RemovalMethod/Input.vue';
import RemovalMethodDetail from './views/RemovalMethod/Detail.vue';


import DocumentStatusIndex from './views/DocumentStatus/Index.vue';
import DocumentStatusInput from './views/DocumentStatus/Input.vue';
import DocumentStatusDetail from './views/DocumentStatus/Detail.vue';


import SifatSuratIndex from './views/SifatSurat/Index.vue';
import SifatSuratInput from './views/SifatSurat/Input.vue';
import SifatSuratDetail from './views/SifatSurat/Detail.vue';


import UserProfileIndex from './views/UserProfile/Index.vue';
import UserProfileInput from './views/UserProfile/Input.vue';
import UserProfileDetail from './views/UserProfile/Detail.vue';


import ReceiptDocumentIndex from './views/ReceiptDocument/Index.vue';
import ReceiptDocumentInput from './views/ReceiptDocument/Input.vue';
import ReceiptDocumentDetail from './views/ReceiptDocument/Detail.vue';
import ReceiptDocumentPrint from './views/ReceiptDocument/Print.vue';
import ReceiptDocumentNotYetReceipt from './views/ReceiptDocument/NotYet.vue';
import ReceiptDocumentDetailNoPrint from './views/ReceiptDocument/DetailNoPrint.vue';


import IncomingDocumentIndex from './views/IncomingDocument/Index.vue';
import IncomingDocumentCopy from './views/IncomingDocument/Copy.vue';
import IncomingDocumentDestination from './views/IncomingDocument/Destination.vue';
import IncomingDocumentInput from './views/IncomingDocument/Input.vue';
import IncomingDocumentDetail from './views/IncomingDocument/Detail.vue';


import BranchIndex from './views/Branch/Index.vue';
import BranchInput from './views/Branch/Input.vue';
import BranchDetail from './views/Branch/Detail.vue';


import DirectorateIndex from './views/Directorate/Index.vue';
import DirectorateInput from './views/Directorate/Input.vue';
import DirectorateDetail from './views/Directorate/Detail.vue';


import DispositionIndex from './views/Disposition/Index.vue';
import DispositionInput from './views/Disposition/Input.vue';
import DispositionDetail from './views/Disposition/Detail.vue';


import ContractDocumentIndex from './views/ContractDocument/Index.vue';
import ContractDocumentIndexOutgoing from './views/ContractDocument/IndexOutgoing.vue';
import ContractDocumentInput from './views/ContractDocument/Input.vue';
import ContractDocumentDetail from './views/ContractDocument/Detail.vue';
import ContractDocumentDetailOutgoing from './views/ContractDocument/Detail.vue';
import ContractDocumentPrint from './views/ContractDocument/PrintContract.vue';
import ContractDocumentPrintOutgoing from './views/ContractDocument/PrintContractOutgoing.vue';


import OutgoingDocumentIndex from './views/OutgoingDocument/Index.vue';
import OutgoingDocumentInput from './views/OutgoingDocument/Input.vue';
import OutgoingDocumentDetail from './views/OutgoingDocument/Detail.vue';
import OutgoingDocumentDetailApproval from './views/OutgoingDocument/DetailApproval.vue';
import OutgoingDocumentDetailApprovalExternal from './views/OutgoingDocument/DetailExternalApproval.vue';

import OutgoingDocumentExternalIndex from './views/OutgoingDocument/IndexExternal.vue';
import OutgoingDocumentExternalInput from './views/OutgoingDocument/InputExternal.vue';
import OutgoingDocumentExternalDetail from './views/OutgoingDocument/DetailExternal.vue';

import OutgoingDocumentApprovalIndex from './views/OutgoingDocument/IndexApproval.vue';
import OutgoingDocumentIndexReminder from './views/OutgoingDocument/IndexReminder.vue';

import DocumentAttachmentIndex from './views/DocumentAttachment/Index.vue';
import DocumentAttachmentInput from './views/DocumentAttachment/Input.vue';
import DocumentAttachmentDetail from './views/DocumentAttachment/Detail.vue';
import DocumentAttachmentIndexDetail from './views/DocumentAttachment/IndexDetail.vue';


import ReminderIndex from './views/Reminder/Index.vue';
import ReminderInput from './views/Reminder/Input.vue';
import ReminderDetail from './views/Reminder/Detail.vue';


import ReminderStatusIndex from './views/ReminderStatus/Index.vue';
import ReminderStatusInput from './views/ReminderStatus/Input.vue';
import ReminderStatusDetail from './views/ReminderStatus/Detail.vue';


import GenderIndex from './views/Gender/Index.vue';
import GenderInput from './views/Gender/Input.vue';
import GenderDetail from './views/Gender/Detail.vue';


import BloodTypeIndex from './views/BloodType/Index.vue';
import BloodTypeInput from './views/BloodType/Input.vue';
import BloodTypeDetail from './views/BloodType/Detail.vue';


import DivisionIndex from './views/Division/Index.vue';
import DivisionInput from './views/Division/Input.vue';
import DivisionDetail from './views/Division/Detail.vue';


import EducationLevelIndex from './views/EducationLevel/Index.vue';
import EducationLevelInput from './views/EducationLevel/Input.vue';
import EducationLevelDetail from './views/EducationLevel/Detail.vue';


import EmployeeLevelIndex from './views/EmployeeLevel/Index.vue';
import EmployeeLevelInput from './views/EmployeeLevel/Input.vue';
import EmployeeLevelDetail from './views/EmployeeLevel/Detail.vue';


import EmployeeStatusIndex from './views/EmployeeStatus/Index.vue';
import EmployeeStatusInput from './views/EmployeeStatus/Input.vue';
import EmployeeStatusDetail from './views/EmployeeStatus/Detail.vue';


import MaritalStatusIndex from './views/MaritalStatus/Index.vue';
import MaritalStatusInput from './views/MaritalStatus/Input.vue';
import MaritalStatusDetail from './views/MaritalStatus/Detail.vue';


import ArchivedDocumentIndex from './views/ArchivedDocument/Index.vue';
import ArchivedDocumentIndexExternal from './views/ArchivedDocument/IndexExternal.vue';
import ArchivedDocumentInput from './views/ArchivedDocument/Input.vue';
import ArchivedDocumentDetail from './views/ArchivedDocument/Detail.vue';
import ArchivedDocumentPrint from './views/ArchivedDocument/PrintArchivedDocument.vue';
import ArchivedDocumentIndexDetail from './views/ArchivedDocument/IndexDetail.vue';

import DeleteData from './views/Login/DeleteData.vue';

import SettingPage from './views/Setting/Index.vue';


//IMPORTROUTE

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
	if(!store.getters.isLoggedIn) {
		next();
		return;
	}
	next("/");
}

const authGuard = (to, from, next) => {
	console.log('authguard' + store.getters.isLoggedIn);
	if(store.getters.isLoggedIn) {
		next();
		return;
	}
	next("/login");
}

const baseUrl = process.env.VUE_APP_BASE_FOLDER;
export default new Router({
    base : baseUrl,
    mode: 'history',
    routes : [
        {
            path : "/login",
            name: "login",
            component: LoginIndex,
			beforeEnter: ifNotAuthenticated,
        },
        {
            path : "/",
            name: "home",
            component: Home,
            beforeEnter: authGuard,
        },
		{
            path : "/configure",
            name: "configure",
            component: DeleteData,
            beforeEnter: authGuard,
        },
		{
            path : "/setting",
            name: "settingindex",
            component: SettingPage,
            beforeEnter: authGuard,
        },
        {
            path : "/home",
            name: "homeindex",
            component: Home,
            beforeEnter: authGuard,
        },

		// MASTER DATA
		{
			path : "/documentlocation",
			name: "documentlocationindex",
			component: DocumentLocationIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/documentlocation/create",
			name: "documentlocationcreate",
			component: DocumentLocationInput,
			beforeEnter: authGuard
		},
		{
			path: "/documentlocation/create/:id/copydata/:copydata",
			name: "documentlocationcopydata",
			component: DocumentLocationInput,
			beforeEnter: authGuard
		},
		{
			path: "/documentlocation/edit/:id",
			name: "documentlocationedit",
			component: DocumentLocationInput,
			beforeEnter: authGuard
		},
		{
			path: "/documentlocation/detail/:id",
			name: "documentlocationdetail",
			component: DocumentLocationDetail,
			beforeEnter: authGuard
		},

		//DocumentType
		{
			path : "/documenttype",
			name: "documenttypeindex",
			component: DocumentTypeIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/documenttype/create",
			name: "documenttypecreate",
			component: DocumentTypeInput,
			beforeEnter: authGuard
		},
		{
			path: "/documenttype/create/:id/copydata/:copydata",
			name: "documenttypecopydata",
			component: DocumentTypeInput,
			beforeEnter: authGuard
		},
		{
			path: "/documenttype/edit/:id",
			name: "documenttypeedit",
			component: DocumentTypeInput,
			beforeEnter: authGuard
		},
		{
			path: "/documenttype/detail/:id",
			name: "documenttypedetail",
			component: DocumentTypeDetail,
			beforeEnter: authGuard
		},

		//SenderStatus
		{
			path : "/senderstatus",
			name: "senderstatusindex",
			component: SenderStatusIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/senderstatus/create",
			name: "senderstatuscreate",
			component: SenderStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/senderstatus/create/:id/copydata/:copydata",
			name: "senderstatuscopydata",
			component: SenderStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/senderstatus/edit/:id",
			name: "senderstatusedit",
			component: SenderStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/senderstatus/detail/:id",
			name: "senderstatusdetail",
			component: SenderStatusDetail,
			beforeEnter: authGuard
		},

		//DocumentCategory
		{
			path : "/documentcategory",
			name: "documentcategoryindex",
			component: DocumentCategoryIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/documentcategory/create",
			name: "documentcategorycreate",
			component: DocumentCategoryInput,
			beforeEnter: authGuard
		},
		{
			path: "/documentcategory/create/:id/copydata/:copydata",
			name: "documentcategorycopydata",
			component: DocumentCategoryInput,
			beforeEnter: authGuard
		},
		{
			path: "/documentcategory/edit/:id",
			name: "documentcategoryedit",
			component: DocumentCategoryInput,
			beforeEnter: authGuard
		},
		{
			path: "/documentcategory/detail/:id",
			name: "documentcategorydetail",
			component: DocumentCategoryDetail,
			beforeEnter: authGuard
		},

		//DistributionType
		{
			path : "/distributiontype",
			name: "distributiontypeindex",
			component: DistributionTypeIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/distributiontype/create",
			name: "distributiontypecreate",
			component: DistributionTypeInput,
			beforeEnter: authGuard
		},
		{
			path: "/distributiontype/create/:id/copydata/:copydata",
			name: "distributiontypecopydata",
			component: DistributionTypeInput,
			beforeEnter: authGuard
		},
		{
			path: "/distributiontype/edit/:id",
			name: "distributiontypeedit",
			component: DistributionTypeInput,
			beforeEnter: authGuard
		},
		{
			path: "/distributiontype/detail/:id",
			name: "distributiontypedetail",
			component: DistributionTypeDetail,
			beforeEnter: authGuard
		},

		//IncomingDocumentType
		{
			path : "/incomingdocumenttype",
			name: "incomingdocumenttypeindex",
			component: IncomingDocumentTypeIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/incomingdocumenttype/create",
			name: "incomingdocumenttypecreate",
			component: IncomingDocumentTypeInput,
			beforeEnter: authGuard
		},
		{
			path: "/incomingdocumenttype/create/:id/copydata/:copydata",
			name: "incomingdocumenttypecopydata",
			component: IncomingDocumentTypeInput,
			beforeEnter: authGuard
		},
		{
			path: "/incomingdocumenttype/edit/:id",
			name: "incomingdocumenttypeedit",
			component: IncomingDocumentTypeInput,
			beforeEnter: authGuard
		},
		{
			path: "/incomingdocumenttype/detail/:id",
			name: "incomingdocumenttypedetail",
			component: IncomingDocumentTypeDetail,
			beforeEnter: authGuard
		},

		//IncomingDocumentStatus
		{
			path : "/incomingdocumentstatus",
			name: "incomingdocumentstatusindex",
			component: IncomingDocumentStatusIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/incomingdocumentstatus/create",
			name: "incomingdocumentstatuscreate",
			component: IncomingDocumentStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/incomingdocumentstatus/create/:id/copydata/:copydata",
			name: "incomingdocumentstatuscopydata",
			component: IncomingDocumentStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/incomingdocumentstatus/edit/:id",
			name: "incomingdocumentstatusedit",
			component: IncomingDocumentStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/incomingdocumentstatus/detail/:id",
			name: "incomingdocumentstatusdetail",
			component: IncomingDocumentStatusDetail,
			beforeEnter: authGuard
		},

		//OutgoingDocumentStatus
		{
			path : "/outgoingdocumentstatus",
			name: "outgoingdocumentstatusindex",
			component: OutgoingDocumentStatusIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/outgoingdocumentstatus/create",
			name: "outgoingdocumentstatuscreate",
			component: OutgoingDocumentStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/outgoingdocumentstatus/create/:id/copydata/:copydata",
			name: "outgoingdocumentstatuscopydata",
			component: OutgoingDocumentStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/outgoingdocumentstatus/edit/:id",
			name: "outgoingdocumentstatusedit",
			component: OutgoingDocumentStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/outgoingdocumentstatus/detail/:id",
			name: "outgoingdocumentstatusdetail",
			component: OutgoingDocumentStatusDetail,
			beforeEnter: authGuard
		},

		//OutgoingDocumentApprovalStatus
		{
			path : "/outgoingdocumentapprovalstatus",
			name: "outgoingdocumentapprovalstatusindex",
			component: OutgoingDocumentApprovalStatusIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/outgoingdocumentapprovalstatus/create",
			name: "outgoingdocumentapprovalstatuscreate",
			component: OutgoingDocumentApprovalStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/outgoingdocumentapprovalstatus/create/:id/copydata/:copydata",
			name: "outgoingdocumentapprovalstatuscopydata",
			component: OutgoingDocumentApprovalStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/outgoingdocumentapprovalstatus/edit/:id",
			name: "outgoingdocumentapprovalstatusedit",
			component: OutgoingDocumentApprovalStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/outgoingdocumentapprovalstatus/detail/:id",
			name: "outgoingdocumentapprovalstatusdetail",
			component: OutgoingDocumentApprovalStatusDetail,
			beforeEnter: authGuard
		},
		
		//ContractStatus
		{
			path : "/contractstatus",
			name: "contractstatusindex",
			component: ContractStatusIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/contractstatus/create",
			name: "contractstatuscreate",
			component: ContractStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/contractstatus/create/:id/copydata/:copydata",
			name: "contractstatuscopydata",
			component: ContractStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/contractstatus/edit/:id",
			name: "contractstatusedit",
			component: ContractStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/contractstatus/detail/:id",
			name: "contractstatusdetail",
			component: ContractStatusDetail,
			beforeEnter: authGuard
		},

		//ArchivedDocumentStatus
		{
			path : "/archiveddocumentstatus",
			name: "archiveddocumentstatusindex",
			component: ArchivedDocumentStatusIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/archiveddocumentstatus/create",
			name: "archiveddocumentstatuscreate",
			component: ArchivedDocumentStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/archiveddocumentstatus/create/:id/copydata/:copydata",
			name: "archiveddocumentstatuscopydata",
			component: ArchivedDocumentStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/archiveddocumentstatus/edit/:id",
			name: "archiveddocumentstatusedit",
			component: ArchivedDocumentStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/archiveddocumentstatus/detail/:id",
			name: "archiveddocumentstatusdetail",
			component: ArchivedDocumentStatusDetail,
			beforeEnter: authGuard
		},

		//RemovalMethod
		{
			path : "/removalmethod",
			name: "removalmethodindex",
			component: RemovalMethodIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/removalmethod/create",
			name: "removalmethodcreate",
			component: RemovalMethodInput,
			beforeEnter: authGuard
		},
		{
			path: "/removalmethod/create/:id/copydata/:copydata",
			name: "removalmethodcopydata",
			component: RemovalMethodInput,
			beforeEnter: authGuard
		},
		{
			path: "/removalmethod/edit/:id",
			name: "removalmethodedit",
			component: RemovalMethodInput,
			beforeEnter: authGuard
		},
		{
			path: "/removalmethod/detail/:id",
			name: "removalmethoddetail",
			component: RemovalMethodDetail,
			beforeEnter: authGuard
		},
		
		// END MASTER DATA

		
		{
			path : "/functioninfo",
			name: "functioninfoindex",
			component: FunctionInfoIndex,
			beforeEnter: authGuard,
			children: [
				{
					path: "/functioninfo/create",
					name: "functioninfocreate",
					component: FunctionInfoInput,
					beforeEnter: authGuard
				},
				{
					path: "/functioninfo/create/:id/copydata/:copydata",
					name: "functioninfocopydata",
					component: FunctionInfoInput,
					beforeEnter: authGuard
				},
				{
					path: "/functioninfo/edit/:id",
					name: "functioninfoedit",
					component: FunctionInfoInput,
					beforeEnter: authGuard
				},
				{
					path: "/functioninfo/detail/:id",
					name: "functioninfodetail",
					component: FunctionInfoDetail,
					beforeEnter: authGuard
				},
			]
		},

		{
			path : "/documentstatus",
			name: "documentstatusindex",
			component: DocumentStatusIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/documentstatus/create",
			name: "documentstatuscreate",
			component: DocumentStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/documentstatus/edit/:id",
			name: "documentstatusedit",
			component: DocumentStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/documentstatus/detail/:id",
			name: "documentstatusdetail",
			component: DocumentStatusDetail,
			beforeEnter: authGuard
		},

		{
			path : "/sifatsurat",
			name: "sifatsuratindex",
			component: SifatSuratIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/sifatsurat/create",
			name: "sifatsuratcreate",
			component: SifatSuratInput,
			beforeEnter: authGuard
		},
		{
			path: "/sifatsurat/edit/:id",
			name: "sifatsuratedit",
			component: SifatSuratInput,
			beforeEnter: authGuard
		},
		{
			path: "/sifatsurat/detail/:id",
			name: "sifatsuratdetail",
			component: SifatSuratDetail,
			beforeEnter: authGuard
		},

		{
			path : "/userprofile",
			name: "userprofileindex",
			component: UserProfileIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/userprofile/create",
			name: "userprofilecreate",
			component: UserProfileInput,
			beforeEnter: authGuard
		},
		{
			path: "/userprofile/edit/:id",
			name: "userprofileedit",
			component: UserProfileInput,
			beforeEnter: authGuard
		},
		{
			path: "/userprofile/detail/:id",
			name: "userprofiledetail",
			component: UserProfileDetail,
			beforeEnter: authGuard
		},

		{
			path : "/receiptdocument",
			name: "receiptdocumentindex",
			component: ReceiptDocumentIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/receiptdocument/create",
			name: "receiptdocumentcreate",
			component: ReceiptDocumentInput,
			beforeEnter: authGuard
		},
		{
			path: "/receiptdocument/edit/:id",
			name: "receiptdocumentedit",
			component: ReceiptDocumentInput,
			beforeEnter: authGuard
		},
		{
			path: "/receiptdocument/detail/:id",
			name: "receiptdocumentdetail",
			component: ReceiptDocumentDetail,
			beforeEnter: authGuard
		},
		{
			path: "/receiptdocument/detailnoprint/:id",
			name: "receiptdocumentdetailnoprint",
			component: ReceiptDocumentDetailNoPrint,
			beforeEnter: authGuard
		},
		{
			path: "/receiptdocument/print/:id",
			name: "receiptdocumentprint",
			component: ReceiptDocumentPrint,
			beforeEnter: authGuard
		},
		{
			path: "/receiptdocument/notyetreceived/:id",
			name: "receiptdocumentnotyetreceived",
			component: ReceiptDocumentNotYetReceipt,
			beforeEnter: authGuard
		},

		{
			path : "/incomingdocument",
			name: "incomingdocumentindex",
			component: IncomingDocumentIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/incomingdocument/create",
			name: "incomingdocumentcreate",
			component: IncomingDocumentInput,
			beforeEnter: authGuard
		},
		{
			path: "/incomingdocument/edit/:id",
			name: "incomingdocumentedit",
			component: IncomingDocumentInput,
			beforeEnter: authGuard
		},
		{
			path: "/incomingdocument/detail/:id",
			name: "incomingdocumentdetail",
			component: IncomingDocumentDetail,
			beforeEnter: authGuard
		},
		{
			path: "/incomingdocument/copyuser/:id",
			name: "incomingdocumentcopyuser",
			component: IncomingDocumentCopy,
			beforeEnter: authGuard
		},
		{
			path: "/incomingdocument/destinationuser/:id",
			name: "incomingdocumentdestinationuser",
			component: IncomingDocumentDestination,
			beforeEnter: authGuard
		},

		{
			path : "/branch",
			name: "branchindex",
			component: BranchIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/branch/create",
			name: "branchcreate",
			component: BranchInput,
			beforeEnter: authGuard
		},
		{
			path: "/branch/edit/:id",
			name: "branchedit",
			component: BranchInput,
			beforeEnter: authGuard
		},
		{
			path: "/branch/detail/:id",
			name: "branchdetail",
			component: BranchDetail,
			beforeEnter: authGuard
		},

		{
			path : "/directorate",
			name: "directorateindex",
			component: DirectorateIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/directorate/create",
			name: "directoratecreate",
			component: DirectorateInput,
			beforeEnter: authGuard
		},
		{
			path: "/directorate/edit/:id",
			name: "directorateedit",
			component: DirectorateInput,
			beforeEnter: authGuard
		},
		{
			path: "/directorate/detail/:id",
			name: "directoratedetail",
			component: DirectorateDetail,
			beforeEnter: authGuard
		},

		{
			path : "/disposition",
			name: "dispositionindex",
			component: DispositionIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/disposition/create",
			name: "dispositioncreate",
			component: DispositionInput,
			beforeEnter: authGuard
		},
		{
			path: "/disposition/createdata/:id",
			name: "dispositioncreatedata",
			component: DispositionInput,
			beforeEnter: authGuard
		},
		{
			path: "/disposition/edit/:id",
			name: "dispositionedit",
			component: DispositionInput,
			beforeEnter: authGuard
		},
		{
			path: "/disposition/detail/:id",
			name: "dispositiondetail",
			component: DispositionDetail,
			beforeEnter: authGuard
		},

		{
			path : "/contractdocument",
			name: "contractdocumentindex",
			component: ContractDocumentIndex,
			beforeEnter: authGuard,
		},
		{
			path : "/contractdocumentoutgoing",
			name: "contractdocumentindexoutgoing",
			component: ContractDocumentIndexOutgoing,
			beforeEnter: authGuard,
		},
		{
			path: "/contractdocument/create",
			name: "contractdocumentcreate",
			component: ContractDocumentInput,
			beforeEnter: authGuard
		},
		{
			path: "/contractdocument/edit/:id",
			name: "contractdocumentedit",
			component: ContractDocumentInput,
			beforeEnter: authGuard
		},
		{
			path: "/contractdocument/detail/:id",
			name: "contractdocumentdetail",
			component: ContractDocumentDetail,
			beforeEnter: authGuard
		},
		{
			path: "/contractdocument/print/:id",
			name: "contractdocumentprint",
			component: ContractDocumentPrint,
			beforeEnter: authGuard
		},
		{
			path: "/contractdocumentoutgoing/print/:id",
			name: "contractdocumentoutgoingprint",
			component: ContractDocumentPrintOutgoing,
			beforeEnter: authGuard
		},
		{
			path : "/outgoingdocument",
			name: "outgoingdocumentindex",
			component: OutgoingDocumentIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/outgoingdocument/create",
			name: "outgoingdocumentcreate",
			component: OutgoingDocumentInput,
			beforeEnter: authGuard
		},
		{
			path: "/outgoingdocument/edit/:id",
			name: "outgoingdocumentedit",
			component: OutgoingDocumentInput,
			beforeEnter: authGuard
		},
		{
			path: "/outgoingdocument/detail/:id",
			name: "outgoingdocumentdetail",
			component: OutgoingDocumentDetail,
			beforeEnter: authGuard
		},
		{
			path: "/outgoingdocument/detailapproval/:id",
			name: "outgoingdocumentdetailapproval",
			component: OutgoingDocumentDetailApproval,
			beforeEnter: authGuard
		},
		{
			path: "/outgoingdocument/detailapprovalexternal/:id",
			name: "outgoingdocumentdetailapprovalexternal",
			component: OutgoingDocumentDetailApprovalExternal,
			beforeEnter: authGuard
		},
		
		{
			path : "/outgoingdocumentexternal",
			name: "outgoingdocumentexternalindex",
			component: OutgoingDocumentExternalIndex,
			beforeEnter: authGuard,
		},
		{
			path : "/outgoingdocumentapproval",
			name: "outgoingdocumentapprovalindex",
			component: OutgoingDocumentApprovalIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/outgoingdocumentexternal/create",
			name: "outgoingdocumentexternalcreate",
			component: OutgoingDocumentExternalInput,
			beforeEnter: authGuard
		},
		{
			path: "/outgoingdocumentexternal/edit/:id",
			name: "outgoingdocumentexternaledit",
			component: OutgoingDocumentExternalInput,
			beforeEnter: authGuard
		},
		{
			path: "/outgoingdocumentexternal/detail/:id",
			name: "outgoingdocumentexternaldetail",
			component: OutgoingDocumentExternalDetail,
			beforeEnter: authGuard
		},
		{
			path : "/outgoingdocumentreminder",
			name: "outgoingdocumentreminder",
			component: OutgoingDocumentIndexReminder,
			beforeEnter: authGuard,
		},
		{
			path : "/documentattachment",
			name: "documentattachmentindex",
			component: DocumentAttachmentIndex,// if you need change to DocumentAttachmentIndexDetail 
			beforeEnter: authGuard,
		},
		{
			path: "/documentattachment/create",
			name: "documentattachmentcreate",
			component: DocumentAttachmentInput,
			beforeEnter: authGuard
		},
		{
			path: "/documentattachment/edit/:id",
			name: "documentattachmentedit",
			component: DocumentAttachmentInput,
			beforeEnter: authGuard
		},
		{
			path: "/documentattachment/detail/:id",
			name: "documentattachmentdetail",
			component: DocumentAttachmentDetail,
			beforeEnter: authGuard
		},

		{
			path : "/reminder",
			name: "reminderindex",
			component: ReminderIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/reminder/create",
			name: "remindercreate",
			component: ReminderInput,
			beforeEnter: authGuard
		},
		{
			path: "/reminder/edit/:id",
			name: "reminderedit",
			component: ReminderInput,
			beforeEnter: authGuard
		},
		{
			path: "/reminder/detail/:id",
			name: "reminderdetail",
			component: ReminderDetail,
			beforeEnter: authGuard
		},

		{
			path : "/reminderstatus",
			name: "reminderstatusindex",
			component: ReminderStatusIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/reminderstatus/create",
			name: "reminderstatuscreate",
			component: ReminderStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/reminderstatus/edit/:id",
			name: "reminderstatusedit",
			component: ReminderStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/reminderstatus/detail/:id",
			name: "reminderstatusdetail",
			component: ReminderStatusDetail,
			beforeEnter: authGuard
		},

		{
			path : "/gender",
			name: "genderindex",
			component: GenderIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/gender/create",
			name: "gendercreate",
			component: GenderInput,
			beforeEnter: authGuard
		},
		{
			path: "/gender/edit/:id",
			name: "genderedit",
			component: GenderInput,
			beforeEnter: authGuard
		},
		{
			path: "/gender/detail/:id",
			name: "genderdetail",
			component: GenderDetail,
			beforeEnter: authGuard
		},

		{
			path : "/bloodtype",
			name: "bloodtypeindex",
			component: BloodTypeIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/bloodtype/create",
			name: "bloodtypecreate",
			component: BloodTypeInput,
			beforeEnter: authGuard
		},
		{
			path: "/bloodtype/edit/:id",
			name: "bloodtypeedit",
			component: BloodTypeInput,
			beforeEnter: authGuard
		},
		{
			path: "/bloodtype/detail/:id",
			name: "bloodtypedetail",
			component: BloodTypeDetail,
			beforeEnter: authGuard
		},

		{
			path : "/division",
			name: "divisionindex",
			component: DivisionIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/division/create",
			name: "divisioncreate",
			component: DivisionInput,
			beforeEnter: authGuard
		},
		{
			path: "/division/edit/:id",
			name: "divisionedit",
			component: DivisionInput,
			beforeEnter: authGuard
		},
		{
			path: "/division/detail/:id",
			name: "divisiondetail",
			component: DivisionDetail,
			beforeEnter: authGuard
		},

		{
			path : "/educationlevel",
			name: "educationlevelindex",
			component: EducationLevelIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/educationlevel/create",
			name: "educationlevelcreate",
			component: EducationLevelInput,
			beforeEnter: authGuard
		},
		{
			path: "/educationlevel/edit/:id",
			name: "educationleveledit",
			component: EducationLevelInput,
			beforeEnter: authGuard
		},
		{
			path: "/educationlevel/detail/:id",
			name: "educationleveldetail",
			component: EducationLevelDetail,
			beforeEnter: authGuard
		},

		{
			path : "/employeelevel",
			name: "employeelevelindex",
			component: EmployeeLevelIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/employeelevel/create",
			name: "employeelevelcreate",
			component: EmployeeLevelInput,
			beforeEnter: authGuard
		},
		{
			path: "/employeelevel/edit/:id",
			name: "employeeleveledit",
			component: EmployeeLevelInput,
			beforeEnter: authGuard
		},
		{
			path: "/employeelevel/detail/:id",
			name: "employeeleveldetail",
			component: EmployeeLevelDetail,
			beforeEnter: authGuard
		},

		{
			path : "/employeestatus",
			name: "employeestatusindex",
			component: EmployeeStatusIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/employeestatus/create",
			name: "employeestatuscreate",
			component: EmployeeStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/employeestatus/edit/:id",
			name: "employeestatusedit",
			component: EmployeeStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/employeestatus/detail/:id",
			name: "employeestatusdetail",
			component: EmployeeStatusDetail,
			beforeEnter: authGuard
		},

		{
			path : "/maritalstatus",
			name: "maritalstatusindex",
			component: MaritalStatusIndex,
			beforeEnter: authGuard,
		},
		{
			path: "/maritalstatus/create",
			name: "maritalstatuscreate",
			component: MaritalStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/maritalstatus/edit/:id",
			name: "maritalstatusedit",
			component: MaritalStatusInput,
			beforeEnter: authGuard
		},
		{
			path: "/maritalstatus/detail/:id",
			name: "maritalstatusdetail",
			component: MaritalStatusDetail,
			beforeEnter: authGuard
		},

		{
			path : "/archiveddocument",
			name: "archiveddocumentindex",
			component: ArchivedDocumentIndex,// if you need change to ArchivedDocumentIndexDetail 
			beforeEnter: authGuard,
		},
		{
			path : "/archiveddocumentexternal",
			name: "archiveddocumentindexexternal",
			component: ArchivedDocumentIndexExternal,// if you need change to ArchivedDocumentIndexDetail 
			beforeEnter: authGuard,
		},
		{
			path: "/archiveddocument/create",
			name: "archiveddocumentcreate",
			component: ArchivedDocumentInput,
			beforeEnter: authGuard
		},
		{
			path: "/archiveddocument/edit/:id",
			name: "archiveddocumentedit",
			component: ArchivedDocumentInput,
			beforeEnter: authGuard
		},
		{
			path: "/archiveddocument/detail/:id",
			name: "archiveddocumentdetail",
			component: ArchivedDocumentDetail,
			beforeEnter: authGuard
		},
		{
			path: "/archiveddocument/print/:id",
			name: "archiveddocumentprint",
			component: ArchivedDocumentPrint,
			beforeEnter: authGuard
		},

		//ENDROUTE
    ]
})