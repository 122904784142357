<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true id="popup-upload" @ok="uploadButtonAction" :okTitle="okTitle" title="Upload Data">
		<b-card>
			<b-row>
				<b-col cols="12">
					<app-input-fileupload label="File" size="sm" :model.sync="fileupload" />
				</b-col>
			</b-row>
			<b-row v-if="isUploadSuccess">
				<b-col cols="12">
					<b-card no-body>
						<b-tabs card>
							<b-tab title="Outgoing Document" active>
								<b-row class="mb-2">
									<b-col>
										<span class="float-right">
											<b-form-select v-model="outgoingDocumentPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
										</span>
									</b-col>
								</b-row>
								<b-row>
								<b-col>
								<div class="table-corner">
									<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="outgoingDocuments" :fields="fieldsOutgoingDocument" :busy="isDataLoading" :sort-by.sync="sortByOutgoingDocument" :sort-desc.sync="sortDescOutgoingDocument" small responsive>
									<template v-slot:head(actions)>
										<b-button @click="isShowOutgoingDocumentFilter = !isShowOutgoingDocumentFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
									</template>
									<template #top-row v-if="isShowOutgoingDocumentFilter">
										<b-th></b-th>
										<b-th stacked-heading="Kode Risalah">
											<b-form-input id="input-document-code-table" v-model="filterOutgoingDocument.documentCode" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Lokasi Dokumen">
											<v-select append-to-body label="name" :options="documentLocations" :value="filterOutgoingDocument.documentLocationId"  :reduce="item => item.id" v-model="filterOutgoingDocument.documentLocationId" @search="getDocumentLocations" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Applicant">
											<v-select append-to-body label="fullName" :options="userProfiles" :value="filterOutgoingDocument.applicantId"  :reduce="item => item.id" v-model="filterOutgoingDocument.applicantId" @search="getUserProfiles" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Ditujukan Kepada">
											<b-form-input id="input-destination-user-table" v-model="filterOutgoingDocument.destinationUser" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Status Tujuan">
											<v-select append-to-body label="name" :options="senderStatuss" :value="filterOutgoingDocument.destinationStatusId"  :reduce="item => item.id" v-model="filterOutgoingDocument.destinationStatusId" @search="getSenderStatuss" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Contact Person (CP)">
											<b-form-input id="input-destination-contact-person-table" v-model="filterOutgoingDocument.destinationContactPerson" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Alamat Kirim">
											<b-form-input id="input-destination-address-table" v-model="filterOutgoingDocument.destinationAddress" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Tanggal Surat">
											<b-form-datepicker boundary="viewport" placeholder="" id="input-document-date-from-table" v-model="filterOutgoingDocument.documentDateFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
											<b-form-datepicker boundary="viewport" placeholder="" id="input-document-date-to-table" v-model="filterOutgoingDocument.documentDateTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
										</b-th>
										<b-th stacked-heading="Perihal/Ringkasan Surat">
											<b-form-input id="input-description-table" v-model="filterOutgoingDocument.description" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Masa Berlaku Kontrak">
											<b-form-datepicker boundary="viewport" placeholder="" id="input-contract-date-from-table" v-model="filterOutgoingDocument.contractDateFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
											<b-form-datepicker boundary="viewport" placeholder="" id="input-contract-date-to-table" v-model="filterOutgoingDocument.contractDateTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
										</b-th>
										<b-th stacked-heading="Catatan Atas Kontrak">
											<b-form-input id="input-contract-description-table" v-model="filterOutgoingDocument.contractDescription" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Draft">
											<b-form-checkbox id="input-is-draft-table" @change="getOutgoingDocuments" class="float-right" v-model="filterOutgoingDocument.isDraft"></b-form-checkbox>
										</b-th>
										<b-th stacked-heading="Lampiran">
											<b-form-checkbox id="input-is-lampiran-table" @change="getOutgoingDocuments" class="float-right" v-model="filterOutgoingDocument.isLampiran"></b-form-checkbox>
										</b-th>
										<b-th>
										</b-th>
										<b-th>
										</b-th>
										<b-th stacked-heading="Catatan">
											<b-form-input id="input-remarks-table" v-model="filterOutgoingDocument.remarks" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Status">
											<v-select append-to-body label="name" :options="outgoingDocumentStatuss" :value="filterOutgoingDocument.outgoingDocumentStatusId"  :reduce="item => item.id" v-model="filterOutgoingDocument.outgoingDocumentStatusId" @search="getOutgoingDocumentStatuss" :filterable="true"></v-select>
										</b-th>
										<b-th>
										</b-th>
									</template>
									<template v-slot:table-busys>
										<div class="text-center my-2">
									<b-spinner class="align-middle text-primary"></b-spinner>
										<strong class="ml-2">Loading..</strong>
										</div>
									</template>
									<template v-slot:cell(no)="row">
										{{ ((row.index + 1) + ( outgoingDocumentPageSize * (currentOutgoingDocumentPage - 1))) }}
									</template>
									<template v-slot:cell(documentLocation)="row">
										{{ row.item.documentLocation != null ? row.item.documentLocation.name : "" }}
									</template>
									<template v-slot:cell(applicant)="row">
										{{ row.item.applicant != null ? row.item.applicant.fullName : "" }}
									</template>
									<template v-slot:cell(destinationStatus)="row">
										{{ row.item.destinationStatus != null ? row.item.destinationStatus.name : "" }}
									</template>
									<template v-slot:cell(documentDate)="row">
										{{ row.item.documentDate | moment("DD-MMM-YYYY")  }}
									</template>
									<template v-slot:cell(contractDate)="row">
										{{ row.item.contractDate | moment("DD-MMM-YYYY")  }}
									</template>
									<template v-slot:cell(draftAttachment)="row">
										{{ row.item.draftAttachment != null ? row.item.draftAttachment.originalFileName : "" }}
									</template>
									<template v-slot:cell(lampiranAttachment)="row">
										{{ row.item.lampiranAttachment != null ? row.item.lampiranAttachment.originalFileName : "" }}
									</template>
									<template v-slot:cell(outgoingDocumentStatus)="row">
										{{ row.item.outgoingDocumentStatus != null ? row.item.outgoingDocumentStatus.name : "" }}
									</template>
									</b-table>
									</div>
								</b-col>
								<b-col cols="12">
									<b-pagination
									class="float-right btn-paging"
									v-model="currentOutgoingDocumentPage"
									:total-rows="totalOutgoingDocumentRow"
									:per-page="outgoingDocumentPageSize"
									aria-controls="my-table"
									></b-pagination>
								</b-col>
								</b-row>
							</b-tab>
						</b-tabs>
					</b-card>
				</b-col>
			</b-row>
		</b-card>
		<template #modal-footer="{ ok, cancel }">
			<b-button v-if="isUploadSuccess" size="sm" variant="primary" @click="downloadLog()">
				Download Log
			</b-button>
			<b-button size="sm" variant="success" class="float-right" @click="ok()">
				{{ okTitle }}
			</b-button>
			<b-button size="sm" variant="secondary" class="float-right" @click="cancel()">
				Cancel
			</b-button>
		</template>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<style lang="scss" scoped>
.card {
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
:deep .th-message {
	color: red;
}
:deep .modal-footer {
	display: block;
}
</style>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate"; 
import { required, minLength } from "vuelidate/lib/validators"; 
import { saveAs } from 'file-saver';

import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import OutgoingDocumentList from '@/models/OutgoingDocument/OutgoingDocumentList';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';

import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import SenderStatus from '@/models/SenderStatus/SenderStatus';
import SenderStatusFilter from '@/filters/SenderStatus/SenderStatusFilter';
import Attachment from '@/models/Core/Attachment';
import AttachmentFilter from '@/filters/Core/AttachmentFilter';
import OutgoingDocumentStatus from '@/models/OutgoingDocumentStatus/OutgoingDocumentStatus';
import OutgoingDocumentStatusFilter from '@/filters/OutgoingDocumentStatus/OutgoingDocumentStatusFilter';
import AppInputFileupload from '@/components/AppInputFileupload';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
export default {
	props: ['model', 'openPopup','uploadButtonMethod', 'parentId'],
	components : {AppInputFileupload,AppInputDatetimepicker,AppInputDatetimepickerRange,},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fieldsOutgoingDocument: [
				{"no" : { "class" : "th-number"}}, 
				{"documentCode": { "class" : "th-document-code", "label" : "Kode Risalah"}, key: 'documentCode', sortable: true}, 
				{"documentLocation": { "class" : "th-document-location", "label" : "Lokasi Dokumen"}, key: 'documentLocation', sortable: true}, 
				{"applicant": { "class" : "th-applicant", "label" : "Applicant"}, key: 'applicant', sortable: true}, 
				{"destinationUser": { "class" : "th-destination-user", "label" : "Ditujukan Kepada"}, key: 'destinationUser', sortable: true}, 
				{"destinationStatus": { "class" : "th-destination-status", "label" : "Status Tujuan"}, key: 'destinationStatus', sortable: true}, 
				{"destinationContactPerson": { "class" : "th-destination-contact-person", "label" : "Contact Person (CP)"}, key: 'destinationContactPerson', sortable: true}, 
				{"destinationAddress": { "class" : "th-destination-address", "label" : "Alamat Kirim"}, key: 'destinationAddress', sortable: true}, 
				{"documentDate": { "class" : "th-document-date", "label" : "Tanggal Surat"}, key: 'documentDate', sortable: true}, 
				{"description": { "class" : "th-description", "label" : "Perihal/Ringkasan Surat"}, key: 'description', sortable: true}, 
				{"contractDate": { "class" : "th-contract-date", "label" : "Masa Berlaku Kontrak"}, key: 'contractDate', sortable: true}, 
				{"contractDescription": { "class" : "th-contract-description", "label" : "Catatan Atas Kontrak"}, key: 'contractDescription', sortable: true}, 
				{"isDraft": { "class" : "th-is-draft", "label" : "Draft"}, key: 'isDraft', sortable: true}, 
				{"isLampiran": { "class" : "th-is-lampiran", "label" : "Lampiran"}, key: 'isLampiran', sortable: true}, 
				{"draftAttachment": { "class" : "th-draft-attachment", "label" : "Draft"}, key: 'draftAttachment', sortable: true}, 
				{"lampiranAttachment": { "class" : "th-lampiran-attachment", "label" : "Lampiran"}, key: 'lampiranAttachment', sortable: true}, 
				{"remarks": { "class" : "th-remarks", "label" : "Catatan"}, key: 'remarks', sortable: true}, 
				{"outgoingDocumentStatus": { "class" : "th-outgoing-document-status", "label" : "Status"}, key: 'outgoingDocumentStatus', sortable: true}, 
				{"class" : "th-status", "label" : "Status", key: 'uploadValidationStatus', sortable: true},
				{"class" : "th-message", "label" : "Message", key: 'uploadValidationMessage', sortable: true},
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isDataLoading: false,
			isBusy : false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			outgoingDocuments : [],
			outgoingDocumentId : '',
			totalOutgoingDocumentRow : 0,
			isShowOutgoingDocumentFilter: false,
			filterOutgoingDocument : null,
			currentOutgoingDocumentPage : 1,
			outgoingDocumentPageSize: 10,
			sortByOutgoingDocument: '',
			sortDescOutgoingDocument: '',
			documentLocations: [],
			userProfiles: [],
			senderStatuss: [],
			outgoingDocumentStatuss: [],
			processId: "",
			isUploadSuccess: false,
		}
	},
	methods : {
		resetModal() {
			this.datas = Array();
			this.$emit('resetMethod');
		},
		uploadButtonAction(modalEvent) {
			modalEvent.preventDefault();
			this.isBusy = true;
			if (this.isUploadSuccess) {
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'outgoingdocument/confirmUpload',
					[],
					).then(async function(response){
						if (response != null) {
						if (response.data != null) {
							_this.resetModal();
							_this.datas = Array();
							_this.fileupload = null;
							_this.isUploadSuccess = false;
						}
						}
						_this.isBusy = false;
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
			} else {
				if (this.fileupload != null) {
				let data = new FormData();
				data.append('file', this.fileupload);
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'outgoingdocument/upload',
					data,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
					).then(async function(response){
						_this.isBusy = false;
						_this.isUploadSuccess = true;
						_this.getOutgoingDocuments();
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
				}
			}
		},
		getOutgoingDocuments : async function() {
			let filter = {};
			Object.assign(filter, this.filterOutgoingDocument);
			filter.draftFromUpload = "1";
			filter.draftMode = "1";
			this.sortingByOutgoingDocument = Array();
			if (this.sortByOutgoingDocument != null) {
				this.sortingByOutgoingDocument[this.sortByOutgoingDocument] = this.sortDescOutgoingDocument;
			}
			let currentPage = (this.currentOutgoingDocumentPage - 1);
			OutgoingDocument.getList(this, filter, this.sortingByOutgoingDocument, currentPage , this.outgoingDocumentPageSize).then(result => {
				this.outgoingDocuments = result.data;
				this.totalOutgoingDocumentRow = result.dataCount;
			}).catch(error => {});
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentLocation.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getSenderStatuss : async function(input) {
			let filter = new SenderStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			SenderStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.senderStatuss = result.data
				}
			})
			.catch(error => {});
		},
		getOutgoingDocumentStatuss : async function(input) {
			let filter = new OutgoingDocumentStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			OutgoingDocumentStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.outgoingDocumentStatuss = result.data
				}
			})
			.catch(error => {});
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		downloadLog() {
			let _this = this;
			this.$http.post(process.env.VUE_APP_API_URL + 'outgoingdocument/downloadlog', [],
			{ headers: { 'Content-Type': 'application/json' }}
			).then(async function(response){
				await saveAs(process.env.VUE_APP_API_URL + 'outgoingdocument/downloadlog', "OutgoingDocument.xslx");
				_this.isBusy = false;
			})
			.catch(error => {
				_this.$store.dispatch('addErrorMessageGlobal', error);
				_this.isBusy = false;
			});
		}
	},
	beforeMount() {
	},
	mounted(){
	},
	watch: {
		sortByOutgoingDocument : {
			handler: function() {
				this.getOutgoingDocuments();
			}
		},
		sortDescOutgoingDocument: {
			handler: function() {
				this.getOutgoingDocuments();
			}
		},
		currentOutgoingDocumentPage: {
			handler: function() {
				this.getOutgoingDocuments();
			}
		},
		outgoingDocumentPageSize : {
			handler: function() {
				this.getOutgoingDocuments();
			}
		},
		filterOutgoingDocument: {
			handler: function() {
				this.getOutgoingDocuments();
			},
			deep: true,
		},
		fileupload(newValue, oldValue) {
			this.datas = Array();
			this.isUploadSuccess = false;
		}
	},
	computed: {
		okTitle: {
			get: function() {
				if (this.isUploadSuccess) {
					return "Proses";
				}
				return "Simpan";
				}
		},
		isShowPopup : {
			get: function () {
				if (this.openPopup) {
					this.filterOutgoingDocument = new OutgoingDocumentFilter();
					return true;
				}
				else return false;
			},
			set: function (newValue) {}
		},
	}
}
</script>
