<template>
    <div class="p-5">
        <b-overlay
            :show="isDataLoading"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
        >
            <b-button variant="secondary" @click="getContractDocuments">Clear Doc</b-button>
            <b-button variant="secondary" @click="getArchivedDocuments">Archived Doc</b-button>
        </b-overlay>
        <b-card class="mt-3">
            <div v-for="(item, index) in messages" class="mb-3 border-bottom-1" :key="index">
                {{ item.value.id }} | {{ item.value.documentCode }}
            </div>
        </b-card>
    </div>
</template>
<script>
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import ContractDocument from '@/models/ContractDocument/ContractDocument';
import ArchivedDocument from '@/models/ArchivedDocument/ArchivedDocument';

export default {
    mounted() {
    },
    data() {
        return {
            isDataLoading : false,
            messages: [],
        };
        
    },
    methods : {
        getIncomingDocuments : async function() {
            this.isDataLoading = false;
			let sortingBy = Array();
			let filter = {};
            sortingBy["createdAt"] = true;
            IncomingDocument.getList(this, filter, sortingBy, 1, 1, '')
			.then(result_ => {
                if (result_.pagination != null) {
                    IncomingDocument.getList(this, filter, sortingBy, 1, result_.pagination.total, '')
                    .then(result => {
                        if (result != null) {
                            let incomingDocuments = result.data;
                            incomingDocuments.forEach(item => {
                                let date = Date.parse(item.createdAt);
                                let createdDate = new Date(item.createdAt);
                                if (createdDate != undefined && createdDate != null) {
                                    if (createdDate.getFullYear() < 2023) {
                                        this.messages.push({name : "outgoing" + item.id , value : item});
                                        IncomingDocument.deleteData(this, item.id).then(result => {
                                            console.log(item.id + " | deleted");
                                        })
                                    }
                                }
                            });
                        }
                        this.isDataLoading = false;
                    })
                    .catch(error => {
                        this.isDataLoading = false;
                    });
                }
            }).catch(error => {
				
			});
		},
        getOutgoingDocuments : async function() {
            this.isDataLoading = false;
			let sortingBy = Array();
			let filter = {};
            sortingBy["createdAt"] = true;
            OutgoingDocument.getList(this, filter, sortingBy, 1, 1, '')
			.then(result_ => {
                if (result_.pagination != null) {
                    OutgoingDocument.getList(this, filter, sortingBy, 1, result_.pagination.total, '')
                    .then(result => {
                        if (result != null) {
                            let outgoingDocuments = result.data;
                            outgoingDocuments.forEach(item => {
                                let date = Date.parse(item.createdAt);
                                let createdDate = new Date(item.createdAt);
                                if (createdDate != undefined && createdDate != null) {
                                    if (createdDate.getFullYear() <= 2023) {
                                        this.messages.push({name : "outgoing" + item.id , value : item});
                                        OutgoingDocument.deleteData(this, item.id).then(result => {
                                            console.log(item.id + " | deleted");
                                        })
                                    }
                                }
                            });
                        }
                        this.isDataLoading = false;
                    })
                    .catch(error => {
                        this.isDataLoading = false;
                    });
                }
            }).catch(error => {
				
			});
		},
        getContractDocuments : async function() {
            this.isDataLoading = false;
			let sortingBy = Array();
			let filter = {};
            this.messages = [];
            sortingBy["created_at"] = true;
            ContractDocument.getList(this, filter, sortingBy, 1, 1, '')
			.then(result_ => {
                if (result_.pagination != null) {
                    ContractDocument.getList(this, filter, sortingBy, 1, result_.pagination.total, '')
                    .then(result => {
                        if (result != null) {
                            let contractDocuments = result.data;
                            contractDocuments.forEach(item => {
                                let date = Date.parse(item.contractDateFrom);
                                let createdDate = new Date(item.contractDateFrom);
                                if (createdDate != undefined && createdDate != null) {
                                    if (createdDate.getFullYear() >= 2023) {
                                        this.messages.push({name : "outgoing" + item.id , value : item});
                                        ContractDocument.deleteData(this, item.id).then(result => {
                                            console.log(item.id + " | deleted");
                                        })
                                    }
                                }
                            });
                        }
                        this.isDataLoading = false;
                    })
                    .catch(error => {
                        this.isDataLoading = false;
                    });
                }
            }).catch(error => {
				
			});	
		},
        getArchivedDocuments : async function() {
            this.isDataLoading = false;
			let sortingBy = Array();
			let filter = {};
            //filter["dataType"] = "OUTGOING";
			//filter.dataType = "OUTGOING";
            sortingBy["createdAt"] = true;
            ArchivedDocument.getList(this, filter, sortingBy, 1, 1, '')
			.then(result_ => {
                if (result_.pagination != null) {
                    ArchivedDocument.getList(this, filter, sortingBy, 1, result_.pagination.total, '')
                    .then(result => {
                        if (result != null) {
                            let archivedDocuments = result.data;
                            archivedDocuments.forEach(item => {
                                let date = Date.parse(item.createdAt);
                                let createdDate = new Date(item.createdAt);
                                if (createdDate != undefined && createdDate != null) {
                                    if (createdDate.getFullYear() >= 2023) {
                                        this.messages.push({name : "outgoing" + item.id , value : item});
                                        ArchivedDocument.deleteData(this, item.id).then(result => {
                                            console.log(item.id + " | deleted");
                                        })
                                    }
                                }
                            });
                        }
                        this.isDataLoading = false;
                    })
                    .catch(error => {
                        this.isDataLoading = false;
                    });
                }
            }).catch(error => {
				
			});
		},
    }    
}

</script>